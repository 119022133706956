import { DropOptions, NodeModel } from '@minoru/react-dnd-treeview';
import { TreeNodeData } from './types';

interface Props {
  tree: NodeModel<TreeNodeData>[];
  toggle: (id: number | string) => void;
  openedNodes: (number | string)[];
  onDrop: (tree: NodeModel<TreeNodeData>[], options: DropOptions<TreeNodeData>) => void;
}

export default function useOnDrop({ tree, toggle, openedNodes, onDrop }: Props) {
  const isDroppingIntoItself = (options: DropOptions<TreeNodeData>) =>
    options.dropTargetId === options.dragSourceId;

  const getSiblings = (parentId: string | number | null) =>
    tree.filter((node) => node.parent === parentId);

  const handleReorder = (
    newTree: NodeModel<TreeNodeData>[],
    options: DropOptions<TreeNodeData>
  ) => {
    const draggedNodeIndex = newTree.findIndex((node) => node.id === options.dragSourceId);
    const draggedNode = newTree[draggedNodeIndex];
    const parentId = options.dropTarget?.parent || options.dropTargetId;

    // Get the original position among siblings
    const originalSiblingPosition = newTree
      .filter((node) => node.parent === draggedNode.parent)
      .findIndex((node) => node.id === draggedNode.id);

    // Remove the dragged node
    const filteredTree = newTree.filter((node) => node.id !== options.dragSourceId);

    // Get the new siblings after removal
    const siblings = filteredTree.filter((node) => node.parent === parentId);

    // Calculate the correct target index
    let targetIndex;
    const relativeIndex = options.relativeIndex ?? siblings.length;

    if (parentId === draggedNode.parent && relativeIndex > originalSiblingPosition) {
      targetIndex = relativeIndex - 1;
    } else {
      targetIndex = relativeIndex;
    }

    // Get all nodes with the same parent
    const nodesWithSameParent = filteredTree.filter((node) => node.parent === parentId);

    if (nodesWithSameParent.length === 0) {
      filteredTree.push({ ...draggedNode, parent: parentId });
    } else {
      const referenceNode = nodesWithSameParent[targetIndex];
      const insertIndex = referenceNode
        ? filteredTree.findIndex((node) => node.id === referenceNode.id)
        : filteredTree.length;

      filteredTree.splice(insertIndex, 0, { ...draggedNode, parent: parentId });
    }

    onDrop(filteredTree, options);
  };

  const handleDropIntoSubfolder = (
    newTree: NodeModel<TreeNodeData>[],
    options: DropOptions<TreeNodeData>
  ) => {
    const draggedNodeIndex = newTree.findIndex((node) => node.id === options.dragSourceId);
    const draggedNode = newTree[draggedNodeIndex];

    const filteredTree = newTree.filter((node) => node.id !== options.dragSourceId);

    const siblings = getSiblings(options.dropTargetId);
    const destinationIndex = options.destinationIndex ?? siblings.length;
    filteredTree.splice(destinationIndex, 0, { ...draggedNode, parent: options.dropTargetId });

    onDrop(filteredTree, options);

    if (!openedNodes.includes(options.dropTargetId)) {
      toggle(options.dropTargetId);
    }
  };

  const handleDrop = (newTree: NodeModel<TreeNodeData>[], options: DropOptions<TreeNodeData>) => {
    if (isDroppingIntoItself(options)) return;
    if (options.dropTarget) {
      handleDropIntoSubfolder(newTree, options);
    } else {
      handleReorder(newTree, options);
    }
  };

  return handleDrop;
}
