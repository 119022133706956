import {
  CircularProgress,
  SvgIcon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  IconButton,
  Box,
  Tooltip,
} from '@mui/material';
import {
  WorkbaseCloseCircleIcon,
  WorkbaseCheckmarkBigFilledIcon,
  WorkbaseErrorFilledIcon,
  WorkbaseDeleteSquareIcon,
} from 'src/assets/icons/workbaseIcons';
import FileIcon from 'src/ui-components/custom/file-icon/FileIcon';
import { UploadStatus } from './types';
import { useUpload } from './UploadContext';
import { useNavigate } from 'react-router-dom';
import useMaintainEditPath from 'src/lib/hooks/useMaintainEditPath';
import { useTranslation } from 'react-i18next';

export default function UploadingFilesList() {
  const { t } = useTranslation();
  const { uploadingFiles, progress, removeFile, cancelUpload } = useUpload();
  const navigate = useNavigate();
  const path = useMaintainEditPath();
  const getStatusIcon = (fileId: string) => {
    const cancelAction = (
      <Tooltip title={t('upload_window.cancel_upload')}>
        <IconButton size="small" onClick={() => cancelUpload(fileId)} className="button">
          <SvgIcon component={WorkbaseCloseCircleIcon} fontSize="small" />
        </IconButton>
      </Tooltip>
    );

    const deleteAction = (
      <Tooltip title={t('upload_window.clear_upload')}>
        <IconButton size="small" onClick={() => removeFile(fileId)} className="button">
          <SvgIcon component={WorkbaseDeleteSquareIcon} fontSize="small" />
        </IconButton>
      </Tooltip>
    );

    switch (uploadingFiles[fileId].status) {
      case UploadStatus.UPLOADING:
        return (
          <>
            <Box p={1} className="icon">
              <CircularProgress size={24} value={progress[fileId]} variant="determinate" />
            </Box>
            {cancelAction}
          </>
        );
      case UploadStatus.ERROR:
        return (
          <>
            <Box p={1} className="icon">
              <SvgIcon component={WorkbaseErrorFilledIcon} fontSize="small" color="error" />
            </Box>
            {deleteAction}
          </>
        );
      case UploadStatus.SUCCESS:
        return (
          <>
            <Box p={1} className="icon">
              <SvgIcon
                component={WorkbaseCheckmarkBigFilledIcon}
                color="success"
                fontSize="small"
              />
            </Box>
            {deleteAction}
          </>
        );
      default:
        return null;
    }
  };
  return (
    <Stack maxHeight={300} overflow="auto">
      <List>
        {Object.keys(uploadingFiles).map((fileId) => (
          <ListItem
            key={fileId}
            component={Stack}
            direction="row"
            disableGutters
            py={1}
            pr={1}
            pl={2}
          >
            <ListItemIcon color="primary">
              <FileIcon extension={uploadingFiles[fileId]?.name?.split('.').pop() as string} />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ noWrap: true }}
              secondaryTypographyProps={{
                noWrap: true,
                sx: {
                  textDecoration: 'underline',
                  cursor: 'pointer',
                },
                onClick: (e) => {
                  e.preventDefault();
                  navigate({
                    pathname: uploadingFiles[fileId].resource.route?.replace(
                      '/edit',
                      path
                    ) as string,
                    search: uploadingFiles[fileId].resource.params,
                  });
                },
              }}
              primary={uploadingFiles[fileId]?.name}
              secondary={`to ${uploadingFiles[fileId]?.resource?.name}`}
            />
            <Stack
              direction="row"
              minWidth={40}
              alignItems="center"
              justifyContent="center"
              sx={{
                '& .button': {
                  display: 'none',
                },
                '&:hover .icon': {
                  display: 'none',
                },
                '& .icon': {
                  display: 'flex',
                },
                '&:hover .button': {
                  display: 'flex',
                },
              }}
            >
              {getStatusIcon(fileId)}
            </Stack>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}
