import { alpha, Box, Container, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import LightBackground from 'src/assets/images/light_background.jpg';
import SubHeader from './SubHeader';
import BackgroundImageActions from './BackgroundImageActions';
import { useDropzone } from 'react-dropzone';
import { allowedFileTypes } from 'src/features/editor/view/grid/widgets/upload-item/config';
import { showNotification } from '../custom/notifications-snackbar';
import { imageUpload } from 'src/lib/utils/imageUpload';
import { useTranslation } from 'react-i18next';
import Empty from '../custom/empty/Empty';
import { WorkbaseImageNewIcon } from 'src/assets/icons/workbaseIcons';

interface Props {
  children: React.ReactNode;
  minHeight?: number;
  subHeader?: React.ReactNode;
  stickyBottomContent?: boolean;
  backgroundImage?: {
    url?: string | null;
    onChange?: (val: string | null) => void;
    blur?: boolean;
  };
}

const absoluteStyles = {
  left: 0,
  right: 0,
  bottom: 0,
  top: 0,
  position: 'absolute' as const,
};

export default function BrandedHeader({
  children,
  subHeader,
  minHeight = 250,
  stickyBottomContent,
  backgroundImage,
}: Props) {
  const [overlayOpacity, setOverlayOpacity] = useState(0.3);
  const theme = useTheme();
  const { t } = useTranslation();
  const imageUrl = backgroundImage?.url;
  const hasBlurEffect = backgroundImage?.blur;

  useEffect(() => {
    if (imageUrl && !hasBlurEffect) {
      const img = new Image();
      img.src = imageUrl;
      img.crossOrigin = 'Anonymous';

      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0, img.width, img.height);

        const imageData = ctx?.getImageData(0, 0, canvas.width, canvas.height).data;
        if (!imageData) return undefined;
        let brightnessSum = 0;
        for (let i = 0; i < imageData.length; i += 4) {
          const r = imageData[i];
          const g = imageData[i + 1];
          const b = imageData[i + 2];
          brightnessSum += (r + g + b) / 3;
        }
        const avgBrightness = brightnessSum / (imageData.length / 4);

        const calculatedOpacity = avgBrightness > 128 ? 0.6 : 0.3;

        setOverlayOpacity(calculatedOpacity);
        return undefined;
      };
    }
  }, [imageUrl, hasBlurEffect]);

  const backgroundUrl = imageUrl || LightBackground;
  const contentElementsColor = backgroundImage?.url ? 'common.white' : 'primary.contrastText';
  const handleFileChange = async (files: File[]) => {
    const file = files?.[0];
    if (!file || !backgroundImage?.onChange) return undefined;

    try {
      const response = await imageUpload(file);
      return backgroundImage?.onChange(response.data.result.id);
    } catch (_err) {
      showNotification(t('validationErrorMessages.UploadImageErrorMessage'), 'error');
    }
    return undefined;
  };
  const { getInputProps, open, getRootProps, isDragActive } = useDropzone({
    onDrop: handleFileChange,
    noClick: true,
    disabled: !backgroundImage?.onChange,
    accept: {
      ...allowedFileTypes.image,
    },
  });

  const overlayColor = hasBlurEffect
    ? alpha(theme.palette.primary.main, 0.6)
    : alpha(theme.palette.common.black, overlayOpacity);

  return (
    <>
      <Box width="100%" pb={subHeader ? 0 : 2}>
        <Box
          display="flex"
          {...getRootProps()}
          position="relative"
          width="100%"
          minHeight={minHeight}
          zIndex={16}
          overflow="hidden"
          py={3}
          color={contentElementsColor}
        >
          <Box
            {...absoluteStyles}
            sx={{
              backgroundImage: backgroundImage ? `url(${backgroundUrl})` : undefined,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              filter: backgroundImage?.url && backgroundImage?.blur ? 'blur(20px)' : 'none',
            }}
          />
          <Box
            bgcolor={backgroundImage?.url ? overlayColor : alpha(theme.palette.primary.main, 0.9)}
            sx={{
              '& > *': {
                opacity: 0,
              },
              '&:hover > *': {
                opacity: 1,
              },
              backdropFilter: isDragActive ? 'blur(20px)' : undefined,
            }}
            {...absoluteStyles}
          >
            {backgroundImage?.onChange && !stickyBottomContent && (
              <BackgroundImageActions
                removeImage={!!backgroundImage?.url}
                inputProps={getInputProps()}
                open={open}
                onDelete={() => backgroundImage?.onChange?.(null)}
              />
            )}
          </Box>
          <Container
            component={Box}
            position="relative"
            display="flex"
            flexGrow={1}
            maxWidth="lg"
            overflow="hidden"
            sx={{
              pointerEvents: 'none',
              '& > *': {
                pointerEvents: 'auto',
              },
            }}
          >
            {isDragActive ? (
              <Box
                position="absolute"
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                height="100%"
              >
                <Empty icon={WorkbaseImageNewIcon} description={t('dragAndDropImage')} />
              </Box>
            ) : (
              children
            )}
          </Container>
        </Box>
      </Box>
      {subHeader && <SubHeader>{subHeader}</SubHeader>}
    </>
  );
}
