import { Box, SvgIcon } from '@mui/material';
import {
  WorkbaseDefault1,
  WorkbaseDefault2,
  WorkbaseDefault3,
  WorkbaseDefault4,
  WorkbaseDefault5,
  WorkbaseDefault6,
} from 'src/assets/placeholders/defaultIcons';

export type DefaultImage =
  | 'WorkbaseDefault1'
  | 'WorkbaseDefault2'
  | 'WorkbaseDefault3'
  | 'WorkbaseDefault4'
  | 'WorkbaseDefault5'
  | 'WorkbaseDefault6';

export interface BrandedDefaultImageProps {
  defaultImage: DefaultImage;
  width?: string | number;
  height?: string | number;
  dataCy?: string;
  fit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
  borderRadius?: number;
  aspectRatio?: number;
}

const contentDict = {
  WorkbaseDefault1,
  WorkbaseDefault2,
  WorkbaseDefault3,
  WorkbaseDefault4,
  WorkbaseDefault5,
  WorkbaseDefault6,
};

export default function BrandedDefaultImage({
  defaultImage,
  width = '100%',
  height = '100%',
  fit = 'cover',
  dataCy,
  borderRadius = 1,
  aspectRatio,
}: BrandedDefaultImageProps) {
  return (
    <Box
      overflow="hidden"
      borderRadius={borderRadius}
      width={width}
      height={height}
      bgcolor="primary.main"
      alignItems="center"
      display="flex"
    >
      <SvgIcon
        data-cy={dataCy}
        viewBox="222.5 225 555 550"
        component={contentDict[defaultImage] || contentDict.WorkbaseDefault1}
        sx={{
          width: '100%',
          height: '100%',
          objectFit: fit,
          aspectRatio,
        }}
      />
    </Box>
  );
}
