import Dialog from '@mui/material/Dialog';
import { lazy, Suspense } from 'react';
import { useSearchParams } from 'react-router-dom';
import LottieLogoSpinner from 'src/core/lottie-logo-spinner';
import { UnsavedChangesProvider, useUnsavedChanges } from 'src/lib/utils/UnsavedChangesContext';

const Employees = lazy(() => import('src/features/company-users/view'));

function EmployeesDialogWrapper() {
  const [searchParams] = useSearchParams();
  const { handleSafeSetSearchParams } = useUnsavedChanges();
  const handleClose = () => {
    handleSafeSetSearchParams({});
  };
  return (
    <Dialog
      open={!!searchParams.get('company-user')}
      PaperProps={{
        variant: 'elevation',
        elevation: 0,
        sx: {
          height: 'calc(100vh - 100px)',
          maxHeight: ({ breakpoints }) => breakpoints.values.md,
        },
      }}
      onClose={handleClose}
      fullWidth
      maxWidth="xl"
    >
      <Suspense fallback={<LottieLogoSpinner margin="auto" />}>
        <Employees />
      </Suspense>
    </Dialog>
  );
}

export default function EmployeesPopup() {
  return (
    <UnsavedChangesProvider key="employees">
      <EmployeesDialogWrapper />
    </UnsavedChangesProvider>
  );
}
