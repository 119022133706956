import { Box, Stack, useTheme } from '@mui/material';
import TabPanel from '@mui/lab/TabPanel';
import { DropzoneProps } from 'react-dropzone';
import { motion } from 'framer-motion';
import { Document, File } from 'src/store/api/driveApi';
import FilesTable from './table/FilesTable';
import { DocumentsTabData } from './types';
import DocumentsTable from './table/DocumentsTable';
import { GridSortModel } from '@mui/x-data-grid';

interface DocumentsDataListProps {
  tabsData: DocumentsTabData[];
  dropZoneProps: DropzoneProps;
  isDragging: boolean;
  setDocumentsPage: (page: number) => void;
  setFilesPage: (page: number) => void;
  filesRowCount: number;
  documentsRowCount: number;
  setDocumentsSortModel: (model: GridSortModel) => void;
  setFilesSortModel: (model: GridSortModel) => void;
  isDocumentsDataLoading: boolean;
  isFilesDataLoading: boolean;
}

export default function DocumentsDataList({
  tabsData,
  dropZoneProps,
  isDragging,
  setDocumentsPage,
  setFilesPage,
  filesRowCount,
  documentsRowCount,
  setDocumentsSortModel,
  setFilesSortModel,
  isDocumentsDataLoading,
  isFilesDataLoading,
}: DocumentsDataListProps) {
  const theme = useTheme();
  return (
    <Box pb={2}>
      <Stack gap={2}>
        <Box>
          {tabsData.map(({ key, rows }) => (
            <TabPanel key={key} value={key}>
              {key === 'files' ? (
                <>
                  {/* @ts-expect-error */}
                  <Box
                    component="div"
                    {...dropZoneProps}
                    position="relative"
                    sx={{
                      cursor: 'default',
                    }}
                  >
                    <FilesTable
                      files={rows as File[]}
                      setFilesPage={setFilesPage}
                      rowCount={filesRowCount}
                      setFilesSortModel={setFilesSortModel}
                      isLoading={isFilesDataLoading}
                    />
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={isDragging ? { opacity: 1 } : { opacity: 0 }}
                      exit={{ opacity: 0 }}
                      transition={{ opacity: { duration: 0.3 } }}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        border: `1px solid ${theme.palette.divider}`,
                        bottom: 0,
                        display: isDragging ? 'flex' : 'none',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: theme.palette.action.hover,
                        backdropFilter: 'blur(10px)',
                        borderRadius: `${theme.shape.borderRadius}px`,
                        zIndex: 10,
                      }}
                    />
                  </Box>
                </>
              ) : (
                <Box position="relative">
                  <DocumentsTable
                    rows={rows as Document[]}
                    setDocumentsPage={setDocumentsPage}
                    rowCount={documentsRowCount}
                    setDocumentsSortModel={setDocumentsSortModel}
                    isLoading={isDocumentsDataLoading}
                  />
                </Box>
              )}
            </TabPanel>
          ))}
        </Box>
      </Stack>
    </Box>
  );
}
