import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/store/api/api';

export interface Link {
  id: string;
  name: string;
  url: string;
  imageUrl: string;
  position: string;
}

export type ResourceType = 'role' | 'page' | 'wikiCard' | 'customerGroup';

interface Resource {
  resourceType: ResourceType;
  resourceId: string;
}

export const linksApi = createApi({
  reducerPath: 'linksApi',
  tagTypes: ['ResourceLinks'],
  baseQuery,
  endpoints: (builder) => ({
    links: builder.query<Link[], Resource>({
      query: ({ resourceId, resourceType }) => ({
        url: `/links`,
        params: { resourceId, resourceType },
      }),
      providesTags: (_, __, { resourceId }) => [{ type: 'ResourceLinks', id: resourceId }],
    }),
    postLinkToResource: builder.mutation<Omit<Link, 'position'>, Resource & Omit<Link, 'imageUrl'>>(
      {
        query: (payload) => ({
          url: '/links',
          method: 'POST',
          body: payload,
        }),
        invalidatesTags: (_, __, { resourceId }) => [{ type: 'ResourceLinks', id: resourceId }],
        async onQueryStarted(newLink, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            linksApi.util.updateQueryData(
              'links',
              { resourceId: newLink.resourceId, resourceType: newLink.resourceType },
              (draft) => {
                draft.push({ ...newLink, imageUrl: '' });
              }
            )
          );
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
          }
        },
      }
    ),
    patchLink: builder.mutation<
      Partial<Link>,
      Resource & Partial<Omit<Link, 'imageUrl' | 'id'>> & { imageId?: string | null; id: string }
    >({
      query: ({ id, ...rest }) => ({
        url: `/links/${id}`,
        method: 'PATCH',
        body: rest,
      }),
      invalidatesTags: (_, __, { resourceId }) => [{ type: 'ResourceLinks', id: resourceId }],
    }),
    removeLink: builder.mutation<void, Resource & { id: string }>({
      query: ({ id, ...rest }) => ({
        url: `/links/${id}`,
        method: 'DELETE',
        body: rest,
      }),
      invalidatesTags: (_, __, { resourceId }) => [{ type: 'ResourceLinks', id: resourceId }],
    }),
  }),
});

export const {
  useLinksQuery,
  usePostLinkToResourceMutation,
  usePatchLinkMutation,
  useRemoveLinkMutation,
} = linksApi;
