import { MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  GeneralAccessType,
  ResourceType,
  usePatchGeneralAccessMutation,
} from 'src/store/api/shareResourceApi';
import {
  WorkbaseLockIcon,
  WorkbaseBusinessPlusIcon,
  WorkbaseTwoEmployeesIcon,
} from 'src/assets/icons/workbaseIcons';
import { ElementType } from 'react';
import { ShareLevel } from 'src/lib/types/share-level';

interface GeneralAccessSwitchProps {
  resourceId: string;
  resourceType: ResourceType;
  generalAccessType: GeneralAccessType;
}
// TODO: confirmation dialog
function GeneralAccessSelect({
  resourceId,
  resourceType,
  generalAccessType,
}: GeneralAccessSwitchProps): JSX.Element {
  const { t } = useTranslation();
  const [patchGeneralAccess] = usePatchGeneralAccessMutation();
  const publicShareLevel = { shareLevel: ShareLevel.view };

  const generalAccessItems: Partial<
    Record<GeneralAccessType, { label: string; icon: ElementType; value: GeneralAccessType }>
  > = {
    restricted: {
      label: t('permissionsShare.restricted'),
      value: 'restricted',
      icon: WorkbaseLockIcon,
    },
    everyone: {
      label: t('permissionsShare.everyone'),
      value: 'everyone',
      icon: WorkbaseBusinessPlusIcon,
    },
    ...(resourceType === 'page' && {
      public: {
        label: t('permissionsShare.public'),
        icon: WorkbaseTwoEmployeesIcon,
        value: 'public',
      },
    }),
  };

  return (
    <Select
      value={generalAccessType}
      size="small"
      data-cy="general-access-select"
      variant="standard"
      onChange={(event) => {
        patchGeneralAccess({
          resourceType: resourceType as ResourceType,
          resourceId: resourceId as string,
          type: event.target.value as GeneralAccessType,
          ...(event.target.value === 'public' && publicShareLevel),
        });
      }}
    >
      {Object.values(generalAccessItems).map((item) => (
        <MenuItem key={item.label} value={item.value} data-cy={`general-access-${item.value}`}>
          {item.label}
        </MenuItem>
      ))}
    </Select>
  );
}

export default GeneralAccessSelect;
