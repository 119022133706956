import { createApi } from '@reduxjs/toolkit/query/react';
import { DefaultImage } from 'src/ui-components/branded-default-image/BrandedDefaultImage';
import { baseQuery } from '../api';

export interface Training {
  id: string;
  name: string;
  description: string;
  imageUrl: string | null;
  hasPermission: boolean;
  category: TrainingCategory;
  defaultImage: DefaultImage;
  modulesCount: number;
  lessonsCount: number;
  sectionsCount: number;
  estimatedTimeToComplete: number;
  progress: number;
}

export interface TrainingsProgress {
  trainingId: string;
  progress: number;
}

export interface TrainingModulesProgress {
  trainingModuleId: string;
  progress: number;
}

type TrainingCategory = 'trainingsForMe' | 'trainingsForMePublic' | 'optionalTrainings';

export const trainingsApi = createApi({
  reducerPath: 'trainingsApi',
  baseQuery,
  tagTypes: ['Training', 'TrainingsByUserId'],
  endpoints: (builder) => ({
    trainingsByUserId: builder.query<Training[], string | undefined>({
      query: (userId) => ({
        url: `/company-users/${userId || 'me'}/trainings`,
      }),
      providesTags: ['TrainingsByUserId'],
    }),
    getSingleTraining: builder.query<Training, string>({
      query: (id) => ({
        url: `/company-users/me/trainings/${id}`,
      }),
      providesTags: (_, __, id) => [{ type: 'Training', id }],
    }),
    postTraining: builder.mutation<void, void>({
      query: (data) => ({
        url: `/trainings`,
        method: 'POST',
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(trainingsApi.util.invalidateTags(['TrainingsByUserId']));
        });
      },
    }),
    patchTraining: builder.mutation<
      void,
      Partial<Omit<Training, 'id' | 'imageUrl'> & { id: string; imageId?: string | null }>
    >({
      query: ({ id, ...rest }) => ({
        url: `/trainings/${id}`,
        method: 'PATCH',
        body: { ...rest },
      }),
      invalidatesTags: (_, __, { id }) => ['TrainingsByUserId', { type: 'Training', id }],
    }),
    deleteTrainings: builder.mutation<void, string>({
      query: (id) => ({
        url: `/trainings/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['TrainingsByUserId'],
    }),
    duplicateTrainings: builder.mutation<void, string>({
      query: (id) => ({
        url: `/trainings/${id}/duplicate`,
        method: 'POST',
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(trainingsApi.util.invalidateTags(['TrainingsByUserId']));
        });
      },
    }),
  }),
});

export const {
  usePatchTrainingMutation,
  useGetSingleTrainingQuery,
  usePostTrainingMutation,
  useDeleteTrainingsMutation,
  useDuplicateTrainingsMutation,
  useTrainingsByUserIdQuery,
} = trainingsApi;
