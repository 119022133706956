import { ResultData } from 'src/store/api/searchApi';

import { useSearchResultsDict } from 'src/features/global-search/controller/useSearchResultsDict';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';

export default function SuggestionItem({ suggestion, ...props }: { suggestion: ResultData }) {
  const { searchResultDict } = useSearchResultsDict(suggestion, 'medium', 'small');

  return (
    <MenuItem {...props}>
      <ListItemIcon>{searchResultDict[suggestion.type].prefix}</ListItemIcon>
      <ListItemText
        primary={suggestion.name}
        secondary={suggestion.email}
        primaryTypographyProps={{ noWrap: true }}
        secondaryTypographyProps={{ noWrap: true }}
      />
    </MenuItem>
  );
}
