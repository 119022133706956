import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/store';
import { ResultData, ResourceType } from 'src/store/api/searchApi';
import React from 'react';
import SvgIconOrEmoji from 'src/ui-components/icon/SvgIconOrEmoji';
import { Avatar, SvgIconPropsSizeOverrides } from '@mui/material';
import { SearchHistoryItem, setSearchHistory } from './search.slice';
import { getNavigationRoute } from './utils';
import { useTranslation } from 'react-i18next';
import { OverridableStringUnion } from '@mui/types';

interface ResultVariables {
  name: string;
  navigateRoute: string;
  description: string | null;
  prefix: React.ReactNode;
}

export const useSearchResultsDict = (
  item: ResultData,
  iconSize?: OverridableStringUnion<
    'small' | 'medium' | 'inherit' | 'large',
    SvgIconPropsSizeOverrides
  >,
  avatarSize?: string | number
) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentPortal = useAppSelector((state) => state.userPortal.currentPortal);

  const setSearchResult = (newSearchResult: SearchHistoryItem) => {
    dispatch(setSearchHistory(newSearchResult));
  };
  const searchResultDict: Record<ResourceType, ResultVariables> = {
    page: {
      name: item.name || t('customSites.unnamedPage'),
      navigateRoute: getNavigationRoute(item),
      description: item.headline,
      prefix: item.icon && <SvgIconOrEmoji content={item.icon} fontSize={iconSize || 'medium'} />,
    },
    training: {
      name: item.name || t('trainingDetailPage.unnamedTraining'),
      navigateRoute: getNavigationRoute(item),
      description: item.headline,
      prefix: <SvgIconOrEmoji content="WorkbaseTrainingsIcon" fontSize={iconSize || 'medium'} />,
    },
    trainingModule: {
      name: item.name || 'trainingModule',
      navigateRoute: getNavigationRoute(item),
      description: item.headline,
      prefix: <SvgIconOrEmoji content="WorkbaseModuleIcon" fontSize={iconSize || 'medium'} />,
    },
    trainingLesson: {
      name: item.name || 'trainingLesson',
      navigateRoute: getNavigationRoute(item),
      description: item.headline,
      prefix: <SvgIconOrEmoji content="WorkbaseLessonIcon" fontSize={iconSize || 'medium'} />,
    },
    companyUser: {
      name: `${item.name}`,
      navigateRoute: getNavigationRoute(item),
      description: item.email,
      prefix: (
        <Avatar size={avatarSize as any} src={item.imageUrl || undefined}>
          {item.name[0]}
        </Avatar>
      ),
    },
    embeddedApp: {
      name: item.name || t('embeddedApps.unnamedApp'),
      navigateRoute: getNavigationRoute(item),
      description: item.headline,
      prefix: item.icon && <SvgIconOrEmoji content={item.icon} fontSize={iconSize || 'medium'} />,
    },
    whiteboard: {
      name: item.name || t('whiteboards.unnamedWhiteboard'),
      navigateRoute: getNavigationRoute(item),
      description: item.headline,
      prefix: item.icon && <SvgIconOrEmoji content={item.icon} fontSize={iconSize || 'medium'} />,
    },
    wikiCard: {
      name: item.name || t('wikiPage.unnamedWikiCard'),
      navigateRoute: getNavigationRoute(item),
      description: item.headline,
      prefix: <SvgIconOrEmoji content="WorkbaseWikiIcon" fontSize={iconSize || 'medium'} />,
    },
    assistantThread: {
      name: item.name,
      navigateRoute: getNavigationRoute(item),
      description: item.headline,
      prefix: <SvgIconOrEmoji content="WorkbaseLiveChatIcon" fontSize={iconSize || 'medium'} />,
    },
  };

  const handleClick = () => {
    if (item.type === 'companyUser') {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set('company-user', item.id);
      setSearchParams(newSearchParams);
    } else {
      navigate(searchResultDict[item.type].navigateRoute, { relative: 'route' });
    }
    const searchResult = {
      name: searchResultDict[item.type].name,
      navigateRoute: searchResultDict[item.type].navigateRoute,
      id: item.id,
      currentPortal,
    };
    setSearchResult(searchResult);
  };

  return {
    searchResultDict,
    handleClick,
  };
};
