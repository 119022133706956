import { alpha, Stack } from '@mui/material';

export default function FloatingActionWrapper({ children }: { children: React.ReactNode }) {
  return (
    <Stack
      direction="row"
      p={1}
      gap={2}
      mr={1}
      position="sticky"
      ml="auto"
      mb={-8}
      top={8}
      width="max-content"
      zIndex={20}
      sx={{
        backgroundColor: (theme) => alpha(theme.palette.background.paper, 0.4),
        backdropFilter: 'blur(8px)',
        borderRadius: 1,
      }}
    >
      {children}
    </Stack>
  );
}
