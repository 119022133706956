import { useTranslation } from 'react-i18next';
import { ChangeEvent, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import ensureHttpProtocol from 'src/lib/utils/ensureHttpProtocol';
import {
  ResourceType,
  usePatchLinkMutation,
  usePostLinkToResourceMutation,
} from 'src/store/api/linksApi';
import useOnEnterTrigger from 'src/hooks/useOnEnterTrigger';
import { UUID } from 'uuidjs';

interface Props {
  linkId?: string;
  open: boolean;
  onClose: () => void;
  editMode?: boolean;
  initialName?: string;
  initialLink?: string;
  resourceId: string;
  resourceType: ResourceType;
}

function ModalContent({
  editMode,
  initialName,
  initialLink,
  linkId,
  onClose,
  resourceId,
  resourceType,
}: Omit<Props, 'open'>) {
  const { t } = useTranslation();
  const [name, setName] = useState(initialName || '');
  const [link, setLink] = useState(initialLink || '');

  const [postLinkToResource] = usePostLinkToResourceMutation();
  const [patchLink] = usePatchLinkMutation();

  const [nameBlurred, setNameBlurred] = useState(false);
  const [linkBlurred, setLinkBlurred] = useState(false);

  const onNameChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setName(e.target.value);
  };

  // TODO: improve the validation script for special links that can be used within some *BASE-1010
  const onLinkChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLink(e.target.value);
  };

  const isNameValid = name?.trim() !== '';
  const isLinkValid = link?.trim() !== '';

  const handleSave = () => {
    if (!isNameValid) return;

    if (editMode && linkId) {
      patchLink({
        id: linkId,
        resourceId,
        resourceType,
        name,
        url: ensureHttpProtocol(link),
      });
    } else {
      postLinkToResource({
        name,
        url: ensureHttpProtocol(link),
        resourceId,
        resourceType,
        position: '',
        id: UUID.generate(),
      });
    }
    onClose();
  };

  useOnEnterTrigger(handleSave);

  return (
    <>
      <DialogTitle>
        {editMode ? t('links.addEditLinkModal.editTitle') : t('links.addEditLinkModal.addTitle')}
      </DialogTitle>
      <DialogContent>
        <Stack gap={2} pt={1}>
          <TextField
            data-cy="edit-link-name-modal-textfield"
            size="small"
            label={t('links.addEditLinkModal.nameLabel')}
            value={name}
            onChange={onNameChange}
            onBlur={() => setNameBlurred(true)}
            error={nameBlurred && !isNameValid}
            helperText={nameBlurred && !isNameValid && t('links.addEditLinkModal.nameErrorMessage')}
          />
          <TextField
            size="small"
            onBlur={() => setLinkBlurred(true)}
            error={linkBlurred && !isLinkValid}
            helperText={linkBlurred && !isLinkValid && t('links.addEditLinkModal.linkErrorMessage')}
            label={t('links.addEditLinkModal.linkLabel')}
            value={link}
            onChange={onLinkChange}
            data-cy="edit-link-modal-textfield"
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button fullWidth color="inherit" onClick={onClose} data-cy="edit-link-modal-cancel-button">
          {t('cancel')}
        </Button>
        <Button
          fullWidth
          color="primary"
          disabled={!isNameValid}
          onClick={handleSave}
          data-cy={`edit-link-modal-${editMode ? 'save' : 'add'}-button`}
        >
          {editMode ? t('save') : t('add')}
        </Button>
      </DialogActions>
    </>
  );
}

export default function AddEditLinkModal({ open, onClose, ...restOfProps }: Props) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth data-cy="edit-link-modal">
      <ModalContent {...restOfProps} onClose={onClose} />
    </Dialog>
  );
}
