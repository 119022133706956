import { useRef } from 'react';
import { DropzoneInputProps } from 'react-dropzone';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, SvgIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import {
  WorkbaseDeleteSquareIcon,
  WorkbaseImageDragAndUploadIcon,
  WorkbaseImageNewIcon,
} from 'src/assets/icons/workbaseIcons';

interface Props {
  faviconUrl?: string;
  onRemoveFavicon?: () => void;
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T | null;
  onFaviconChange?: (e: any) => void;
}
export default function FaviconMenu({
  faviconUrl,
  onRemoveFavicon,
  getInputProps,
  onFaviconChange,
}: Props) {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const popupState = usePopupState({ variant: 'popover' });

  const handleEditFaviconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const popoverProps = bindPopover(popupState);

  return (
    <>
      {faviconUrl ? (
        <>
          <IconButton
            {...bindTrigger(popupState)}
            size="small"
            color="inherit"
            disableFocusRipple
            data-cy="favicon-menu-upload-favicon-button"
          >
            <SvgIcon component={WorkbaseImageDragAndUploadIcon} fontSize="small" />
          </IconButton>
          <Menu
            {...popoverProps}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <MenuItem
              data-cy="favicon-menu-change-favicon-button"
              onClick={() => {
                handleEditFaviconClick();
                popoverProps.onClose();
              }}
            >
              <ListItemIcon>
                <SvgIcon component={WorkbaseImageNewIcon} />
              </ListItemIcon>
              <ListItemText primary={t('links.changeFavicon')} />
            </MenuItem>
            <MenuItem
              data-cy="favicon-menu-remove-favicon-button"
              onClick={() => {
                onRemoveFavicon?.();
                popoverProps.onClose();
              }}
            >
              <ListItemIcon>
                <SvgIcon component={WorkbaseDeleteSquareIcon} />
              </ListItemIcon>
              <ListItemText primary={t('links.removeFavicon')} />
            </MenuItem>
          </Menu>
        </>
      ) : (
        <IconButton
          onClick={handleEditFaviconClick}
          size="small"
          color="inherit"
          data-cy="favicon-menu-add-new-image-button"
        >
          <SvgIcon component={WorkbaseImageNewIcon} fontSize="small" />
        </IconButton>
      )}
      <input
        {...getInputProps()}
        ref={fileInputRef}
        type="file"
        accept=".jpg, .jpeg, .jfif, .pjpeg, .pjp, .gif, .apng, .png, .avif, .svg, .webp"
        onClick={(e: any) => {
          onFaviconChange?.(e.target.files?.[0]);
          popoverProps.onClose();
        }}
      />
    </>
  );
}
