import { useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { WorkbaseWikiIcon } from 'src/assets/icons/workbaseIcons';
import useMaintainEditPath from 'src/lib/hooks/useMaintainEditPath';
import { useShareLevelQuery } from 'src/store/api/shareResourceApi';
import { ShareLevel } from 'src/lib/types/share-level';
import SplitPaneLayout from 'src/layouts/SplitPaneLayout';
import useHasAnyPermission from 'src/hooks/useHasAnyPermission';
import Empty from 'src/ui-components/custom/empty/Empty';
import LottieLogoSpinner from 'src/core/lottie-logo-spinner/LottieLogoSpinner';
import { useFetchWikiCardsNoPaginationQuery } from '../../../store/api/wikiApi';
import { convertData } from '../controller/helper';
import WikiSidebar from './WikiSidebar';
import WikiContent from './WikiContent';
import { PermissionCodes } from 'src/store/api/userPermissionsApi';

// eslint-disable-next-line max-statements
export default function Wiki() {
  const { t } = useTranslation();
  const path = useMaintainEditPath();
  const { id } = useParams<{ id: string }>();
  const { data: sidebarData, isLoading } = useFetchWikiCardsNoPaginationQuery();
  const { error: shareLevelError, data: shareLevelData } = useShareLevelQuery(
    {
      resourceType: 'wikiCard',
      resourceId: id ?? '',
    },
    { refetchOnMountOrArgChange: true, skip: !id }
  );
  const navigate = useNavigate();
  const hasAnyPermission = useHasAnyPermission();
  const hasAddWikiPermission = hasAnyPermission([PermissionCodes.ADD_WIKIS]);
  const canView = shareLevelData?.shareLevel !== ShareLevel.none;
  const canEdit = ![ShareLevel.view, ShareLevel.none].includes(
    shareLevelData?.shareLevel as ShareLevel
  );

  // handle some random links + maintain edit mode
  useEffect(() => {
    if (shareLevelError || !id) {
      if (sidebarData?.[0]?.id) {
        navigate(`/wiki/${sidebarData?.[0]?.id}${path}`);
      } else {
        navigate('/wiki');
      }
    }
  }, [shareLevelError, sidebarData, navigate, path, id]);

  const convertedData = useMemo(
    () => (sidebarData ? convertData(sidebarData, t, navigate, path) : []),
    [sidebarData, t, navigate, path]
  );
  const focusedIndex = useMemo(() => {
    const index = sidebarData?.findIndex((card) => card.id === id);
    return index as number;
  }, [sidebarData, id]);

  const text = hasAddWikiPermission ? t('wikiPage.addWikiPlaceholder') : undefined;
  if (!canView) return <Navigate to="/" />;

  if (isLoading)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <LottieLogoSpinner />
      </Box>
    );

  const wikiContent = !sidebarData?.length ? (
    <Box margin="auto">
      <Empty icon={WorkbaseWikiIcon} description={t('wikiPage.noWikiPlaceholder')}>
        {text}
      </Empty>
    </Box>
  ) : (
    <Box
      sx={{
        flexGrow: 1,
        position: 'relative',
        overflowY: 'auto',
        height: '100%',
      }}
    >
      <WikiContent
        readOnly={!canEdit}
        id={id as string}
        shareLevel={shareLevelData?.shareLevel}
        addPermission={hasAddWikiPermission}
        wikiCards={sidebarData || []}
        focusedIndex={focusedIndex}
      />
    </Box>
  );
  const sidebarHidden = sidebarData?.length === 0 && !hasAddWikiPermission;
  if (sidebarHidden) return wikiContent;
  return (
    <SplitPaneLayout
      leftContent={
        <WikiSidebar
          data={convertedData}
          hasAddPermission={hasAddWikiPermission}
          wikiId={id as string}
          isEditMode={canEdit}
        />
      }
      rightContent={wikiContent}
    />
  );
}
