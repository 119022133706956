import { ListItemAvatar, Avatar, SvgIcon, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseDeleteSquareIcon, WorkbaseRoleIcon } from 'src/assets/icons/workbaseIcons';
import { ResourceType } from 'src/store/api/searchResourceApi';
import { ResourceInfo, useDeleteSharedResourceMutation } from 'src/store/api/shareResourceApi';
import AccessControlItem from './AccessControlItem';
import RequiredSwitch from '../RequiredSwitch';

interface Props {
  resource: ResourceInfo;
  resourceId: string;
  resourceType: ResourceType;
  isTrainingResourceType: boolean;
}

export default function CustomerGroupAccessItem(props: Props) {
  const { resource, resourceId, resourceType, isTrainingResourceType } = props;
  const { t } = useTranslation();
  const { customerGroup, id, required } = resource;
  const [deleteResource] = useDeleteSharedResourceMutation();

  if (!customerGroup) {
    return null;
  }

  return (
    <AccessControlItem
      prefix={
        <ListItemAvatar>
          <Avatar size="large">
            <SvgIcon component={WorkbaseRoleIcon} fontSize="medium" />
          </Avatar>
        </ListItemAvatar>
      }
      primaryText={`${t('permissionsShare.customerGroupTitle')}: ${customerGroup.name}`}
      suffix={
        <>
          <Tooltip title={t('delete')}>
            <IconButton
              onClick={() => {
                deleteResource({
                  resourceId,
                  resourceType,
                  sharedWithId: id,
                });
              }}
            >
              <SvgIcon component={WorkbaseDeleteSquareIcon} />
            </IconButton>
          </Tooltip>
          {isTrainingResourceType && (
            <RequiredSwitch
              dataCy="customerGroup-permision-select"
              resourceId={resourceId}
              resourceType={resourceType}
              id={id}
              isRequired={required ?? false}
            />
          )}
        </>
      }
    />
  );
}
