import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ShareModal from 'src/core/share-modal/ShareModal';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import {
  useDeleteEmbeddedAppMutation,
  useDuplicateEmbeddedAppMutation,
  usePatchEmbeddedAppMutation,
} from 'src/store/api/embeddedAppsApi';
import DeleteDuplicateMenu from 'src/ui-components/custom/delete-duplicate-menu';
import { ShareLevel } from 'src/lib/types/share-level';
import { Box, SvgIcon, ToggleButton, Tooltip } from '@mui/material';
import { WorkbasePressContentIcon } from 'src/assets/icons/workbaseIcons';
import useIsCustomerPortal from 'src/hooks/useIsCustomerPortal';

interface Props {
  id: string;
  name?: string;
  shareLevel?: ShareLevel;
  hasAddPermission?: boolean;
  sidebar?: boolean;
}

export default function EmbeddedAppActions({
  id,
  name,
  shareLevel,
  hasAddPermission,
  sidebar,
}: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [deleteEmbeddedApp] = useDeleteEmbeddedAppMutation();
  const [duplicateEmbeddedApp] = useDuplicateEmbeddedAppMutation();
  const [patchEmbeddedApp] = usePatchEmbeddedAppMutation();
  const embededAppName = name || t('embeddedApps.unnamedApp');
  const isCustomerPortal = useIsCustomerPortal();

  return (
    <>
      {!isCustomerPortal && (
        <Tooltip title={t('sidebarTooltip')}>
          <Box bgcolor="primary.paper" borderRadius={1}>
            <ToggleButton
              data-cy="embedded-app-show-in-sidebar-button"
              size="small"
              color={sidebar ? 'primary' : 'standard'}
              selected
              value={!!sidebar}
              onChange={(_, state) => {
                patchEmbeddedApp({ id, sidebar: !state });
              }}
            >
              <SvgIcon component={WorkbasePressContentIcon} fontSize="small" />
            </ToggleButton>
          </Box>
        </Tooltip>
      )}{' '}
      {shareLevel === ShareLevel.manage ? (
        <ShareModal
          name={embededAppName}
          resourceId={id}
          resourceType="embeddedApp"
          shareLevel={shareLevel}
          dataCy="embedded-app"
        />
      ) : null}
      <DeleteDuplicateMenu
        dataCy="embedded-app"
        onDuplicate={
          hasAddPermission
            ? () => {
                duplicateEmbeddedApp(id).then((resp: any) => {
                  if (resp.error) {
                    showNotification(t('notifications.duplicateError'), 'error');
                  } else {
                    navigate(`/app/${resp.data.id}/edit`);
                    showNotification(t('notifications.duplicateSuccess'));
                  }
                });
              }
            : undefined
        }
        deleteDialogDescription={t('embeddedAppPage.deleteModal.content')}
        onDelete={
          shareLevel === ShareLevel.manage
            ? () => {
                deleteEmbeddedApp(id).then((resp: any) => {
                  if (resp.error) {
                    showNotification(t('notifications.deleteError'), 'error');
                  } else {
                    showNotification(t('notifications.deleteSuccess'));
                    navigate('/edit');
                  }
                });
              }
            : undefined
        }
      />
    </>
  );
}
