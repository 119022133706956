import { EditorProvider } from '../controller';
import Editor, { EditorProps } from './Editor';

export interface EditorWithContextProviderProps extends EditorProps {
  documentName: string;
  documentTitle?: string;
}

export default function EditorWithContextProvider({
  documentName,
  documentTitle,
  ...otherProps
}: EditorWithContextProviderProps) {
  return (
    <EditorProvider documentName={documentName}>
      <Editor documentTitle={documentTitle} {...otherProps} />
    </EditorProvider>
  );
}
