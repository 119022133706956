import { useEffect } from 'react';
import { useAppDispatch } from 'src/store';
import { useUserPermissionsQuery } from 'src/store/api/userPermissionsApi';
import { resetUserPortalState } from 'src/store/client/userPortalSlice';

interface Props {
  children: React.ReactNode;
}

export default function CustomerPortalGuard({ children }: Props) {
  const { data, isSuccess } = useUserPermissionsQuery();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (isSuccess && data && !data.some(({ code }) => code === 'MANAGE_CUSTOMER_PORTAL')) {
      dispatch(resetUserPortalState());
    }
  }, [data, isSuccess, dispatch]);
  return children;
}
