import { Box, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ResourceInfo, ResourceType } from 'src/store/api/shareResourceApi';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import AccessControlList from './AccessControlList';

interface TrainingAccessControlListProps {
  resourceId: string;
  resourceType: ResourceType;
  currentShareLevel: string;
  activeTabButton?: TabValue;
  setActiveTabButton?: any;
}
export type TabValue = 'all' | 'required' | 'optional';

export default function TrainingAccessControlList({
  resourceId,
  resourceType,
  currentShareLevel,
  activeTabButton = 'all',
  setActiveTabButton,
}: TrainingAccessControlListProps) {
  const { t } = useTranslation();
  const props = {
    resourceId,
    resourceType,
    currentShareLevel,
  };
  const filterDataRquiredfn = (resource: ResourceInfo) => !!(resource && resource.required);
  const filterDataOptionalfn = (resource: ResourceInfo) => !!(resource && !resource.required);

  const tabs: { label: string; content: JSX.Element | string; key: TabValue }[] = [
    {
      key: 'all',
      label: t('permissionsShare.all'),
      content: <AccessControlList {...props} />,
    },
    {
      key: 'required',
      label: t('permissionsShare.required'),
      content: <AccessControlList {...props} filterDataFn={filterDataRquiredfn} />,
    },
    {
      key: 'optional',
      label: t('permissionsShare.optional'),
      content: <AccessControlList {...props} filterDataFn={filterDataOptionalfn} />,
    },
  ];

  return (
    <Box>
      <TabContext value={activeTabButton}>
        <TabList onChange={(_, newVal) => setActiveTabButton && setActiveTabButton(newVal)}>
          {tabs.map(({ label, key }) => (
            <Tab
              key={key}
              label={label}
              value={key.toString()}
              data-cy={`training-access-control-${key}-tab`}
            />
          ))}
        </TabList>
        {tabs.map(({ key, content }) => (
          <TabPanel key={key} value={key}>
            {content}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
}
