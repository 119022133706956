import { Box, Stack, SvgIcon } from '@mui/material';
import { ReactNode, useState } from 'react';
import { WorkbaseAddSquareIcon, WorkbaseImageNewIcon } from 'src/assets/icons/workbaseIcons';
import { useTranslation } from 'react-i18next';
import LottieLogoSpinner from 'src/core/lottie-logo-spinner';
import Image from 'src/ui-components/image';
import { ImageProps } from 'src/ui-components/image/Image';
import ImageEditorWithPreview from './ImageEditorWithPreview';
import ImageEditorWithoutPreview from './ImageEditorWithoutPreview';
import Empty from '../empty/Empty';
import { useDropzone } from 'react-dropzone';
import { allowedFileTypes } from 'src/features/editor/view/grid/widgets/upload-item/config';

export interface EditableImageUploadProps {
  src?: string | null;
  placeholder?: ReactNode;
  altText?: string;
  onChange: (file: File) => Promise<unknown>;
  onClear: () => Promise<unknown>;
  dataCy?: string;
  gutters?: boolean;
  bgcolor?: string;
  fit?: ImageProps['fit'];
  aspectRatio?: number;
  height?: number;
  width?: number;
}

export default function EditableImageUpload({
  src,
  altText = '',
  onChange,
  placeholder,
  onClear,
  dataCy,
  gutters = false,
  bgcolor = 'background.default',
  fit = 'cover',
  aspectRatio,
  height,
  width,
}: EditableImageUploadProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const defaultImage = placeholder || (
    <Empty
      svgIconProps={{ fontSize: 'small' }}
      icon={WorkbaseAddSquareIcon}
      description={t('Upload')}
      typographyProps={{
        variant: 'caption',
      }}
    />
  );

  const handleChange = async (file: File) => {
    setLoading(true);
    await onChange(file);
    setLoading(false);
  };

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      handleChange(acceptedFiles[0]);
    },
    noClick: true,
    multiple: false,
    accept: {
      ...allowedFileTypes.image,
    },
  });

  const handleClear = async () => {
    setLoading(true);
    await onClear();
    setLoading(false);
  };
  const content = {
    loading: <LottieLogoSpinner sx={{ width: 50, height: 50 }} />,
    withImage: (
      <>
        <Image
          data-cy={dataCy && `${dataCy}-image`}
          src={src as string}
          alt={altText}
          height={height}
          width={width}
          fit={fit}
          aspectRatio={aspectRatio}
        />
        <ImageEditorWithPreview
          onImageRemove={handleClear}
          dataCy={dataCy}
          open={open}
          inputProps={getInputProps()}
        />
      </>
    ),
    withoutImage: (
      <>
        {defaultImage}
        <ImageEditorWithoutPreview dataCy={dataCy} inputProps={getInputProps()} />
      </>
    ),
  };

  const renderContent = () => {
    if (loading) {
      return content.loading;
    }
    return src ? content.withImage : content.withoutImage;
  };

  return (
    <Stack
      borderRadius={1}
      {...getRootProps()}
      justifyContent="center"
      alignItems="center"
      bgcolor={bgcolor}
      p={gutters ? 1 : 0}
      height="100%"
      position="relative"
      overflow="hidden"
    >
      {isDragActive ? (
        <Box
          overflow="hidden"
          sx={{
            position: 'absolute',
            borderRadius: 1,
            top: '50%',
            left: '50%',
            width: '100%',
            height: '100%',
            transform: 'translate(-50%,-50%)',
            backdropFilter: 'blur(8px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <SvgIcon component={WorkbaseImageNewIcon} fontSize="small" />
        </Box>
      ) : null}
      {renderContent()}
    </Stack>
  );
}
