export enum UploadStatus {
  UPLOADING = 'uploading',
  SUCCESS = 'success',
  ERROR = 'error',
}

export interface FileResource {
  type: string;
  id: string;
  name: string;
  route?: string;
  params?: string;
}

export interface UploadingFile {
  id: string;
  name: string;
  resource: FileResource;
  status: UploadStatus;
}

export interface UploadOptions {
  file: File;
  errorMessage: string;
  resource: FileResource;
  onFileUploadSuccess: (id: string) => void;
  onFileUploadError: (id: string) => void;
}

export interface VideoUploadOptions {
  file: File;
  errorMessage: string;
  onVideoUploadSuccess: (id: string) => void;
  documentName?: string;
  resource: FileResource;
  elementId?: string;
  originalAspectRatio?: number;
  posterUrl?: string;
}

export interface ImageUploadOptions {
  file: File;
  errorMessage: string;
  onImageUploadSuccess: (id: string) => void;
  documentName?: string;
  resource: FileResource;
  imageId: string;
}
