import { Box, Stack, SvgIcon, TextField, Typography } from '@mui/material';
import { ChangeEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { WorkbaseContentIcon } from 'src/assets/icons/workbaseIcons';
import useDebouncedValue from 'src/hooks/useDebouncedValue';
import { imageUpload } from 'src/lib/utils/imageUpload';
import {
  TrainingModule,
  usePatchTrainingModuleMutation,
} from 'src/store/api/trainings/trainingModulesApi';
import BrandedDefaultImage from 'src/ui-components/branded-default-image';
import BrandedHeader from 'src/ui-components/branded-header';
import EditableImageUpload from 'src/ui-components/custom/image-edit/EditableImageUpload';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import StartCompleteProgress from 'src/ui-components/custom/progress/StartCompleteProgress';
import ImageWithDefaultFallback from 'src/ui-components/image/ImageWithDefaultFallback';

interface Props {
  trainingModule: TrainingModule;
  mobileView: boolean;
  readOnly: boolean;
  dataCy?: string;
}

export default function ModuleHeader({ trainingModule, mobileView, readOnly, dataCy }: Props) {
  const { t } = useTranslation();
  const [patchModule] = usePatchTrainingModuleMutation();

  const handleChangeModuleName = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      patchModule({
        module: {
          name: e.target.value,
          id: trainingModule.id,
          trainingId: trainingModule.trainingId,
        },
      });
    },
    [trainingModule.id, trainingModule.trainingId, patchModule]
  );

  const { value, handleChange } = useDebouncedValue(trainingModule.name, handleChangeModuleName);
  const onImgChange = async (file: File): Promise<void> => {
    try {
      const response = await imageUpload(file);
      await patchModule({
        module: {
          trainingId: trainingModule.trainingId,
          id: trainingModule.id,
          imageId: response.data.result.id,
        },
        imageSrc: response.data.result.variants[0],
      });
    } catch (_err) {
      showNotification(t('validationErrorMessages.UploadImageErrorMessage'), 'error');
    }
  };

  const removeImage = (): Promise<unknown> =>
    patchModule({
      module: { trainingId: trainingModule.trainingId, id: trainingModule.id, imageId: null },
      imageSrc: '',
    });

  const imageSize = !mobileView
    ? {
        height: 160,
        width: 290,
      }
    : null;

  const image = (
    <>
      {!readOnly && (
        <EditableImageUpload
          {...imageSize}
          src={trainingModule.imageUrl}
          dataCy={dataCy}
          onClear={removeImage}
          onChange={onImgChange}
          aspectRatio={16 / 9}
          placeholder={
            <BrandedDefaultImage
              defaultImage={trainingModule.defaultImage as any}
              dataCy="module-header"
              aspectRatio={16 / 9}
              height={imageSize?.height}
            />
          }
        />
      )}
      {readOnly && (
        <Stack height={imageSize?.height} width={imageSize?.width}>
          <ImageWithDefaultFallback
            aspectRatio={16 / 9}
            src={trainingModule.imageUrl || ''}
            alt={t('editor.trainingCoursePage.trainingModulesWidget.trainingModuleImageAlt')}
            defaultImage={trainingModule.defaultImage as any}
          />
        </Stack>
      )}
    </>
  );

  const progress = <StartCompleteProgress value={trainingModule.progress || 0} />;

  const name = (
    <Stack minWidth={0} flexGrow={1}>
      <Stack direction="row" alignItems="center" width="100%">
        {readOnly && (
          <Stack gap={1}>
            <Typography
              variant={mobileView ? 'h6' : 'h4'}
              fontWeight="medium"
              overflow="hidden"
              display="-webkit-box"
              sx={{
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                wordBreak: 'break-word',
              }}
            >
              {trainingModule.name || t('trainingDetailPage.unnamedModule')}
            </Typography>
            <Stack direction="row" alignItems="center" gap={0.5}>
              <SvgIcon component={WorkbaseContentIcon} fontSize="small" />
              <Typography variant="caption">
                {t('lesson', { count: trainingModule.lessonsCount || 0 })} –{' '}
                {t('minute', { count: trainingModule.estimatedTimeToComplete || 0 })}
              </Typography>
            </Stack>
          </Stack>
        )}
        {!readOnly && (
          <TextField
            value={value}
            data-cy={dataCy && `${dataCy}-input`}
            onChange={handleChange}
            color="contrastText"
            fullWidth
            placeholder={t('trainingDetailPage.unnamedModule')}
          />
        )}
      </Stack>
    </Stack>
  );

  return (
    <Box
      p={mobileView ? 2 : 0}
      sx={mobileView ? { '& > * > *': { borderRadius: 1 } } : {}}
      width="100%"
      flexShrink={0}
    >
      <BrandedHeader backgroundImage={{ url: trainingModule.imageUrl, blur: true }} minHeight={160}>
        <Box my="auto" width="100%">
          <Stack
            direction={mobileView ? 'column' : 'row'}
            justifyContent="space-between"
            gap={4}
            alignItems={mobileView ? 'stretch' : 'center'}
            color="primary.contrastText"
          >
            {mobileView && image}
            <Stack
              minWidth={0}
              flexShrink={1}
              flexGrow={1}
              direction="row"
              alignItems="center"
              gap={1}
            >
              {!mobileView && progress}
              {name}
              {mobileView && progress}
            </Stack>
            {!mobileView && image}
          </Stack>
        </Box>
      </BrandedHeader>
    </Box>
  );
}
