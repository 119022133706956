import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { DetailedCompanyUser } from 'src/store/api/company-users/types';

interface CompanyUserDetailsState {
  companyUser: DetailedCompanyUser | null;
}

const initialState: CompanyUserDetailsState = {
  companyUser: null,
};

const companyUserDetails = createSlice({
  name: 'companyUser',
  initialState,
  reducers: {
    setCompanyUser: (state, action: PayloadAction<DetailedCompanyUser>) => {
      state.companyUser = action.payload;
    },
    updateCompanyUser: (state, action: PayloadAction<{ keyPath: string; value: any }>) => {
      if (state.companyUser) {
        state.companyUser = set(state.companyUser, action.payload.keyPath, action.payload.value);
      }
    },
    clearCompanyUser: (state) => {
      state.companyUser = null;
    },
  },
});

export const { setCompanyUser, updateCompanyUser, clearCompanyUser } = companyUserDetails.actions;
export default companyUserDetails;
