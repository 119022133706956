import { useMemo } from 'react';
import useIsCustomerPortal from 'src/hooks/useIsCustomerPortal';
import { useAppSelector } from 'src/store';
import { useCustomerGroupsQuery } from 'src/store/api/customerGroupsApi';

export default function useFilteredCustomerGroups() {
  const isCustomerPortal = useIsCustomerPortal();
  const { data, isSuccess } = useCustomerGroupsQuery(undefined, {
    refetchOnMountOrArgChange: true,
    skip: !isCustomerPortal,
  });
  const previewMode = useAppSelector((state) => state.userPortal.previewMode);
  const previewGroupIds = useAppSelector((state) => state.userPortal.previewGroupIds);

  const filteredCustomerGroups = useMemo(() => {
    if (isCustomerPortal && previewMode && data) {
      return data.filter((group) => previewGroupIds.includes(group.id));
    }
    return data || [];
  }, [isCustomerPortal, previewMode, data, previewGroupIds]);

  return { filteredCustomerGroups, customerGroupsFetchSuccess: isSuccess };
}
