import { useEffect, useRef } from 'react';
import { useEditor } from './context';
import useEditorCssVariables from './useEditorCssVariables';
import { Box } from '@mui/material';

export default function Editor() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { editor } = useEditor()!;
  const editorContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (editorContainerRef.current && editor) {
      editorContainerRef.current.innerHTML = '';
      editorContainerRef.current.appendChild(editor);
    }
  }, [editor]);

  useEditorCssVariables();

  return (
    <Box
      className="editor-container"
      sx={{
        flexGrow: 1,
        '& .page-editor-container > *:last-child': {
          top: '0 !important',
          right: '0 !important',
          left: 'unset !important',
        },
        '& .affine-drag-handle-container:hover': {
          paddingBottom: '0 !important',
        },
        '& doc-meta-tags': {
          display: 'none',
        },
        '& icon-button[data-testid=convert-to-linked-doc]': {
          display: 'none !important',
        },
        '& affine-format-bar-widget .affine-format-bar-widget': {
          display: 'none !important',
        },
        '& .select-selected, & .card-header-icon svg, & .tag': {
          color: 'black !important',
        },
        '* .doc-title-container': {
          display: 'none',
        },
      }}
      style={{ flexGrow: 1 }}
      ref={editorContainerRef}
    />
  );
}
