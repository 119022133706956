import { ConnectDragPreview, useDrag, useDrop, XYCoord } from 'react-dnd';
import { useRef, useState } from 'react';

interface DragItem {
  index: number;
  id: string;
  type: string;
}

interface HoverState {
  isHovered: boolean;
  isOverLeftHalf: boolean;
}

interface UseDraggableLinkProps {
  index?: number;
  id?: string;
  type: string;
  onDrop?: (index: number, id: string) => void;
}

interface UseDraggableLinkReturn {
  dragItemRef: React.RefObject<HTMLElement>;
  hoverState: HoverState;
  isDragging: boolean;
  previewRef: ConnectDragPreview;
}

function useDraggableLink({
  index,
  id,
  type,
  onDrop,
}: UseDraggableLinkProps): UseDraggableLinkReturn {
  const dragItemRef = useRef<HTMLElement>(null);
  const [hoverState, setHoverState] = useState<HoverState>({
    isHovered: false,
    isOverLeftHalf: false,
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag<DragItem, void, { isDragging: boolean }>({
    type,
    item: () => ({ index: index ?? 0, id: id ?? '', type }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop<DragItem, void, { handlerId: any }>({
    accept: type,
    collect(monitor) {
      const isCurrentlyOver = monitor.isOver({ shallow: true });
      let isOverLeftHalf = false;

      if (isCurrentlyOver && dragItemRef.current) {
        const hoverBoundingRect = dragItemRef.current.getBoundingClientRect();
        const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
        const clientOffset = monitor.getClientOffset();

        if (clientOffset) {
          const hoverClientX = clientOffset.x - hoverBoundingRect.left;
          isOverLeftHalf = hoverClientX < hoverMiddleX;
        }
      }

      setHoverState((prevState) => {
        if (
          isCurrentlyOver !== prevState.isHovered ||
          isOverLeftHalf !== prevState.isOverLeftHalf
        ) {
          return { isHovered: isCurrentlyOver, isOverLeftHalf };
        }
        return prevState;
      });

      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!dragItemRef.current) {
        return;
      }

      const hoverIndex = index ?? 0;
      const hoverBoundingRect = dragItemRef.current.getBoundingClientRect();
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      const clientOffset = monitor.getClientOffset();

      if (clientOffset) {
        const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left;
        const isOverLeftHalf = hoverClientX < hoverMiddleX;
        const isCurrentlyOver = monitor.isOver({ shallow: true });

        if (
          isCurrentlyOver !== hoverState.isHovered ||
          isOverLeftHalf !== hoverState.isOverLeftHalf
        ) {
          setHoverState({ isHovered: isCurrentlyOver, isOverLeftHalf });
        }

        if (hoverIndex !== undefined) {
          // eslint-disable-next-line no-param-reassign
          item.index = isOverLeftHalf ? hoverIndex : hoverIndex + 1;
        }
      }
    },
    drop(item: DragItem) {
      onDrop?.(item.index, item.id);
    },
  });

  // Combine drag and drop refs
  drop(dragRef(dragItemRef));

  return {
    dragItemRef,
    hoverState,
    isDragging,
    previewRef,
  };
}

export default useDraggableLink;
