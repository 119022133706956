import {
  Autocomplete,
  Avatar,
  Divider,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  MenuItem,
  SvgIcon,
} from '@mui/material';
import debounce from 'lodash.debounce';
import { useState, useMemo, useEffect, useCallback, ElementType } from 'react';
import { ResourceType, useSearchResultsByResourceQuery } from 'src/store/api/searchResourceApi';
import { usePostShareResourceMutation } from 'src/store/api/shareResourceApi';
import Empty from 'src/ui-components/custom/empty/Empty';
import {
  WorkbaseEmployeeIcon,
  WorkbaseDepartmentsIcon,
  WorkbaseRoleIcon,
} from 'src/assets/icons/workbaseIcons';
import { useTranslation } from 'react-i18next';
import { SearchTextField } from 'src/ui-components/custom/textfield/search';
import { TabValue } from '../access-list/TrainingAccessControlList';

type OptionType = 'companyUserId' | 'departmentId' | 'roleId';

enum OptionTypes {
  CompanyUser = 'companyUserId',
  Department = 'departmentId',
  Role = 'roleId',
}

interface Option {
  id: string;
  name: string;
  type: OptionType;
  description: string;
  avatarUrl: string | null;
}

interface Props {
  resourceId: string;
  resourceType: ResourceType;
  activeTabButton?: TabValue;
}

function SearchResourceTextField({ resourceId, resourceType, activeTabButton }: Props) {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [inputVal, setInputVal] = useState('');
  const [queryToSend, setQueryToSend] = useState(inputVal);
  const [shareResource] = usePostShareResourceMutation();

  const handleSearch = useCallback(
    (val: string) => {
      setInputVal(val);
    },
    [setInputVal]
  );

  const queryParameters = useMemo(
    () => ({
      resourceId,
      resourceType,
      query: queryToSend,
    }),
    [resourceId, resourceType, queryToSend]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleQueryToSendSet = useCallback(
    debounce((val: string) => {
      setQueryToSend(val);
    }, 220),
    []
  );

  useEffect(() => {
    debouncedHandleQueryToSendSet(inputVal);
  }, [inputVal, debouncedHandleQueryToSendSet]);

  const { data } = useSearchResultsByResourceQuery(queryParameters, {
    skip: !queryParameters.query,
    refetchOnMountOrArgChange: true,
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setInputVal('');
    setOpen(false);
  };

  const renderIcon = (type: OptionType): ElementType => {
    switch (type) {
      case 'companyUserId':
        return WorkbaseEmployeeIcon;
      case 'departmentId':
        return WorkbaseDepartmentsIcon;
      case 'roleId':
        return WorkbaseRoleIcon;
      default:
        return WorkbaseEmployeeIcon;
    }
  };

  const handleSearchResultItemClick = (
    keyName: 'companyUserId' | 'roleId' | 'departmentId',
    id: string
  ) => {
    shareResource({
      resourceId,
      resourceType,
      [keyName]: id,
      required: activeTabButton === 'required',
    });
    handleClose();
  };

  const sortedOptions: Option[] = useMemo(() => {
    if (!data) return [];
    return [
      ...data.companyUsers.map((user) => ({
        id: user.id,
        type: 'companyUserId' as const,
        name: `${user.details.firstName} ${user.details.lastName}`,
        description: user.details.email,
        avatarUrl: user.details.avatarUrl,
      })),
      ...data.departments.map((dept) => ({
        id: dept.id,
        type: 'departmentId' as const,
        name: dept.details.name,
        description: '',
        avatarUrl: null,
      })),
      ...data.roles.map((role) => ({
        id: role.id,
        type: 'roleId' as const,
        name: `${role.details.department.name} – ${role.details.name}`,
        description: '',
        avatarUrl: null,
      })),
    ];
  }, [data]);
  return (
    <Autocomplete
      data-cy="share-modal-search"
      onOpen={handleOpen}
      open={isOpen && !!queryToSend}
      onClose={handleClose}
      options={sortedOptions}
      disableClearable
      forcePopupIcon={false}
      selectOnFocus={false}
      inputValue={inputVal}
      clearOnBlur={false}
      filterOptions={(x) => x}
      noOptionsText={<Empty description={t('notFound')} />}
      getOptionLabel={(option: Option | string) =>
        typeof option === 'string' ? option : option.name
      }
      onChange={(_, value) => {
        if (value && typeof value !== 'string') {
          handleSearchResultItemClick(value.type, value.id);
        }
      }}
      renderInput={({ InputProps, ...restOfProps }) => (
        <SearchTextField
          {...restOfProps}
          slotProps={{
            input: { ref: InputProps.ref, className: InputProps.className },
          }}
          autoFocus
          value={inputVal}
          placeholder={t('add')}
          data-cy="share-modal-search-textfield"
          onChange={(e) => handleSearch(e.target.value)}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        />
      )}
      // using list item icon instead of list item avatar to have the same margin on the Right
      renderOption={(props, option) => {
        const companyUsers = data?.companyUsers || [];
        const isLastCompanyUser = option.id === companyUsers[companyUsers.length - 1]?.id;
        const showDivider =
          data && (data.departments.length > 0 || data.roles.length > 0) && isLastCompanyUser;
        const prefix =
          option.type === OptionTypes.CompanyUser ? (
            <ListItemAvatar>
              <Avatar src={option.avatarUrl as string}>{option.name[0]}</Avatar>
            </ListItemAvatar>
          ) : (
            <ListItemIcon>
              <SvgIcon component={renderIcon(option.type)} />
            </ListItemIcon>
          );
        return (
          <>
            <MenuItem {...props}>
              {prefix}
              <ListItemText
                primary={option.name}
                secondary={option.description}
                primaryTypographyProps={{ noWrap: true }}
                secondaryTypographyProps={{ noWrap: true }}
              />
            </MenuItem>
            {showDivider && <Divider />}
          </>
        );
      }}
    />
  );
}

export default SearchResourceTextField;
