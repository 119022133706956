import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ResourceType } from 'src/store/api/searchResourceApi';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import { ShareLevel } from 'src/lib/types/share-level';
import { bindDialog, PopupState } from 'material-ui-popup-state/hooks';
import SvgIconByName from 'src/ui-components/icon/SvgIconByName';
import useOnEnterTrigger from 'src/hooks/useOnEnterTrigger';
import SearchResourceTextField from './search-text-field/SearchResourceTextField';
import GeneralAccess from './GeneralAccess';
import AccessControlListGroup from './access-list/AccessControlListGroup';
import { TabValue } from './access-list/TrainingAccessControlList';
import useIsCustomerPortal from 'src/hooks/useIsCustomerPortal';
import SearchCustomerGroups from './search-text-field/SearchCustomerGroups';

interface Props {
  name: string;
  resourceId: string;
  resourceType: ResourceType;
  dialogState: PopupState;
  shareLevel: ShareLevel;
  dataCy?: string;
}

function ModalContent({
  shareLevel,
  dialogState,
  name,
  resourceId,
  resourceType,
  dataCy,
}: {
  shareLevel: ShareLevel;
  dialogState: PopupState;
  name: string;
  resourceId: string;
  resourceType: ResourceType;
  dataCy?: string;
}) {
  const { t } = useTranslation();
  const [activeTabButton, setActiveTabButton] = useState<TabValue>();
  const isCustomerPortal = useIsCustomerPortal();

  useOnEnterTrigger(dialogState.close);

  const copyToClipboard = useMemo(
    () => (id: string, type: string) => {
      const url = window.location.href;
      let modifiedUrl;
      let modifiedType = type;
      if (url.includes('documents')) {
        modifiedType = modifiedType.replace(/^embeddedApp$/, 'app');
        modifiedUrl = `${document.location.host}/${modifiedType}/${id}`;
      } else {
        modifiedUrl = url.replace(/\/edit\b/, '');
      }

      if (modifiedUrl) {
        navigator.clipboard.writeText(modifiedUrl).then(() => {
          showNotification(t('linkCopiedToClipboard'), 'success');
        });
      }
    },
    [t]
  );

  return (
    <>
      <DialogTitle>{`${t('share')} "${name}"`}</DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          <Box>
            {!isCustomerPortal ? (
              <SearchResourceTextField
                resourceId={resourceId}
                resourceType={resourceType}
                activeTabButton={activeTabButton}
              />
            ) : (
              <SearchCustomerGroups
                resourceId={resourceId}
                resourceType={resourceType}
                activeTabButton={activeTabButton}
              />
            )}
          </Box>
          <Stack gap={1} overflow="hidden">
            <Typography variant="body1" fontWeight="bold">
              {t(
                isCustomerPortal
                  ? 'permissionsShare.customerGroupsListLabel'
                  : 'permissionsShare.rolesAndPeopleListLabel'
              )}
            </Typography>
            <AccessControlListGroup
              dataCy={dataCy}
              activeTabButton={activeTabButton}
              setActiveTabButton={setActiveTabButton}
              resourceType={resourceType}
              resourceId={resourceId}
              currentShareLevel={shareLevel}
            />
          </Stack>
          <Stack gap={1}>
            <Typography variant="body1" fontWeight="bold">
              {t('permissionsShare.generalAccess')}
            </Typography>
            <GeneralAccess resourceId={resourceId} resourceType={resourceType} />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          data-cy={dataCy && `${dataCy}-copy-link-button`}
          color="inherit"
          onClick={() => copyToClipboard(resourceId, resourceType)}
          startIcon={<SvgIconByName iconName="WorkbaseCopyDuplicateIcon" />}
        >
          {t('permissionsShare.copyLinkButton')}
        </Button>
        <Box flexGrow={2}>
          <Button
            fullWidth
            onClick={dialogState.close}
            data-cy={dataCy && `${dataCy}-close-button`}
          >
            {t('permissionsShare.doneButton')}
          </Button>
        </Box>
      </DialogActions>
    </>
  );
}

export default function ShareModalDialog({
  name,
  resourceId,
  resourceType,
  dialogState,
  shareLevel,
  dataCy,
}: Props) {
  return (
    <Dialog {...bindDialog(dialogState)} maxWidth="sm" fullWidth>
      <ModalContent
        dataCy={dataCy}
        shareLevel={shareLevel}
        dialogState={dialogState}
        name={name}
        resourceId={resourceId}
        resourceType={resourceType}
      />
    </Dialog>
  );
}
