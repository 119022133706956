import { Stack, Paper, Typography, Box, SvgIcon, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseAIIcon } from 'src/assets/icons/workbaseIcons';
import { ChatStateMessage } from '../../helpers/types';
import AttachmentImage from './AttachmentImage';
import DynamicLottie from './DynamicLottie';
import MarkdownMessage from './MarkdownMessage';
import ResourceChip from './suggestions-textfield/ResourceChip';
import { ResultData } from 'src/store/api/searchApi';

interface WrappedMessageProps {
  message: ChatStateMessage;
  isMobile: boolean;
  waitingResponse?: boolean;
}

export function WrappedMessage({
  message,
  isMobile,
  waitingResponse = false,
}: WrappedMessageProps) {
  const imageIndex = message.content.findIndex((content) => content.type === 'image');
  const textIndex = message.content.findIndex((content) => content.type === 'text');
  const isUser = message.role === 'user';
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Stack
      key={message.id}
      direction="row"
      justifyContent={message.role === 'user' ? 'flex-end' : 'flex-start'}
      my={1}
      ml={isUser ? 5 : 0}
    >
      <Stack
        overflow="hidden"
        component={Paper}
        elevation={0}
        variant={isUser ? 'outlined' : 'elevation'}
        maxWidth={isMobile && !isUser ? '100%' : '75%'}
        px={isUser ? 1 : 0}
        flexGrow={isUser ? 0 : 1}
        py={1}
        bgcolor={isUser ? 'primary.main' : 'transparent'}
        borderRadius={`${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 ${theme.shape.borderRadius}px`}
        sx={{
          overflowWrap: 'break-word',
        }}
      >
        <Stack direction="row" alignItems="flex-start" width="100%" overflow="hidden">
          {isUser ? (
            <Typography variant="body1" color="primary.contrastText">
              {message?.content[textIndex]?.text}
            </Typography>
          ) : (
            <>
              <Box
                bgcolor="transparent"
                width={34}
                height={34}
                borderRadius="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexShrink={0}
                border={`1px solid ${theme.palette.divider}`}
              >
                <SvgIcon component={WorkbaseAIIcon} fontSize="small" />
              </Box>
              <MarkdownMessage message={message?.content[textIndex]?.text as string} />
            </>
          )}
          {waitingResponse && (
            <Stack direction="row" alignItems="flex-start" width="100%" pr={5} pt={0.5}>
              <Typography>{t('assistant.thinking')}</Typography>
              <DynamicLottie />
            </Stack>
          )}
        </Stack>
        {imageIndex !== -1 && (
          <Stack py={0.5}>
            <AttachmentImage
              imageUrl={message.content[imageIndex].image as string}
              fileName={message.content[imageIndex].file_name as string}
              readonly
            />
          </Stack>
        )}
        {message.resources && message.resources.length > 0 && (
          <Stack py={0.5} gap={0.5} direction="row" flexWrap="wrap">
            {message.resources.map((resource) => (
              <ResourceChip suggestion={resource as ResultData} key={resource.id} />
            ))}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
