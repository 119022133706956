import { useEffect } from 'react';
import useThemeLogos from 'src/hooks/useThemeLogos';
import { useTheme } from '@mui/material';
import { useSettingsQuery } from '../store/api/settingsApi';

interface Props {
  children: React.ReactNode;
}

export default function ManifestProvider({ children }: Props) {
  const { data } = useSettingsQuery();
  const { favicon } = useThemeLogos();
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  const backgroundColor = theme.palette.background.default;
  const siteName = data?.siteName;
  const siteDescription = data?.description;
  const faviconUrl = favicon.src;
  const isDefaultFavicon = favicon.isDefault;

  useEffect(() => {
    if (faviconUrl && siteName) {
      const buildIconUrl = (size: number) => {
        const baseUrl = isDefaultFavicon ? `${window.location.origin}` : '';
        const queryParams = isDefaultFavicon ? '' : `?width=${size}&height=${size}`;
        return `${baseUrl}${faviconUrl}${queryParams}`;
      };
      const id = `${new Date().getTime()}`;
      const manifest = {
        id,
        name: siteName,
        short_name: siteName,
        start_url: window.location.href,
        display: 'standalone',
        background_color: backgroundColor,
        lang: 'en',
        scope: window.location.origin,
        description: siteDescription || siteName,
        theme_color: primaryColor,
        icons: [
          {
            src: buildIconUrl(32),
            sizes: '32x32',
            type: 'image/png',
          },
          {
            src: buildIconUrl(192),
            sizes: '192x192',
            type: 'image/png',
          },
          {
            src: buildIconUrl(512),
            sizes: '512x512',
            type: 'image/png',
          },
        ],
      };

      const manifestURL = URL.createObjectURL(
        new Blob([JSON.stringify(manifest)], { type: 'application/json' })
      );

      // te
      const manifestLink = document.createElement('link');
      manifestLink.rel = 'manifest';
      manifestLink.href = `${manifestURL}`;
      document.head.appendChild(manifestLink);

      return () => {
        document.head.removeChild(manifestLink);
        URL.revokeObjectURL(manifestURL);
      };
    }
    return undefined;
  }, [faviconUrl, primaryColor, siteName, siteDescription, isDefaultFavicon, backgroundColor]);

  return children;
}
