import { ListItemIcon, ListItemText, MenuItem, MenuItemProps } from '@mui/material';
import { ResultData } from 'src/store/api/searchApi';
import HtmlContent from './HtmlContent';
import { useSearchResultsDict } from '../controller/useSearchResultsDict';

interface Props extends MenuItemProps {
  item: ResultData;
  dataCy?: string;
}

function SingleSearchResult({ item, onClick, dataCy, ...props }: Props) {
  const { searchResultDict, handleClick } = useSearchResultsDict(item);
  return (
    <MenuItem
      {...props}
      onClick={(e) => {
        handleClick();
        onClick?.(e);
      }}
      data-cy={dataCy && `${dataCy}-${item.id}`}
    >
      <ListItemIcon>{searchResultDict[item.type].prefix}</ListItemIcon>
      <ListItemText
        primary={<HtmlContent content={searchResultDict[item.type].name as string} />}
        secondary={<HtmlContent content={searchResultDict[item.type].description as string} />}
        secondaryTypographyProps={{
          variant: 'caption',
        }}
      />
    </MenuItem>
  );
}

export default SingleSearchResult;
