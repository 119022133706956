import { ResultData } from 'src/store/api/searchApi';

import { useSearchResultsDict } from 'src/features/global-search/controller/useSearchResultsDict';
import { Chip, Stack, Typography } from '@mui/material';

export default function ResourceChip({ suggestion, ...props }: { suggestion: ResultData }) {
  const { searchResultDict, handleClick } = useSearchResultsDict(suggestion, 'small', 'small');
  return (
    <Chip
      label={
        <Stack
          direction="row"
          alignItems="center"
          gap={0.5}
          onClick={handleClick}
          sx={{ cursor: 'pointer' }}
        >
          {searchResultDict[suggestion.type].prefix}
          <Typography variant="body2" noWrap>
            {suggestion.name}
          </Typography>
        </Stack>
      }
      {...props}
    />
  );
}
