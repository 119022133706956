import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/store/api/api';

interface RequestInvitationResponse {
  cooldown: number;
}

export const resendInvitationApi = createApi({
  reducerPath: 'resendInvitationApi',
  baseQuery,
  endpoints: (builder) => ({
    resendInvitationCooldown: builder.query<RequestInvitationResponse, string>({
      query: (id) => `/company-users/${id}/resend-invitation/cooldown`,
    }),
    postRequestResendInvitation: builder.mutation<RequestInvitationResponse, string>({
      query: (id) => ({
        url: `/company-users/${id}/resend-invitation`,
        method: 'POST',
      }),
      onQueryStarted: (id, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((data) => {
          dispatch(
            resendInvitationApi.util.updateQueryData(
              'resendInvitationCooldown',
              id,
              () => data.data
            )
          );
        });
      },
    }),
  }),
});

export const { useResendInvitationCooldownQuery, usePostRequestResendInvitationMutation } =
  resendInvitationApi;
