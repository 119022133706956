import { useEffect, useRef } from 'react';
import { MediaPlayerInstance, MediaTimeUpdateEventDetail } from '@vidstack/react';
import { VideoProgress } from 'src/store/api/trainings/videoProgressApi';

interface UseVideoProgressProps {
  playerRef: React.RefObject<MediaPlayerInstance>;
  watchedDuration?: number;
  disabledFastForward?: boolean;
  onVideoProgress?: (data: Partial<VideoProgress>) => void;
}

function useVideoProgress({
  playerRef,
  watchedDuration,
  disabledFastForward,
  onVideoProgress,
}: UseVideoProgressProps) {
  const currentWatchedDurationRef = useRef<number>(watchedDuration || 0);
  const currentTimeInitialized = useRef(false);

  useEffect(() => {
    if (playerRef.current && watchedDuration !== undefined && !currentTimeInitialized.current) {
      // eslint-disable-next-line no-param-reassign
      playerRef.current.currentTime = watchedDuration;
      currentTimeInitialized.current = true;
    }
  }, [watchedDuration, playerRef]);

  const handleTimeUpdate = (detail: MediaTimeUpdateEventDetail) => {
    if (watchedDuration === undefined) return;
    const { currentTime } = detail;
    if (currentTime > currentWatchedDurationRef.current) {
      const maxSeekDuration = playerRef.current?.paused ? 0.01 : 1;
      const isFastForwarding = currentTime - currentWatchedDurationRef.current > maxSeekDuration;
      if (isFastForwarding && disabledFastForward && playerRef.current) {
        // eslint-disable-next-line no-param-reassign
        playerRef.current.currentTime = currentWatchedDurationRef.current;
        return;
      }

      currentWatchedDurationRef.current = currentTime;
      onVideoProgress?.({ watchedDuration: currentTime });
    }
  };

  const handleEnded = () => {
    onVideoProgress?.({
      watchedDuration: playerRef.current?.duration,
      isWatched: true,
    });
  };

  return { handleTimeUpdate, handleEnded };
}

export default useVideoProgress;
