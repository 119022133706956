import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  addUploadingMedia,
  clearUploadingMedia,
} from 'src/features/editor/controller/Editor.slice';
import { FileType } from '../edit-bar/actions/UploadActions';

export default function useUploadingMedia(id: string, mediaType: FileType) {
  const dispatch = useDispatch();

  useEffect(() => {
    const uploadingMediaCleanup = () => {
      dispatch(clearUploadingMedia({ id }));
    };
    return uploadingMediaCleanup;
  }, [dispatch, id]);

  const handleClearUploading = useCallback(() => {
    dispatch(clearUploadingMedia({ id }));
  }, [dispatch, id]);
  const handleAddingUploading = () => dispatch(addUploadingMedia({ id, mediaType }));

  return { handleClearUploading, handleAddingUploading };
}
