import { Alert, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useChatState } from '../../helpers/chatContext';

interface AlertErrorProps {
  onSendMessage: (message: string, retry: boolean) => void;
}

export default function AiAlertError({ onSendMessage }: AlertErrorProps) {
  const { t } = useTranslation();
  const state = useChatState((state) => ({
    error: state.error,
    lastUserMessage: state.lastUserMessage,
  }));
  const handleRetry = () => {
    onSendMessage(state.lastUserMessage?.content[0].text as string, true);
  };
  if (!state.error) return null;
  return (
    <Alert
      severity="error"
      data-cy="ai-assistant-alert-error"
      action={
        <Button
          color="inherit"
          onClick={handleRetry}
          data-cy="ai-assistant-alert-error-retry-button"
        >
          {t('assistant.retry')}
        </Button>
      }
    >
      <Typography>{state.error}</Typography>
    </Alert>
  );
}
