import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useIsMobile from 'src/hooks/useIsMobile';
import SearchableSelect, {
  SearchableSelectProps,
} from 'src/ui-components/custom/searchable-select/SearchableSelect';

export default function Selector({ options, value, onChange }: SearchableSelectProps) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  if (options.length <= 1) return null;
  const selectedOption = options.find((option) => option.value === value);

  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      justifyContent="space-between"
      alignItems="center"
      gap={2}
    >
      <Typography variant="body1" fontWeight="medium" noWrap flexShrink={0}>
        {t('dashboardPage.header.roleSelectorQuestion')}
      </Typography>
      <SearchableSelect
        dataCy="dashboard-selector"
        onChange={onChange}
        value={selectedOption}
        options={options}
      />
    </Stack>
  );
}
