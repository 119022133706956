import {
  WorkbaseButtonIcon,
  WorkbaseCodeIcon,
  WorkbaseImageIcon,
  WorkbaseInfoIcon,
  WorkbaseLineIcon,
  WorkbaseSpacerV1,
  WorkbaseTextIcon,
  WorkbaseTitleIcon,
  WorkbaseTrainingsIcon,
  WorkbaseVideoIcon,
} from 'src/assets/icons/workbaseIcons';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import useGenerateEditorTopBarItem from './useGenerateEditorTopBarItem';

const focusTrainingItem = () =>
  (
    document.querySelector(
      '.editor-grid > :nth-last-child(2) .training-item-title-text-field input'
    ) as HTMLInputElement
  )?.focus();

export default function useBaseEditorTopBarItems() {
  const generateEditorTopBarItem = useGenerateEditorTopBarItem();
  const { t } = useTranslation();
  const [addEmbeddedDialogOpen, setAddEmbeddedDialogOpen] = useState(false);

  const baseEditorTopBarItems = [
    generateEditorTopBarItem({
      key: 'spacer',
      tooltip: t('editor.topBar.spacerlabel'),
      elementType: 'spacer',
      icon: WorkbaseSpacerV1,
    }),
    generateEditorTopBarItem({
      key: 'heading',
      tooltip: t('editor.topBar.headinglabel'),
      elementType: 'heading',
      icon: WorkbaseTitleIcon,
      // prettier-ignore
      content: '<h2 data-node-text-align="center" style=" text-align:center"></h2>',
    }),
    generateEditorTopBarItem({
      key: 'text-item',
      tooltip: t('editor.topBar.textItemlabelTextItem'),
      elementType: 'textItem',
      icon: WorkbaseTextIcon,
      // prettier-ignore
      content: '<p data-node-text-align="left" style=" text-align:left"></p>',
    }),
    generateEditorTopBarItem({
      key: 'button',
      tooltip: t('editor.topBar.buttonlabel'),
      elementType: 'button',
      icon: WorkbaseButtonIcon,
      // prettier-ignore
      content: '<p data-node-text-align="center" style=" text-align:center"></p>',
    }),
    generateEditorTopBarItem({
      key: 'image',
      tooltip: t('editor.topBar.imagelabel'),
      elementType: 'upload',
      icon: WorkbaseImageIcon,
      extraProps: { mediaType: 'image', columnStart: 1, columnEnd: 5 },
    }),
    generateEditorTopBarItem({
      key: 'video',
      tooltip: t('editor.topBar.videolabel'),
      elementType: 'upload',
      icon: WorkbaseVideoIcon,
      extraProps: { mediaType: 'video' },
    }),
    generateEditorTopBarItem({
      key: 'info-panel',
      tooltip: t('editor.topBar.infoPanellabel'),
      elementType: 'infoPanel',
      icon: WorkbaseInfoIcon,
      extraProps: { infoType: 'info' },
      // prettier-ignore
      content: '<p data-node-text-align="left" style=" text-align:left"></p>',
    }),
    generateEditorTopBarItem({
      key: 'divider',
      tooltip: t('editor.topBar.dividerlabel'),
      elementType: 'divider',
      icon: WorkbaseLineIcon,
    }),
    {
      key: 'embedded',
      tooltip: t('editor.topBar.embeddedlabel'),
      icon: WorkbaseCodeIcon,
      onClick: () => {
        setAddEmbeddedDialogOpen(true);
      },
    },
    generateEditorTopBarItem({
      key: 'trainings',
      tooltip: t('editor.topBar.trainingsLabel'),
      elementType: 'trainings',
      icon: WorkbaseTrainingsIcon,
      extraProps: {
        selectedItems: [],
        selectedAllItems: false,
        title: '',
        trainingsType: 'trainings',
      },
      onClickCallback: focusTrainingItem,
    }),
  ];
  return { baseEditorTopBarItems, addEmbeddedDialogOpen, setAddEmbeddedDialogOpen };
}
