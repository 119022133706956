import { AffineEditorContainer } from '@blocksuite/presets';
import { useEffect, useState } from 'react';
import { HocuspocusProvider } from '@hocuspocus/provider';
import { useAppSelector } from 'src/store';
import { Doc, Schema, DocCollection } from '@blocksuite/store';
import { AffineSchemas, AffineSlashMenuWidget } from '@blocksuite/blocks';

AffineSlashMenuWidget.DEFAULT_CONFIG = {
  ...AffineSlashMenuWidget.DEFAULT_CONFIG,
  items: AffineSlashMenuWidget.DEFAULT_CONFIG.items.filter((props: any) => {
    return props.groupName !== 'Page' && props.name !== 'New Doc' && props.name !== 'Linked Doc';
  }),
};

const parsedUrl = new URL(import.meta.env.VITE_API_URL);
const socketProtocol = parsedUrl.protocol === 'https:' ? 'wss' : 'ws';
const socketUrl = `${socketProtocol}://${parsedUrl.host}/editor`;

export default function useInitEditor(name: string, readOnly: boolean) {
  const [editor, setEditor] = useState<AffineEditorContainer>();
  const [doc, setDoc] = useState<Doc>();
  const token = useAppSelector((state) => state.auth.token);

  useEffect(() => {
    if (!token) return undefined;
    const schema = new Schema().register(AffineSchemas);
    const collection = new DocCollection({
      schema,
      defaultFlags: {
        enable_synced_doc_block: false,
      },
    });
    collection.meta.initialize();

    const doc = collection.createDoc({ id: name });
    doc.load(() => {
      const pageEditor = new AffineEditorContainer();
      pageEditor.doc = doc;
      const provider = new HocuspocusProvider({
        url: socketUrl,
        name,
        document: doc.spaceDoc,
        token,
        onSynced: (isSynced) => {
          if (isSynced) {
            const wsBlocks = doc.getBlocks();
            if (wsBlocks.length === 0) {
              const pageBlockId = doc.addBlock('affine:page', {});
              doc.addBlock('affine:surface', {}, pageBlockId);
              const noteId = doc.addBlock('affine:note', {}, pageBlockId);
              doc.addBlock('affine:paragraph', {}, noteId);
            }
          }
        },
      });
      setEditor(pageEditor);
      setDoc(doc);
      return () => {
        provider.configuration.websocketProvider.disconnect();
        provider.configuration.websocketProvider.destroy();
        provider.disconnect();
        provider.destroy();
      };
    });
    return undefined;
  }, [token, name]);

  useEffect(() => {
    if (doc) {
      doc.awarenessStore.setReadonly(doc.blockCollection, readOnly);
    }
  }, [doc, readOnly]);

  return { editor };
}
