import { useEffect } from 'react';
import { useUpload } from 'src/core/upload/UploadContext';
import { UploadStatus } from 'src/core/upload/types';
import { useConfirm } from 'material-ui-confirm';

export default function BlockerComponent() {
  const { uploadingFiles } = useUpload();
  const confirm = useConfirm();

  const isUploading = Object.values(uploadingFiles).some(
    (file) => file.status === UploadStatus.UPLOADING
  );

  useEffect(() => {
    const handleBeforeUnload = async (e: any) => {
      if (isUploading) {
        e.preventDefault();
        // Some browsers require returnValue to be set to trigger the dialog
        // eslint-disable-next-line no-param-reassign
        e.returnValue = '';
        return '';
      }
      return undefined;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isUploading, confirm]);

  return null;
}
