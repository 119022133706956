import { Stack } from '@mui/material';
import { useUpload } from './UploadContext';
import UploadProgressWindow from './UploadProgressWindow';
import { UploadStatus } from './types';

function UploadProgressWindowWithContext() {
  const { isOpen, collapsed, setCollapsed, setIsOpen, uploadingFiles } = useUpload();

  if (!isOpen) {
    return null;
  }

  const isAllUploadsComplete = Object.values(uploadingFiles).every(
    (file) => file.status !== UploadStatus.UPLOADING
  );
  const successUploadsNumber = Object.values(uploadingFiles).filter(
    (file) => file.status === UploadStatus.SUCCESS
  ).length;
  return (
    <Stack
      position="fixed"
      left="50%"
      bottom={-18}
      right="50%"
      justifyContent="center"
      alignItems="center"
      zIndex={2}
      sx={{ transform: 'translateX(-50%)' }}
    >
      <UploadProgressWindow
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        setIsOpen={setIsOpen}
        uploadsNumber={Object.keys(uploadingFiles).length}
        successUploadsNumber={successUploadsNumber}
        isAllUploadsComplete={isAllUploadsComplete}
      />
    </Stack>
  );
}

export default UploadProgressWindowWithContext;
