import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';

interface DashboardState {
  selectedRole?: string;
  selectedCustomerGroup?: string;
  selectedEmployeePortalNewsfeedItemIdx: number;
  selectedCustomerPortalNewsfeedItemIdx: number;
}

const initialState: DashboardState = {
  selectedRole: undefined,
  selectedEmployeePortalNewsfeedItemIdx: 0,
  selectedCustomerPortalNewsfeedItemIdx: 0,
};

const persistConfig = {
  key: 'dashboard',
  storage,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setSelectedRole: (state, action: PayloadAction<string>) => {
      state.selectedRole = action.payload;
    },
    setSelectedCustomerGroup: (state, action: PayloadAction<string | undefined>) => {
      state.selectedCustomerGroup = action.payload;
    },
    setSelectedEmployeePortalNewsfeedItemIdx: (state, action: PayloadAction<number>) => {
      state.selectedEmployeePortalNewsfeedItemIdx = action.payload;
    },
    setSelectedCustomerPortalNewsfeedItemIdx: (state, action: PayloadAction<number>) => {
      state.selectedEmployeePortalNewsfeedItemIdx = action.payload;
    },
  },
});

export const {
  setSelectedRole,
  setSelectedCustomerPortalNewsfeedItemIdx,
  setSelectedEmployeePortalNewsfeedItemIdx,
  setSelectedCustomerGroup,
} = dashboardSlice.actions;

export const dashboardReducer = persistReducer(persistConfig, dashboardSlice.reducer);
