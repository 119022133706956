import { memo } from 'react';
import AppSidebar from 'src/features/app-sidebar';
import { Stack, Grid2 as Grid, Box, Paper } from '@mui/material';
import { useChatState } from 'src/features/ai-chat/helpers/chatContext';
import ChatPanel from 'src/views/ai-chat/ChatPanel';
import { Outlet } from 'react-router-dom';
import useIsMobile from 'src/hooks/useIsMobile';
import AppSidedrawer from 'src/features/app-sidebar/AppSidedrawer';
import CompanySettingsPopup from './CompanySettingsPopup';
import EmployeesPopup from './EmployeesPopup';
import { useBlurOnEscape } from 'src/hooks/useBlurOnEscape';
import AppHeader from './app-header';

function MainLayout() {
  const { isOpen, isFullScreen } = useChatState((state) => ({
    isOpen: state.isOpen,
    isFullScreen: state.isFullScreen,
  }));
  const renderChat = isOpen && !isFullScreen;
  const isMobile = useIsMobile();
  useBlurOnEscape();
  return (
    <Stack minWidth="100%" width="100%" direction="row" height="100%" pb={isMobile ? 0 : 2}>
      <CompanySettingsPopup />
      <EmployeesPopup />
      {!isMobile && <AppSidebar />}
      <Stack mr={isMobile ? 0 : 2} flexGrow={1} flexShrink={1} minWidth={0} height="100%">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mx={isMobile ? 2 : 0}
          gap={2}
        >
          {isMobile && <AppSidedrawer />}
          <AppHeader />
        </Stack>
        <Grid
          container
          spacing={renderChat ? 1 : 0}
          columns={7}
          flexGrow={1}
          overflow="auto"
          height="100%"
        >
          {renderChat && (
            <Grid size={2} maxHeight="100%" minWidth={440}>
              <ChatPanel isMobile={isMobile} />
            </Grid>
          )}
          <Grid size="grow" maxHeight="100%">
            <Box flexShrink={1} minHeight={0} height="100%">
              <Paper
                id="main-layout-card"
                variant="outlined"
                // eslint-disable-next-line no-restricted-syntax
                sx={{
                  height: '100%',
                  overflow: 'auto',
                  position: 'relative',
                  borderRadius: isMobile ? 0 : undefined,
                  border: isMobile ? 'none' : undefined,
                }}
              >
                <Outlet />
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
}

export default memo(MainLayout);
