import { EmbeddedItemContentType } from 'src/features/editor/model';
import { useBoolean } from 'src/lib/hooks/use-boolean';
import { useEffect, useRef, useState } from 'react';
import { WorkbaseHelpIcon } from 'src/assets/icons/workbaseIcons';
import ensureHttpProtocol from 'src/lib/utils/ensureHttpProtocol';
import { useTranslation } from 'react-i18next';
import { SvgIcon } from '@mui/material';
import replaceParamsInLink from 'src/features/embedded-app/helpers';
import { useCurrentUserQuery } from 'src/store/api/currentUserApi';
import { ResizeStopCallbackType } from '../highlight-wrapper/resize/ResizableWrapper';
import HighlightWrapper from '../highlight-wrapper';
import BorderWrapper from '../border-wrapper';
import EmbeddedItemModal from './modal/EmbeddedItemModal';

interface Props {
  onDelete: () => void;
  onDuplicate: () => void;
  readOnly?: boolean;
  onResizeStop?: ResizeStopCallbackType;
  dragging?: any;
  onResizeStart?: () => void;
  height?: number;
  contentType: EmbeddedItemContentType;
  content: string;
  onUpdate: (props: { contentType: EmbeddedItemContentType; content: string }) => void;
  id: string;
}

export default function EmbeddedItem({
  onDelete,
  onDuplicate,
  readOnly,
  onResizeStop,
  dragging,
  onResizeStart,
  height,
  contentType,
  content,
  onUpdate,
  id,
}: Props) {
  const ref = useRef<any>();
  const { t } = useTranslation();
  const dialog = useBoolean();
  const { data: user } = useCurrentUserQuery();
  const [hasError, setHasError] = useState(false);
  const iframeProps =
    contentType === 'url'
      ? {
          src: user
            ? ensureHttpProtocol(replaceParamsInLink(content, user))
            : ensureHttpProtocol(content),
        }
      : {
          srcDoc: `<style>
    body, html {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
    }
</style>
${replaceParamsInLink(content, user)}`,
        };

  useEffect(() => {
    setHasError(false);
  }, [content]);

  function getPointerEventsVal(focused: boolean) {
    if (readOnly) return 'auto';
    return focused ? 'auto' : 'none';
  }

  const handleLoad = () => {
    const innerDoc = ref.current.contentDocument || ref.current.contentWindow.document;
    if (!innerDoc.body.innerHTML) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  };

  return (
    <HighlightWrapper
      dataCy="editor-widgets-embedded-item"
      id={id}
      editBarProps={{
        baseActionsProps: { onDelete, onDuplicate },
        embeddedActionsProps: { onClick: () => dialog.onTrue() },
      }}
      horizontalResize={{}}
      dragging={dragging}
      verticalResize={{}}
      readOnly={readOnly}
      onResizeStop={onResizeStop}
      onResizeStart={onResizeStart}
      minHeight={160}
      height={height}
    >
      {({ EditBarComponent, focused }) => (
        <BorderWrapper
          className="embedded-box"
          // eslint-disable-next-line no-restricted-syntax
          sx={{
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {EditBarComponent}
          {hasError ? (
            <SvgIcon component={WorkbaseHelpIcon} fontSize="large" />
          ) : (
            <iframe
              ref={ref}
              {...iframeProps}
              style={{
                pointerEvents: getPointerEventsVal(focused),
                border: 'none',
              }}
              title={t('editor.embeddedItemModal.title')}
              width="100%"
              sandbox="allow-scripts allow-popups allow-forms allow-same-origin allow-popups-to-escape-sandbox allow-downloads allow-storage-access-by-user-activation"
              height="100%"
              onLoad={handleLoad}
            />
          )}
          {!readOnly && (
            <EmbeddedItemModal
              open={dialog.value}
              onClose={dialog.onFalse}
              initialContent={content}
              initialContentType={contentType}
              saveButtonText={t('editor.embeddedItemModal.saveConfirmButton')}
              onConfirm={onUpdate}
            />
          )}
        </BorderWrapper>
      )}
    </HighlightWrapper>
  );
}
