import React, { useState, useMemo, ChangeEvent, memo, RefObject, useRef } from 'react';
import { TextField, Autocomplete, Box } from '@mui/material';
import { ResultData, useSearchAiQuery } from 'src/store/api/searchApi';
import SuggestionItem from './SuggestionItem';
import ResourceChip from './ResourceChip';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';

function SuggestionTextfield({
  value,
  setValue,
  selectedItems,
  setSelectedItems,
  handleSendMessage,
  blockSend,
  inputRef,
}: {
  value: string;
  setValue: (value: string) => void;
  selectedItems: ResultData[];
  setSelectedItems: (items: ResultData[]) => void;
  handleSendMessage: (additionalMetadata?: any) => void;
  blockSend: boolean;
  inputRef: RefObject<HTMLInputElement>;
}) {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [open, setOpen] = useState(false);

  const debouncedSetSearchTerm = useRef(debounce(setSearchTerm, 500)).current;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setValue(inputValue);

    const atIndex = inputValue?.lastIndexOf('@');
    if (atIndex !== -1) {
      const newSearchTerm = inputValue?.slice(atIndex + 1).toLowerCase();
      debouncedSetSearchTerm(newSearchTerm);
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const { data } = useSearchAiQuery(searchTerm, {
    skip: searchTerm.trim() === '',
  });

  const sortedSuggestions = useMemo(() => {
    if (!data?.length) return [];
    const companyUsers = data?.filter((item) => item.type === 'companyUser');
    const otherItems = data?.filter((item) => item.type !== 'companyUser');
    if (companyUsers.length && !otherItems.length) return [...companyUsers];
    return [...companyUsers, ...otherItems];
  }, [data]);

  return (
    <Box maxWidth="100%" width="100%" overflow="hidden">
      <Autocomplete
        options={sortedSuggestions || []}
        value={selectedItems}
        disableClearable
        inputValue={value}
        data-cy="ai-assistant-chat-ask-textfield"
        filterOptions={(x) => x}
        getOptionKey={(option) => (option as ResultData).id}
        getOptionLabel={(option) => (option as ResultData).name}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t('assistant.add')}
            multiline
            value={value}
            maxRows={4}
            autoFocus
            onChange={(e) => handleInputChange(e as ChangeEvent<HTMLInputElement>)}
            autoComplete="off"
            inputRef={inputRef}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey && !blockSend && !open) {
                if (!value.trim()) return;
                e.preventDefault();
                e.stopPropagation();
                handleSendMessage();
                setOpen(false);
              }
            }}
          />
        )}
        onChange={(_, val) => {
          setSelectedItems(
            (val as ResultData[]).filter((item): item is ResultData => typeof item !== 'string')
          );
          if (!open) return;
          const inputValue = value.replace(/@.*$/, '');
          setValue(inputValue);
          setOpen(false);
        }}
        forcePopupIcon={false}
        open={open}
        fullWidth
        size="small"
        slotProps={{
          popper: {
            disablePortal: true,
            placement: 'top',
          },
        }}
        multiple
        freeSolo
        renderTags={(value, getTagProps) =>
          value.map((item) => {
            const props = getTagProps({ index: value.indexOf(item) });
            // eslint-disable-next-line react/jsx-key
            return <ResourceChip suggestion={item} {...props} />;
          })
        }
        renderOption={(props, option) => <SuggestionItem {...props} suggestion={option} />}
      />
    </Box>
  );
}

export default memo(SuggestionTextfield);
