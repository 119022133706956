import { Stack, TextField, TextFieldProps } from '@mui/material';
import { ChangeEvent, useCallback, useState } from 'react';
import useDebouncedValue from 'src/hooks/useDebouncedValue';
import IconOrEmojiSelector from 'src/ui-components/custom/icon-selector';
import IconText from './IconText';

interface Props {
  isEditable?: boolean;
  icon: string;
  text?: string;
  placeholder: string;
  onTextChange: (val: string) => void;
  onIconChange: (val: string) => void;
  textFieldProps?: TextFieldProps;
  backdrop?: boolean;
}

export default function EditableIconText({
  isEditable = true,
  icon,
  text,
  placeholder,
  onTextChange,
  onIconChange,
  textFieldProps,
  backdrop,
}: Props) {
  const [wasFocused, setWasFocused] = useState(false);
  const handleTextChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onTextChange?.(e.target.value);
    },
    [onTextChange]
  );
  const { value, handleChange } = useDebouncedValue(text, handleTextChange);
  const readOnlyContent = <IconText icon={icon} text={text || placeholder} />;
  const editContent = (
    <Stack direction="row" gap={2} alignItems="center">
      <IconOrEmojiSelector value={icon} onChange={onIconChange} buttonSize="large" />
      <Stack bgcolor={backdrop ? 'background.paper' : 'transparent'} width="100%" borderRadius={1}>
        <TextField
          data-cy="editable-icon-textfield"
          onFocus={() => setWasFocused(true)}
          placeholder={placeholder}
          autoFocus={!wasFocused}
          value={value}
          onChange={handleChange}
          // eslint-disable-next-line no-restricted-syntax
          sx={{
            '& fieldset': {
              border: 'none',
            },
          }}
          {...textFieldProps}
        />
      </Stack>
    </Stack>
  );
  return isEditable ? editContent : readOnlyContent;
}
