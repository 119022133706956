import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/store/api/api';

export type ResourceType =
  | 'page'
  | 'trainingLesson'
  | 'training'
  | 'trainingModule'
  | 'embeddedApp'
  | 'companyUser'
  | 'wikiCard'
  | 'whiteboard'
  | 'assistantThread';

export interface ResultData {
  id: string;
  name: string;
  trainingId: string | null;
  trainingModuleId: string | null;
  trainingSectionId: string | null;
  imageUrl: string | null;
  type: ResourceType;
  headline: string | null;
  email: string;
  icon: string | null;
  defaultImage: string | null;
}

export const searchApi = createApi({
  reducerPath: 'search',
  tagTypes: ['Search'],
  baseQuery,
  endpoints: (builder) => ({
    search: builder.query<ResultData[], string>({
      query: (searchQuery) => `/search/global?query=${searchQuery}`,
      keepUnusedDataFor: 0,
      providesTags: ['Search'],
    }),
    searchAi: builder.query<ResultData[], string>({
      query: (searchQuery) => `/search/ai-global?query=${searchQuery}`,
      keepUnusedDataFor: 0,
      providesTags: ['Search'],
    }),
  }),
});

export const { useSearchQuery, useLazySearchQuery, useSearchAiQuery, useLazySearchAiQuery } =
  searchApi;
