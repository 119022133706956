import { Box, Container, Grid2, Stack } from '@mui/material';
import { useAppSelector } from 'src/store';
import { GridItemType, GridRow } from '../../model/types';
import findItemInColumn, { isEmptyRowInReadOnly } from './utils';
import GridCell from './GridCell';
import useElementConfigs from './useElementConfigs';
import DroppableRowInBetween from './droppable-items/DroppableRowInBetween';
import DroppableSideRowItem from './droppable-items/DroppableSideRowItem';
import DroppableEmptyCell from './droppable-items/DroppableEmptyCell';
import RowDashedVerticalLines from './RowDashedVerticalLines';

interface Props {
  row: GridRow;
  readOnly: boolean;
  index: number;
}

function RowItemsRenderer({
  gridRow,
  readOnly,
  rowIndex,
  hasFullWidthItem,
}: {
  gridRow: GridRow;
  readOnly: boolean;
  rowIndex: number;
  hasFullWidthItem: boolean;
}) {
  const isDragging = useAppSelector((state) => state.editor.isDragging);
  const resizingRowId = useAppSelector((state) => state.editor.resizingRowId);
  const horizontalLinesVisibility = isDragging || resizingRowId === gridRow.id;
  const cells: JSX.Element[] = [];
  const elementConfigs = useElementConfigs(gridRow.id);
  const renderItem = (column: number) => {
    const item: GridItemType | undefined = findItemInColumn(gridRow.items, column);

    if (!item) {
      cells.push(<DroppableEmptyCell key={column} row={gridRow} colStart={column} />);
      return column + 1;
    }
    const element = elementConfigs[item.elementType];
    if (element?.fullWidth) {
      cells.push(
        <GridCell key={item.id} row={gridRow} rowIndex={rowIndex} item={item} readOnly={readOnly} />
      );
      return 5; // Set column value beyond the loop limit to exit the loop.
    }
    const cols = item.columnEnd - item.columnStart;
    cells.push(
      <GridCell
        key={item.id}
        row={gridRow}
        item={item}
        rowIndex={rowIndex}
        cols={cols}
        readOnly={readOnly}
      />
    );
    return column + cols;
  };

  let column = 1;
  while (column <= 4) {
    column = renderItem(column);
  }

  return (
    <Grid2
      id="editor-grid"
      className={hasFullWidthItem ? 'editor-full-width-item' : ''}
      container
      spacing={2}
      py={1}
      component={Stack}
      position="relative"
      zIndex={4}
      width="100%"
      border={readOnly ? 0 : 1}
      borderTop={0}
      borderBottom={0}
      borderColor="divider"
      sx={{
        borderStyle: 'dashed',
      }}
    >
      {cells}
      {!hasFullWidthItem && horizontalLinesVisibility && <RowDashedVerticalLines />}
    </Grid2>
  );
}

export default function Row({ row, readOnly, index }: Props) {
  const borderColor = readOnly ? 'transparent' : 'divider';
  const isDragging = useAppSelector((state) => state.editor.isDragging);
  const elementConfigs = useElementConfigs(row.id);
  const hasFullWidthItem = row.items.some((item) => elementConfigs[item.elementType]?.fullWidth);

  if (readOnly && isEmptyRowInReadOnly(row)) {
    return null;
  }

  const rowItems = (
    <RowItemsRenderer
      gridRow={row}
      hasFullWidthItem={hasFullWidthItem}
      rowIndex={index}
      readOnly={readOnly}
    />
  );

  return (
    <Box position="relative" id={row.id}>
      {isDragging && <DroppableRowInBetween rowIndex={index} />}
      <Box
        px={2}
        sx={{
          borderColor,
          borderStyle: 'dashed',
          borderBottomWidth: '1px',
          borderTop: 'none',
          minHeight: '0',
          minWidth: '0',
          position: 'relative',
          zIndex: 1,
          borderLeft: 'none',
          borderRight: 'none',
          display: 'flex',
          flexWrap: 'nowrap',
          justifyContent: 'center',
        }}
      >
        {!hasFullWidthItem && <DroppableSideRowItem row={row} position="left" />}
        {hasFullWidthItem ? rowItems : <Container maxWidth="lg">{rowItems}</Container>}
        {!hasFullWidthItem && <DroppableSideRowItem row={row} position="right" />}
      </Box>
    </Box>
  );
}
