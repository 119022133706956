import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import {
  ImageUploadOptions,
  UploadingFile,
  UploadOptions,
  UploadStatus,
  VideoUploadOptions,
} from './types';
import useUploadFunctions from './helpers/useUploadFunctions';

interface UploadContextState {
  isOpen: boolean;
  progress: {
    [key: string]: number;
  };
  setIsOpen: (isOpen: boolean) => void;
  cancelUpload: (fileId: string) => void;
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
  uploadFile: (options: UploadOptions) => Promise<any>;
  uploadVideo: (options: VideoUploadOptions) => Promise<any>;
  uploadImage: (options: ImageUploadOptions) => Promise<any>;
  removeFile: (fileId: string) => void;
  uploadingFiles: { [key: string]: UploadingFile };
  clearProgress: (fileId: string) => void;
}

const initialState: UploadContextState = {
  isOpen: false,
  progress: {},
  setIsOpen: () => {},
  cancelUpload: () => {},
  collapsed: false,
  setCollapsed: () => {},
  uploadFile: () => Promise.resolve({}),
  uploadVideo: () => Promise.resolve({}),
  uploadImage: () => Promise.resolve({}),
  removeFile: () => {},
  uploadingFiles: {},
  clearProgress: () => {},
};

export const UploadContext = createContext<UploadContextState>(initialState);

interface UploadProviderProps {
  children: ReactNode;
}

function UploadProvider({ children }: UploadProviderProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [progress, setProgress] = useState<{ [key: string]: number }>({});
  const [uploadingFiles, setUploadingFiles] = useState<{ [key: string]: UploadingFile }>({});
  const [signals, setSignals] = useState<{ [key: string]: AbortController }>({});
  useEffect(() => {
    if (Object.keys(uploadingFiles).length > 0) {
      if (!isOpen) {
        setIsOpen(true);
        setCollapsed(false);
        setTimeout(() => {
          setCollapsed(true);
        }, 700);
      }
    } else {
      setIsOpen(false);
    }
  }, [uploadingFiles, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setUploadingFiles({});
      setProgress({});
      setSignals({});
      setCollapsed(true);
    }
  }, [isOpen]);

  const updateProgress = (fileId: string, progressValue: number) => {
    setProgress((prev) => ({
      ...prev,
      [fileId]: progressValue,
    }));
  };

  const clearProgress = useCallback((fileId: string) => {
    setProgress((prev) => {
      const newProgress = { ...prev };
      delete newProgress[fileId];
      return newProgress;
    });
  }, []);

  const cancelUpload = useCallback(
    (fileId: string) => {
      signals[fileId]?.abort();
      if (signals[fileId]?.signal?.aborted) {
        setUploadingFiles((prev) => ({
          ...prev,
          [fileId]: { ...prev[fileId], status: UploadStatus.ERROR },
        }));
        clearProgress(fileId);
      }
    },
    [signals, clearProgress]
  );

  const removeFile = useCallback(
    (fileId: string) => {
      signals[fileId]?.abort();
      setUploadingFiles((prev) => {
        const newUploadingFiles = { ...prev };
        delete newUploadingFiles[fileId];
        return newUploadingFiles;
      });
      clearProgress(fileId);
    },
    [signals, clearProgress]
  );

  const { uploadFile, uploadVideo, uploadImage } = useUploadFunctions({
    setSignals,
    setUploadingFiles,
    updateProgress,
  });

  const contextValue: UploadContextState = useMemo(
    () => ({
      isOpen,
      progress,
      setIsOpen,
      cancelUpload,
      collapsed,
      setCollapsed,
      uploadFile,
      uploadVideo,
      uploadImage,
      removeFile,
      uploadingFiles,
      clearProgress,
    }),
    [
      isOpen,
      progress,
      collapsed,
      uploadingFiles,
      cancelUpload,
      removeFile,
      uploadFile,
      uploadVideo,
      uploadImage,
      clearProgress,
    ]
  );

  return <UploadContext.Provider value={contextValue}>{children}</UploadContext.Provider>;
}

function useUpload() {
  const context = useContext(UploadContext);
  if (!context) {
    throw new Error('useUpload must be used within an UploadProvider');
  }
  return context;
}

export { UploadProvider, useUpload };
