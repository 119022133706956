import { TabList } from '@mui/lab';
import { Button, Stack, SvgIcon, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseContentNewIcon } from 'src/assets/icons/workbaseIcons';
import useIsMobile from 'src/hooks/useIsMobile';
import { DocumentsTabData, TabValue } from './types';

interface Props {
  tabsData: DocumentsTabData[];
  onActiveTabChange: (val: TabValue) => void;
  openUpload: () => void;
}

export default function DocumentsHeader({ tabsData, onActiveTabChange, openUpload }: Props) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  return (
    <Stack direction={isMobile ? 'column' : 'row'} justifyContent="space-between" pt={2} gap={1}>
      <TabList
        variant={isMobile ? 'fullWidth' : 'standard'}
        onChange={(_, newVal) => onActiveTabChange(newVal)}
      >
        {tabsData.map(({ label, key }) => (
          <Tab
            disableTouchRipple
            key={key}
            label={label}
            value={key.toString()}
            data-cy={`documents-header${key}-tab`}
          />
        ))}
      </TabList>
      <Button
        data-cy="documents-header-upload-button"
        onClick={openUpload}
        startIcon={<SvgIcon component={WorkbaseContentNewIcon} fontSize="small" />}
      >
        {t('documentsPage.uploadButton')}
      </Button>
    </Stack>
  );
}
