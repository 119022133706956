import { Box, Link } from '@mui/material';
import useThemeLogos from 'src/hooks/useThemeLogos';
import { Link as ReactRouterLink } from 'react-router-dom';
import Image from 'src/ui-components/image';

interface Props {
  fullWidth: boolean;
}

export default function AppLogo({ fullWidth }: Props) {
  const { logo, favicon } = useThemeLogos();
  const imgData = fullWidth ? logo : favicon;

  return (
    <Box width={fullWidth ? 180 : 44} mx={fullWidth ? '14px' : '2px'} flexShrink={0} mb={1}>
      <Link to="/" component={ReactRouterLink} data-cy="app-logo">
        <Image
          height={48}
          fit="contain"
          src={imgData.src}
          alt={imgData.alt}
          objectPosition={fullWidth ? 'left' : 'center'}
          borderRadius={0.2}
        />
      </Link>
    </Box>
  );
}
