import { memo, ReactNode } from 'react';
import { Stack, Box, Paper } from '@mui/material';
import useIsMobile from 'src/hooks/useIsMobile';
import { useBlurOnEscape } from 'src/hooks/useBlurOnEscape';

function PublicPageLayout({ children }: { children: ReactNode }) {
  const isMobile = useIsMobile();

  useBlurOnEscape();
  return (
    <Stack minWidth="100%" width="100%" height="100%" p={1}>
      <Box flexGrow={1} minHeight={0} height="100%">
        <Paper
          id="main-layout-card"
          variant="outlined"
          // eslint-disable-next-line no-restricted-syntax
          sx={{
            height: '100%',
            overflow: 'auto',
            position: 'relative',
            borderRadius: isMobile ? 0 : undefined,
            border: isMobile ? 'none' : undefined,
          }}
        >
          {children}
        </Paper>
      </Box>
    </Stack>
  );
}

export default memo(PublicPageLayout);
