import { ChatState, ChatAction } from './types';

export const chatReducer = (state: ChatState, action: ChatAction): ChatState => {
  switch (action.type) {
    case 'TOGGLE_DIALOG':
      return { ...state, isOpen: !state.isOpen, isFullScreen: false };
    case 'OPEN_DIALOG':
      return { ...state, isOpen: true };
    case 'CLOSE_DIALOG':
      return { ...state, isOpen: false };
    case 'TOGGLE_FULLSCREEN':
      return { ...state, isFullScreen: !state.isFullScreen };
    case 'OPEN_FULLSCREEN':
      return { ...state, isFullScreen: true };
    case 'CLOSE_FULLSCREEN':
      return { ...state, isFullScreen: false };
    case 'SET_LOCATION_BEFORE_FULLSCREEN':
      return { ...state, locationBeforeFullscreen: action.payload };
    case 'SET_SELECTED_CHAT':
      return { ...state, threadId: action.payload };
    case 'SET_CURRENT_INPUT':
      return { ...state, currentInput: action.payload };
    case 'SET_THREADS':
      return { ...state, threads: action.payload };
    case 'ADD_THREAD':
      return { ...state, threads: [action.payload, ...state.threads] };
    case 'CLEAR_CURRENT_THREAD':
      return {
        ...state,
        threadId: null,
        messages: [],
        waitingForResponse: false,
        error: null,
        imageUrl: null,
      };
    case 'UPDATE_THREAD_SUMMARY':
      return {
        ...state,
        threads: state.threads.map((thread) =>
          thread.id === action.payload.id
            ? { ...thread, summary: thread.summary ? thread.summary : action.payload.summary }
            : thread
        ),
      };
    case 'SET_CHAT_LOADING':
      return { ...state, isLoading: action.payload };
    case 'SET_ATTACHMENT':
      return { ...state, attachment: action.payload };
    case 'SET_SELECTED_RESOURCES':
      return { ...state, selectedResources: action.payload };
    case 'DELETE_THREAD':
      return { ...state, threads: state.threads.filter((thread) => thread.id !== action.payload) };
    case 'SET_WAITING_RESPONSE':
      return { ...state, waitingForResponse: action.payload };
    case 'SEND_CHAT_MESSAGE':
      return {
        ...state,
        messages: [...state.messages, action.payload],
        lastUserMessage: action.payload,
      };
    case 'COMPLETE_STREAM':
      return {
        ...state,
        messages: action.payload
          ? [
              ...state.messages,
              {
                role: 'assistant',
                content: [{ type: 'text', text: action.payload }],
                createdAt: null,
              },
            ]
          : state.messages,
      };
    case 'SET_IMAGE_URL':
      return { ...state, imageUrl: action.payload };
    case 'WEBSOCKET_ERROR':
      return { ...state, error: action.payload };
    case 'SET_INITIAL_MESSAGES':
      return {
        ...state,
        messages: action.payload,
      };
    case 'SET_NEEDS_UPDATE':
      return { ...state, needsUpdate: action.payload };
    default:
      return state;
  }
};
