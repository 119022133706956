import { Avatar, ListItemAvatar, Stack, SvgIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  GeneralAccessType,
  ResourceType,
  useGeneralAccessQuery,
} from 'src/store/api/shareResourceApi';
import {
  WorkbaseBusinessPlusIcon,
  WorkbaseLockIcon,
  WorkbaseTwoEmployeesIcon,
} from 'src/assets/icons/workbaseIcons';
import { ElementType } from 'react';
import { ShareLevel } from 'src/lib/types/share-level';
import AccessControlItem from './access-list/access-items/AccessControlItem';
import PermissionSelect from './access-list/PermissionSelect';
import RequiredSwitch from './access-list/RequiredSwitch';
import useIsCustomerPortal from 'src/hooks/useIsCustomerPortal';
import GeneralAccessSelect from './access-list/GeneralAccessSelect';

interface Props {
  resourceId: string;
  resourceType: ResourceType;
}

export default function GeneralAccess({ resourceId, resourceType }: Props) {
  const { t } = useTranslation();
  const { data } = useGeneralAccessQuery({ resourceId, resourceType });
  const isTrainingResourceType = resourceType === 'training';
  const isCustomerPortal = useIsCustomerPortal();
  const iconPropsDict: Record<GeneralAccessType, ElementType> = {
    restricted: WorkbaseLockIcon,
    everyone: WorkbaseBusinessPlusIcon,
    public: WorkbaseTwoEmployeesIcon,
  };
  const accessType = data?.generalAccess.type || 'restricted';
  const isEveryone = accessType === 'everyone';
  const everyoneDescription =
    data?.generalAccess.shareLevel === ShareLevel.edit
      ? t('permissionsShare.everyoneCanEditDescription')
      : t('permissionsShare.everyoneCanViewDescription');

  const descriptionDict: Record<GeneralAccessType, string> = {
    restricted: isCustomerPortal
      ? t('permissionsShare.restrictedCustomerPortalDescription')
      : t('permissionsShare.restrictedDescription'),
    everyone: everyoneDescription,
    public: t('permissionsShare.publicDescription'),
  };

  return (
    <Stack>
      <AccessControlItem
        prefix={
          <ListItemAvatar>
            <Avatar size="large">
              <SvgIcon component={iconPropsDict[data?.generalAccess.type || 'everyone']} />
            </Avatar>
          </ListItemAvatar>
        }
        primaryText={
          <GeneralAccessSelect
            resourceId={resourceId}
            resourceType={resourceType}
            generalAccessType={accessType}
          />
        }
        secondaryText={descriptionDict[accessType]}
        suffix={
          isEveryone && (
            <Stack direction="row" gap={1}>
              {data && (
                <>
                  {!isCustomerPortal && (
                    <PermissionSelect
                      resourceId={resourceId}
                      resourceType={resourceType}
                      shareLevel={data.generalAccess.shareLevel}
                      isGeneralAccess
                      isEveryone={isEveryone}
                    />
                  )}{' '}
                  {isTrainingResourceType && (
                    <RequiredSwitch
                      resourceId={resourceId}
                      resourceType={resourceType}
                      isGeneralAccess
                      isRequired={data.generalAccess.required}
                    />
                  )}
                </>
              )}
            </Stack>
          )
        }
      />
    </Stack>
  );
}
