import debounce from 'lodash.debounce';
import { useEffect, useMemo, useRef, useState } from 'react';

const DEBOUNCE_TIME = 500;

const useDebouncedValue = (
  val: string | undefined,
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
) => {
  const [value, setValue] = useState(val || '');

  const isTyping = useRef(false);

  const debouncedOnChange = useMemo(() => debounce(onChange, DEBOUNCE_TIME), [onChange]);

  // Clean up the debounced function on unmount
  useEffect(() => {
    return () => {
      debouncedOnChange.cancel();
    };
  }, [debouncedOnChange]);

  // Update local value when val changes from the backend,
  // but only if the user is not typing
  useEffect(() => {
    if (!isTyping.current && val !== value) {
      setValue(val || '');
    }
  }, [val, value]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (
    event
  ) => {
    isTyping.current = true;

    setValue(event.target.value);

    debouncedOnChange(event);

    // Reset typing status after debounce time
    setTimeout(() => {
      isTyping.current = false;
    }, DEBOUNCE_TIME);
  };

  return { handleChange, value };
};

export default useDebouncedValue;
