import { Components, Stack, Skeleton, SvgIcon, Theme, Box } from '@mui/material';
import { WorkbaseChevronDownIcon } from 'src/assets/icons/workbaseIcons';
import Empty from 'src/ui-components/custom/empty/Empty';

const MuiDataGrid: Components<Theme>['MuiDataGrid'] = {
  defaultProps: {
    slots: {
      noRowsOverlay: () => (
        <Box py={2}>
          <Empty disablePadding />
        </Box>
      ),
      noResultsOverlay: () => (
        <Box py={2}>
          <Empty disablePadding />
        </Box>
      ),
      loadingOverlay: () => (
        <Stack direction="column" gap={1} pt={1} px={2}>
          <Skeleton animation="wave" variant="text" height={32} width="100%" />
          <Skeleton animation="wave" variant="text" height={32} width="100%" />
        </Stack>
      ),
      columnSortedAscendingIcon: () => (
        <SvgIcon
          fontSize="tiny"
          component={WorkbaseChevronDownIcon}
          sx={{
            transform: 'rotate(180deg)',
          }}
        />
      ),
      columnSortedDescendingIcon: () => (
        <SvgIcon fontSize="tiny" component={WorkbaseChevronDownIcon} />
      ),
    },
  },
  styleOverrides: {
    root: {
      '--DataGrid-containerBackground': 'transparent',
      border: 'none',
      '& .MuiDataGrid-cell:focus': {
        outline: 'none',
      },
      '& .MuiDataGrid-columnHeader:focus': {
        outline: 'none',
      },
    },
    columnSeparator: {
      display: 'none',
    },
  },
};

export default MuiDataGrid;
