import { createApi } from '@reduxjs/toolkit/query/react';
import { DefaultImage } from 'src/ui-components/branded-default-image/BrandedDefaultImage';
import { baseQuery } from '../api';
import { trainingsApi } from './trainingsApi';
import invalidateTrainings from './invalidateTrainings';

export type TrainingModuleStatus = 'draft' | 'published';

export interface TrainingModule {
  id: string;
  name: string;
  imageUrl: string | null;
  trainingId: string;
  order: number;
  defaultImage: DefaultImage;
  position: string;
  sectionsCount: number;
  lessonsCount: number;
  progress: number;
  estimatedTimeToComplete?: number;
}

export interface TrainingModuleWithStatus extends TrainingModule {
  status: TrainingModuleStatus;
}

export const trainingModulesApi = createApi({
  reducerPath: 'trainingModulesApi',
  baseQuery,
  tagTypes: ['AllTrainingModules', 'TrainingModule', 'TrainingModulesByUserAndTrainingId'],
  endpoints: (builder) => ({
    // queries
    allTrainingModules: builder.query<TrainingModuleWithStatus[], void>({
      query: () => ({
        url: `/company-users/me/training-modules`,
      }),
      providesTags: ['AllTrainingModules'],
    }),
    trainingModule: builder.query<TrainingModuleWithStatus, string>({
      query: (id) => ({
        url: `/company-users/me/training-modules/${id}`,
      }),
      providesTags: ['TrainingModule'],
    }),
    trainingModulesByUserAndTrainingId: builder.query<
      TrainingModuleWithStatus[],
      { userId?: string; trainingId: string }
    >({
      query: ({ userId, trainingId }) => ({
        url: `/company-users/${userId ? userId : 'me'}/trainings/${trainingId}/modules`,
      }),
      providesTags: (_, __, { trainingId }) => [
        { type: 'TrainingModulesByUserAndTrainingId', id: trainingId },
      ],
    }),
    // mutations
    postTrainingModule: builder.mutation<
      TrainingModuleWithStatus,
      {
        name?: string;
        status?: TrainingModuleStatus;
        imageId?: string;
        trainingId: string;
        sectionsCount?: number;
      }
    >({
      query: (data) => ({
        url: `/training-modules`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (_, __, payload) => [
        'AllTrainingModules',
        { type: 'TrainingModulesByUserAndTrainingId', id: payload.trainingId },
      ],
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((response) => {
          dispatch(
            trainingModulesApi.util.updateQueryData(
              'trainingModulesByUserAndTrainingId',
              { trainingId: response.data.trainingId },
              (draft) => [...draft, response.data]
            )
          );
        });
      },
    }),
    patchTrainingModule: builder.mutation<
      void,
      {
        module: {
          id: string;
          name?: string;
          status?: TrainingModuleStatus;
          imageId?: string | null;
          trainingId: string;
        };
        imageSrc?: string;
      }
    >({
      query: ({ module }) => ({
        url: `/training-modules/${module.id}`,
        method: 'PATCH',
        body: { ...module, id: undefined },
      }),
      invalidatesTags: (_, __, { module }) => [
        'AllTrainingModules',
        'TrainingModule',
        { type: 'TrainingModulesByUserAndTrainingId', id: module.trainingId },
      ],
      onQueryStarted: ({ module, imageSrc }, { dispatch, queryFulfilled }) => {
        const disp = dispatch(
          trainingModulesApi.util.updateQueryData(
            'trainingModulesByUserAndTrainingId',
            { trainingId: module.trainingId },
            (draft) =>
              draft.map((mod) =>
                mod.id === module.id
                  ? { ...mod, ...module, imageUrl: imageSrc || mod.imageUrl }
                  : mod
              )
          )
        );
        queryFulfilled
          .then(() => {
            dispatch(trainingsApi.util.invalidateTags(['TrainingsByUserId']));
          })
          .catch(disp.undo);
      },
    }),
    deleteTrainingModule: builder.mutation<
      void,
      Pick<TrainingModuleWithStatus, 'id' | 'trainingId'>
    >({
      query: ({ id }) => ({
        url: `/training-modules/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['AllTrainingModules', 'TrainingModule'],
      onQueryStarted: ({ id, trainingId }, { dispatch, queryFulfilled }) => {
        const disp = dispatch(
          trainingModulesApi.util.updateQueryData(
            'trainingModulesByUserAndTrainingId',
            { trainingId },
            (draft) => draft.filter((module) => module.id !== id)
          )
        );
        queryFulfilled
          .then(() => {
            invalidateTrainings(dispatch);
          })
          .catch(disp.undo);
      },
    }),
    duplicateTrainingModule: builder.mutation<
      TrainingModuleWithStatus,
      { trainingId: string; moduleId: string }
    >({
      query: ({ trainingId, moduleId }) => ({
        url: `/training-modules/${moduleId}/duplicate`,
        method: 'POST',
        body: {
          trainingId,
        },
      }),
      invalidatesTags: () => ['AllTrainingModules'],
      onQueryStarted: ({ moduleId, trainingId }, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((response) => {
          dispatch(
            trainingModulesApi.util.updateQueryData(
              'trainingModulesByUserAndTrainingId',
              { trainingId },
              (draft) => {
                const moduleIndex = draft.findIndex((module) => module.id === moduleId);
                if (moduleIndex >= 0) {
                  draft.splice(moduleIndex + 1, 0, response.data);
                }
                return draft;
              }
            )
          );
          invalidateTrainings(dispatch);
        });
      },
    }),
    moveTrainingModuleToNewPosition: builder.mutation<
      void,
      { order: number; moduleId: string; trainingId: string; position: string }
    >({
      query: ({ position, moduleId }) => ({
        url: `/training-modules/${moduleId}`,
        method: 'PATCH',
        body: {
          position,
        },
      }),
      invalidatesTags: ['AllTrainingModules', 'TrainingModule'],
      onQueryStarted: async (
        { moduleId, order, position, trainingId },
        { dispatch, queryFulfilled }
      ) => {
        const undoAction = dispatch(
          trainingModulesApi.util.updateQueryData(
            'trainingModulesByUserAndTrainingId',
            { trainingId },
            (draft) => {
              const moduleIdx = draft.findIndex((module) => module.id === moduleId);
              if (moduleIdx === -1) return;
              const [moduleToMove] = draft.splice(moduleIdx, 1);
              moduleToMove.position = position;
              draft.splice(order - 1, 0, moduleToMove);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          queryFulfilled.catch(undoAction.undo);
        }
      },
    }),
  }),
});

export const {
  useAllTrainingModulesQuery,
  useTrainingModuleQuery,
  useDeleteTrainingModuleMutation,
  useDuplicateTrainingModuleMutation,
  useMoveTrainingModuleToNewPositionMutation,
  usePatchTrainingModuleMutation,
  usePostTrainingModuleMutation,
  useTrainingModulesByUserAndTrainingIdQuery,
} = trainingModulesApi;
