import { ReactNode } from 'react';
import { Route, Routes, Navigate, Outlet } from 'react-router-dom';
import Dashboard from 'src/views/dashboard';
import ModuleOverviewView from 'src/views/module-overview';
import WhiteboardView from 'src/views/whiteboard/WhiteboardView';
import LessonView from 'src/views/lesson';
import DocumentsView from 'src/views/documents/DocumentsView';
import EmbeddedAppView from 'src/views/embedded-app';
import FullScreenChat from 'src/views/ai-chat/FullScreenChat';
import TrainingsView from 'src/views/trainings';
import TrainingCourseView from 'src/views/training-course/TrainingCourseView';
import { ChatProvider } from 'src/features/ai-chat/helpers/chatContext';
import Wiki from 'src/views/wiki';
import ErrorLayout from 'src/layouts/ErrorLayout';
import { UploadProvider } from 'src/core/upload/UploadContext';
import UploadProgressWindowWithContext from 'src/core/upload/UploadProgressWindowWithContext';
import BlockerComponent from 'src/features/editor/view/blocker/BlockerComponent';
import MainLayout from 'src/layouts/main/MainLayout';
import { useAppSelector } from 'src/store';
import CustomPageView from 'src/views/custom-page';
import { StreamProvider } from 'src/features/ai-chat/helpers/streamContext';

interface RouteConfig {
  path: string;
  component: ReactNode;
}

const routes: RouteConfig[] = [
  {
    path: '/',
    component: <Dashboard />,
  },
  {
    path: '/wiki/:id/*',
    component: <Wiki />,
  },
  {
    path: '/wiki',
    component: <Wiki />,
  },
  {
    path: '/documents',
    component: <DocumentsView />,
  },
  {
    path: 'ai/:chatId',
    component: <FullScreenChat />,
  },
  {
    path: '/ai',
    component: <FullScreenChat />,
  },
  {
    path: 'page/:id/*',
    component: <CustomPageView />,
  },
  {
    path: 'app/:id/*',
    component: <EmbeddedAppView />,
  },
  {
    path: 'whiteboard/:id/*',
    component: <WhiteboardView />,
  },
  {
    path: '/trainings/:trainingId/:moduleId/:sectionId/:lessonId/*',
    component: <LessonView />,
  },
  {
    path: 'trainings/:trainingId',
    component: <TrainingCourseView />,
  },
  {
    path: 'trainings/:trainingId/edit',
    component: <TrainingCourseView />,
  },
  {
    path: '/trainings/:trainingId/:moduleId/*',
    component: <ModuleOverviewView />,
  },
  {
    path: 'trainings/',
    component: <TrainingsView />,
  },
  {
    path: 'trainings/edit',
    component: <TrainingsView />,
  },
];

const wrappedLayout = (
  <UploadProvider>
    <BlockerComponent />
    <StreamProvider>
      <ChatProvider>
        <MainLayout />
      </ChatProvider>
    </StreamProvider>
    <UploadProgressWindowWithContext />
  </UploadProvider>
);

function AuthorizedRoutes() {
  const { refreshToken, token } = useAppSelector((state) => state.auth);

  return (
    <ErrorLayout>
      <Routes>
        <Route element={!refreshToken && !token ? <Outlet /> : wrappedLayout}>
          {routes.map(({ path, component }) => (
            <Route key={path} path={path} element={component} />
          ))}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    </ErrorLayout>
  );
}

export default AuthorizedRoutes;
