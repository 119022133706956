import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { Button, Menu, MenuItem, Stack, SvgIcon } from '@mui/material';
import useThemeLogos from 'src/hooks/useThemeLogos';
import { WorkbaseGlobalIcon } from 'src/assets/icons/workbaseIcons';
import { allLangs } from 'src/locales/config-lang';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';

export type UnauthorizedLayoutProps = {
  children: React.ReactNode;
};

function LanguageSelect() {
  const { i18n } = useTranslation();

  const popupState = usePopupState({ variant: 'popover', popupId: 'language-menu' });

  const handleLanguageChange = (value: string) => {
    i18n.changeLanguage(value);
    popupState.close();
  };

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        startIcon={<SvgIcon component={WorkbaseGlobalIcon} />}
        {...bindTrigger(popupState)}
      >
        {i18n.language.toUpperCase()}
      </Button>
      <Menu {...bindMenu(popupState)}>
        {allLangs.map(({ label, value }) => (
          <MenuItem
            key={value}
            selected={i18n.language === value}
            onClick={() => handleLanguageChange(value)}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

function UnauthorizedLayout({ children }: UnauthorizedLayoutProps) {
  const { t } = useTranslation();
  const { favicon } = useThemeLogos();
  const currentYear = new Date().getFullYear();
  return (
    <Box p={2} height="100vh" display="flex" justifyContent="space-between" flexDirection="column">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box component="img" src={favicon.src} alt={favicon.alt} width={24} alignSelf="center" />
        <LanguageSelect />
      </Box>
      {children}
      <Stack alignItems="center" direction="row" alignSelf="center" gap={2}>
        <Button
          variant="text"
          color="inherit"
          size="small"
          href="https://www.workbase.com/"
          target="_blank"
        >
          Workbase © {currentYear}
        </Button>
        <Button
          variant="text"
          color="inherit"
          size="small"
          href="https://www.workbase.com/legal"
          target="_blank"
        >
          {t('unauthorizedPage.footer.legalLabel')}
        </Button>
        <Button
          variant="text"
          color="inherit"
          size="small"
          href="https://www.workbase.com/help"
          target="_blank"
        >
          {t('unauthorizedPage.footer.supportLabel')}
        </Button>
      </Stack>
    </Box>
  );
}

export default UnauthorizedLayout;
