import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/store/api/api';

export interface PermissionRoleCount {
  id: string;
  permissionsCount: number;
}

export interface Department {
  id: string;
  name: string;
}

export interface Role {
  department: Department;
  id: string;
  name: string;
}

export const rolesApi = createApi({
  reducerPath: 'roles',
  tagTypes: ['Roles', 'PermissionRoleCount'],
  baseQuery,
  endpoints: (builder) => ({
    roles: builder.query<Role[], void>({
      query: () => '/roles',
      providesTags: ['Roles'],
    }),
  }),
});

export const { useRolesQuery } = rolesApi;
