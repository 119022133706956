import { Divider, Stack } from '@mui/material';
import Links from 'src/core/links';
import { useAppSelector } from 'src/store';
import { Link } from 'src/store/api/linksApi';
import RoleSelector from './RoleSelector';
import { Role } from '../../store/api/dashboardApi';
import CustomerGroupSelector from './CustomerGroupSelector';
import useIsCustomerPortal from 'src/hooks/useIsCustomerPortal';
import { CustomerGroup } from 'src/features/company-settings/customer-groups/store/types';

interface Props {
  isEditing: boolean;
  links?: Link[];
  roles: Role[];
  customerGroups: CustomerGroup[];
}

export default function SelectorWithLinks({ isEditing, links = [], roles, customerGroups }: Props) {
  const isCustomerPortal = useIsCustomerPortal();
  const shouldShowDivider = (isCustomerPortal ? customerGroups : roles).length > 1;

  const linkResourceId = useAppSelector((state) =>
    !isCustomerPortal ? state.dashboard.selectedRole : state.dashboard.selectedCustomerGroup
  );

  return (
    <Stack gap={2} width="100%">
      {!isCustomerPortal ? <RoleSelector /> : <CustomerGroupSelector />}
      {((links?.length && !isEditing) || isEditing) && shouldShowDivider && <Divider flexItem />}
      {linkResourceId && (
        <Links
          resourceType={isCustomerPortal ? 'customerGroup' : 'role'}
          resourceId={linkResourceId}
          readOnly={!isEditing}
        />
      )}
    </Stack>
  );
}
