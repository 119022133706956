import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import DashboardHeader from 'src/views/dashboard/dashboard-header';
import { useAppSelector } from 'src/store';
import { useCurrentUserQuery } from 'src/store/api/currentUserApi';
import useHasAnyPermission from 'src/hooks/useHasAnyPermission';
import { useDashboardQuery } from 'src/store/api/dashboardApi';
import {
  setSelectedCustomerGroup,
  setSelectedEmployeePortalNewsfeedItemIdx,
  setSelectedRole,
} from '../../store/client/dashboardSlice';
import { PermissionCodes } from 'src/store/api/userPermissionsApi';
import useIsCustomerPortal from 'src/hooks/useIsCustomerPortal';
import useFilteredCustomerGroups from './useFilteredCustomerGroups';
import { Stack } from '@mui/material';
import EditorVerifyE2EProvider from 'src/features/editor2/providers/EditorVerifyE2EProvider';
import Editor from 'src/features/editor2/Editor';

export default function DashboardView() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { data } = useCurrentUserQuery();
  const isCustomerPortal = useIsCustomerPortal();

  const selectedSelectorItem = useAppSelector((state) =>
    !isCustomerPortal ? state.dashboard.selectedRole : state.dashboard.selectedCustomerGroup
  );

  const { data: dashboardData, isSuccess } = useDashboardQuery(undefined, {
    refetchOnMountOrArgChange: true,
    skip: isCustomerPortal,
  });

  const { filteredCustomerGroups, customerGroupsFetchSuccess } = useFilteredCustomerGroups();

  const hasAnyPermission = useHasAnyPermission();
  const hasAdminAccessToDashboardsPages = hasAnyPermission([
    PermissionCodes.ADMIN_ACCESS_DASHBOARD_AND_PAGES,
  ]);
  const hasEditingPermissions = data?.isOwner || hasAdminAccessToDashboardsPages;

  useEffect(() => {
    dispatch(setSelectedEmployeePortalNewsfeedItemIdx(0));
  }, [dispatch]);

  useEffect(() => {
    if (dashboardData?.roles && dashboardData.roles.length > 0 && !isCustomerPortal) {
      const roleExists = dashboardData.roles.some((role) => role.id === selectedSelectorItem);

      if (selectedSelectorItem === undefined || !roleExists) {
        dispatch(setSelectedRole(dashboardData.roles[0].id));
      }
    } else if (filteredCustomerGroups && filteredCustomerGroups.length > 0 && isCustomerPortal) {
      const groupExists = filteredCustomerGroups.some((group) => group.id === selectedSelectorItem);

      if (selectedSelectorItem === undefined || !groupExists) {
        dispatch(setSelectedCustomerGroup(filteredCustomerGroups[0].id));
      }
    }
  }, [
    selectedSelectorItem,
    dashboardData?.roles,
    dispatch,
    isCustomerPortal,
    filteredCustomerGroups,
  ]);

  if (!selectedSelectorItem || (!isCustomerPortal ? !isSuccess : !customerGroupsFetchSuccess))
    return null;

  if (!['/', '/edit'].includes(location.pathname)) {
    return <Navigate to="/" replace />;
  }
  return (
    <Stack height="100%">
      <DashboardHeader readOnly={!hasEditingPermissions} />
      <EditorVerifyE2EProvider
        name={`${!isCustomerPortal ? 'role' : 'customerGroup'}.${selectedSelectorItem}`}
        readOnly={!hasEditingPermissions}
      >
        <Editor />
      </EditorVerifyE2EProvider>
    </Stack>
  );
}
