import { Autocomplete, ListItemIcon, ListItemText, MenuItem, SvgIcon } from '@mui/material';
import { useState, useMemo, useCallback } from 'react';
import { ResourceType } from 'src/store/api/searchResourceApi';
import {
  usePostShareResourceMutation,
  useSharedResourceInfoQuery,
} from 'src/store/api/shareResourceApi';
import Empty from 'src/ui-components/custom/empty/Empty';
import { useTranslation } from 'react-i18next';
import { SearchTextField } from 'src/ui-components/custom/textfield/search';
import { TabValue } from '../access-list/TrainingAccessControlList';
import { useCustomerGroupsQuery } from 'src/store/api/customerGroupsApi';
import { WorkbaseRoleIcon } from 'src/assets/icons/workbaseIcons';
import normalizeString from 'src/lib/utils/normalizeString';

interface Option {
  id: string;
  name: string;
}

interface Props {
  resourceId: string;
  resourceType: ResourceType;
  activeTabButton?: TabValue;
}

function SearchCustomerGroups({ resourceId, resourceType, activeTabButton }: Props) {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [inputVal, setInputVal] = useState('');
  const [shareResource] = usePostShareResourceMutation();
  const { data: customerGroups } = useCustomerGroupsQuery();
  const { data: resourceInfo } = useSharedResourceInfoQuery({ resourceId, resourceType });

  const handleSearch = useCallback(
    (val: string) => {
      setInputVal(val);
    },
    [setInputVal]
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setInputVal('');
    setOpen(false);
  };

  const handleSearchResultItemClick = (id: string) => {
    shareResource({
      resourceId,
      resourceType,
      customerGroupId: id,
      required: activeTabButton === 'required',
    });
    handleClose();
  };

  const options: Option[] = useMemo(() => {
    if (!customerGroups) return [];
    return [
      ...customerGroups
        .filter(
          ({ id, name }) =>
            !resourceInfo?.find((resource) => resource.customerGroup?.id === id) &&
            normalizeString(name).includes(normalizeString(inputVal))
        )
        .map((group) => ({
          id: group.id,
          name: group.name,
        })),
    ];
  }, [customerGroups, resourceInfo, inputVal]);

  return (
    <Autocomplete
      data-cy="share-modal-search"
      onOpen={handleOpen}
      open={isOpen && !!inputVal}
      onClose={handleClose}
      options={options}
      disableClearable
      forcePopupIcon={false}
      selectOnFocus={false}
      inputValue={inputVal}
      clearOnBlur={false}
      filterOptions={(x) => x}
      noOptionsText={<Empty description={t('notFound')} />}
      getOptionLabel={(option: Option | string) =>
        typeof option === 'string' ? option : option.name
      }
      onChange={(_, value) => {
        if (value && typeof value !== 'string') {
          handleSearchResultItemClick(value.id);
        }
      }}
      renderInput={({ InputProps, ...restOfProps }) => (
        <SearchTextField
          {...restOfProps}
          slotProps={{
            input: { ref: InputProps.ref, className: InputProps.className },
          }}
          value={inputVal}
          placeholder={t('add')}
          data-cy="share-modal-search-textfield"
          onChange={(e) => handleSearch(e.target.value)}
        />
      )}
      renderOption={(props, option) => {
        return (
          <>
            <MenuItem {...props}>
              <ListItemIcon>
                <SvgIcon component={WorkbaseRoleIcon} />
              </ListItemIcon>
              <ListItemText
                primary={option.name}
                primaryTypographyProps={{ noWrap: true }}
                secondaryTypographyProps={{ noWrap: true }}
              />
            </MenuItem>
          </>
        );
      }}
    />
  );
}

export default SearchCustomerGroups;
