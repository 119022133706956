import { ThunkDispatch } from '@reduxjs/toolkit';
import { trainingModulesApi } from './trainingModulesApi';
import { trainingsApi } from './trainingsApi';

export default function invalidateTrainings(dispatch: ThunkDispatch<any, any, any>) {
  dispatch(trainingModulesApi.util.invalidateTags(['TrainingModule']));
  dispatch(trainingModulesApi.util.invalidateTags(['TrainingModulesByUserAndTrainingId']));
  dispatch(trainingModulesApi.util.invalidateTags(['AllTrainingModules']));
  dispatch(trainingsApi.util.invalidateTags(['Training']));
  dispatch(trainingsApi.util.invalidateTags(['TrainingsByUserId']));
}
