import { useFetchWikiQuery, usePatchWikiCardMutation } from 'src/store/api/wikiApi';
import { useCallback } from 'react';
import LinksTitleHeader from 'src/core/links-title-header';
import { useTranslation } from 'react-i18next';

interface Props {
  id: string;
  readOnly: boolean;
}

export default function WikiCardHeader({ id, readOnly }: Props) {
  const { t } = useTranslation();
  const [patchWikiCard] = usePatchWikiCardMutation();
  const { data } = useFetchWikiQuery(id);
  const handleNameChange = useCallback(
    (newName: string) => {
      patchWikiCard({ name: newName, id });
    },
    [patchWikiCard, id]
  );
  const handleIconChange = useCallback(
    (newIcon: string) => {
      patchWikiCard({ icon: newIcon, id });
    },
    [patchWikiCard, id]
  );

  return (
    <LinksTitleHeader
      placeholder={t('wikiPage.unnamedWikiCard')}
      readOnly={readOnly}
      name={data?.name}
      icon={data?.icon || ''}
      onIconChange={handleIconChange}
      onNameChange={handleNameChange}
      resourceId={id}
      resourceType="wikiCard"
      imageUrl={data?.imageUrl || ''}
      onImageChange={(imageId: string | null) => patchWikiCard({ id, imageId })}
    />
  );
}
