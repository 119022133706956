import { Box, ListItemIcon, Stack, ToggleButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SvgIcon from '@mui/material/SvgIcon';
import { WorkbaseAIIcon } from 'src/assets/icons/workbaseIcons';
import { useCallback, useEffect } from 'react';
import { useChatDispatch, useChatState } from 'src/features/ai-chat/helpers/chatContext';
import { useFetchSettingsQuery } from 'src/store/api/aiChatApi';
import { useNavigate } from 'react-router-dom';
import useIsMobile from 'src/hooks/useIsMobile';
import useHasAnyPermission from 'src/hooks/useHasAnyPermission';
import { PermissionCodes } from 'src/store/api/userPermissionsApi';
import isUserTyping from 'src/lib/utils/isUserTyping';

export default function AiButton({ labelVisible }: { labelVisible: boolean }) {
  const { t } = useTranslation();
  const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
  const navigate = useNavigate();
  const dispatch = useChatDispatch();
  const { isFullScreen, threadId, isOpen, locationBeforeFullscreen } = useChatState((state) => ({
    isFullScreen: state.isFullScreen,
    threadId: state.threadId,
    isOpen: state.isOpen,
    locationBeforeFullscreen: state.locationBeforeFullscreen,
  }));
  const { data: chatSettings } = useFetchSettingsQuery();
  const isMobile = useIsMobile();
  const hasAnyPermission = useHasAnyPermission();
  const canAccessAiAssistant = hasAnyPermission([PermissionCodes.ACCESS_AI_ASSISTANT]);

  const handleOpenChat = useCallback(() => {
    if (isMobile) {
      navigate(`/ai/${threadId}`);
      return undefined;
    }
    if (isFullScreen) {
      dispatch({ type: 'CLOSE_FULLSCREEN' });
      dispatch({ type: 'CLOSE_DIALOG' });
      navigate(locationBeforeFullscreen ?? '/');
    } else {
      dispatch({ type: 'TOGGLE_DIALOG' });
    }
    return undefined;
  }, [dispatch, isFullScreen, isMobile, navigate, locationBeforeFullscreen, threadId]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        (isMac && event.metaKey && event.key === 'k') ||
        (!isMac && event.ctrlKey && event.key === 'k')
      ) {
        event.preventDefault();
        handleOpenChat();
      } else if (event.key === 'Escape' && !isFullScreen && !isUserTyping()) {
        event.preventDefault();
        dispatch({ type: 'CLOSE_DIALOG' });
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isFullScreen, dispatch, handleOpenChat, isMac]);

  if (!canAccessAiAssistant) return null;

  return (
    <ToggleButton
      selected={isOpen || window.location.pathname.startsWith('/ai')}
      value="ai"
      size="small"
      color="primary"
      onClick={handleOpenChat}
      data-cy="app-sidebar-ai-button"
    >
      <Stack width="100%" direction="row" pl={5 / 8} overflow="hidden" maxWidth="100%">
        <ListItemIcon color="inherit">
          <SvgIcon component={WorkbaseAIIcon} fontSize="small" htmlColor="inherit" />
        </ListItemIcon>
        {!isMobile && (
          <Stack direction="row" justifyContent="space-between" flexGrow={1} overflow="hidden">
            <Typography
              variant="body2"
              fontWeight="medium"
              noWrap
              sx={{
                visibility: labelVisible ? 'visible' : 'hidden',
              }}
            >
              {chatSettings?.name || t('sidebar.assistant')}
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              flexShrink={0}
              gap={0.5}
              visibility={labelVisible ? 'visible' : 'hidden'}
            >
              <Box bgcolor="action.selected" px={0.75} borderRadius={0.5}>
                <Typography variant="caption" color="text.secondary">
                  {isMac ? '⌘' : 'Ctrl'}
                </Typography>
              </Box>
              <Box bgcolor="action.selected" px={0.75} borderRadius={0.5}>
                <Typography variant="caption" color="text.secondary">
                  K
                </Typography>
              </Box>
            </Stack>
          </Stack>
        )}
      </Stack>
    </ToggleButton>
  );
}
