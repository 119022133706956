import useIsMobile from 'src/hooks/useIsMobile';
import {
  WorkbaseContentIcon,
  WorkbaseEmployeeIcon,
  WorkbasePageIcon,
  WorkbaseShareIcon,
} from 'src/assets/icons/workbaseIcons';
import { ShareLevel } from 'src/lib/types/share-level';
import { SvgIcon } from '@mui/material';
import { useDocumentsQuery, useGetFilesQuery } from 'src/store/api/driveApi';
import { useTranslation } from 'react-i18next';
import { DocumentsTabData } from './types';
import { useEffect, useState } from 'react';
import { GridSortModel } from '@mui/x-data-grid';
import { useSearchParams } from 'react-router-dom';

export default function useTabsData() {
  const isMobile = useIsMobile();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const [filesSortModel, setFilesSortModel] = useState<GridSortModel>([
    {
      field: 'updatedAt',
      sort: 'desc',
    },
  ]);
  const [documentsSortModel, setDocumentsSortModel] = useState<GridSortModel>([
    {
      field: 'updatedAt',
      sort: 'desc',
    },
  ]);
  const [documentsPage, setDocumentsPage] = useState(1);
  const [documentsRowCount, setDocumentsRowCount] = useState(0);
  const [filesPage, setFilesPage] = useState(1);
  const [filesRowCount, setFilesRowCount] = useState(0);
  const { data: filesData, isLoading: isFilesDataLoading } = useGetFilesQuery(
    { page: filesPage, pageSize: 50, sortModel: filesSortModel },
    {
      refetchOnMountOrArgChange: true,
      skip: searchParams.get('tab') !== 'files',
    }
  );
  const { data: documentsData, isLoading: isDocumentsDataLoading } = useDocumentsQuery(
    { page: documentsPage, pageSize: 50, sortModel: documentsSortModel },
    {
      refetchOnMountOrArgChange: true,
      skip: searchParams.get('tab') === 'files',
    }
  );

  useEffect(() => {
    if (documentsData) {
      setDocumentsRowCount(documentsData.totalCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentsData?.totalCount, isDocumentsDataLoading]);

  useEffect(() => {
    if (filesData) {
      setFilesRowCount(filesData.totalCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesData?.totalCount, isFilesDataLoading]);

  const docs = documentsData?.data || [];
  const files = filesData?.data || [];
  const tabsData: DocumentsTabData[] = [
    {
      key: 'documents',
      label: isMobile ? (
        <SvgIcon fontSize="small">
          <WorkbasePageIcon />
        </SvgIcon>
      ) : (
        t('documentsPage.allDocumentsTab')
      ),
      rows: docs,
    },
    {
      key: 'createdByMe',
      label: isMobile ? (
        <SvgIcon fontSize="small">
          <WorkbaseEmployeeIcon />
        </SvgIcon>
      ) : (
        t('documentsPage.createdByMeTab')
      ),
      rows: docs.filter((row) => row.shareLevel === ShareLevel.manage),
    },
    {
      key: 'shared',
      label: isMobile ? (
        <SvgIcon fontSize="small">
          <WorkbaseShareIcon />
        </SvgIcon>
      ) : (
        t('documentsPage.sharedTab')
      ),
      rows: docs.filter((row) => row.shareLevel !== ShareLevel.manage),
    },
    {
      key: 'files',
      label: isMobile ? (
        <SvgIcon fontSize="small">
          <WorkbaseContentIcon />
        </SvgIcon>
      ) : (
        t('documentsPage.filesTab')
      ),
      rows: files,
    },
  ];

  return {
    tabsData,
    isFilesDataLoading,
    isDocumentsDataLoading,
    setDocumentsPage,
    setFilesPage,
    documentsRowCount,
    filesRowCount,
    setDocumentsSortModel,
    setFilesSortModel,
  };
}
