import generateJitteredKeyBetween from 'src/lib/utils/generateJitteredKeyBetween';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { WorkbaseAddSquareIcon } from 'src/assets/icons/workbaseIcons';
import {
  useCreateWikiCardMutation,
  useFetchWikiCardsNoPaginationQuery,
  useUpdateCardsOrderMutation,
} from 'src/store/api/wikiApi';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import Sidebar from 'src/ui-components/layout/sidebar/Sidebar';
import { PositionedSidebarItem } from 'src/ui-components/layout/sidebar/types';

interface WikiSidebarProps {
  data: PositionedSidebarItem[];
  hasAddPermission?: boolean;
  wikiId: string;
  isEditMode: boolean;
}

export default function WikiSidebar({
  data,
  hasAddPermission,
  wikiId,
  isEditMode,
}: WikiSidebarProps) {
  const [updateOrder] = useUpdateCardsOrderMutation();
  const [createWikiCard] = useCreateWikiCardMutation();
  const { refetch } = useFetchWikiCardsNoPaginationQuery();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleCreateCard = () => {
    createWikiCard().then((resp: any) => {
      if (!resp.error) {
        refetch().then(() => {
          navigate(`/wiki/${resp.data.id}/edit`, { replace: true });
          showNotification(t('notifications.addingSuccess'));
        });
      } else {
        showNotification(t('notifications.addingError'), 'error');
      }
    });
  };

  const handleUpdateOrder = ({ id, order }: { id: string; order: number }) => {
    const aboveItem = order > 0 ? data[order - 1] : null;
    const belowItem = order < data.length ? data[order] : null;

    const abovePosition = aboveItem?.position ?? null;
    const belowPosition = belowItem?.position ?? null;

    const newPosition = generateJitteredKeyBetween(abovePosition, belowPosition);

    updateOrder({ id, position: newPosition });
  };

  return (
    <Sidebar
      withDragAndDrop={hasAddPermission && isEditMode}
      reorderFunction={handleUpdateOrder}
      items={data}
      dataCy="wiki-sidebar"
      disableSearchBar={!data?.length}
      activeKey={wikiId as string}
      headerIcon={
        hasAddPermission
          ? {
              icon: WorkbaseAddSquareIcon,
              text: t('wikiPage.headerAddButton'),
              onClick: handleCreateCard,
            }
          : undefined
      }
    />
  );
}
