import { useTheme } from '@mui/material';
import { useEffect } from 'react';
import '@blocksuite/presets/themes/affine.css';

export default function useEditorCssVariables() {
  const theme = useTheme();

  const paperColor = theme.palette.background.paper;
  const dividerColor = theme.palette.divider;
  const primaryText = theme.palette.text.primary;
  const hoverColor = theme.palette.action.hover;
  const selectedColor = theme.palette.action.selected;
  const secondaryText = theme.palette.text.secondary;
  const primaryColor = theme.palette.primary.main;
  const backgroundColor = theme.palette.background.default;
  // eslint-disable-next-line max-statements
  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty('--affine-font-family', 'Base');
    root.style.setProperty('--affine-background-primary-color', paperColor);
    root.style.setProperty('--affine-background-overlay-panel-color', paperColor);
    root.style.setProperty('--affine-border-color', dividerColor);
    root.style.setProperty('--affine-icon-color', primaryText);
    root.style.setProperty('--affine-hover-color', hoverColor);
    root.style.setProperty('--affine-text-primary-color', primaryText);
    root.style.setProperty('--affine-editor-width', '1200px');
    root.style.setProperty('--affine-hover-color-filled', selectedColor);
    root.style.setProperty('--affine-black-30', secondaryText);
    root.style.setProperty('--affine-black-50', secondaryText);
    root.style.setProperty('--affine-black-60', secondaryText);
    root.style.setProperty('--affine-black-90', primaryText);
    root.style.setProperty('--affine-primary-color', primaryColor);
    root.style.setProperty('--affine-z-index-popover', '20');
    root.style.setProperty('--affine-processing-color', primaryColor);
    root.style.setProperty('--affine-editor-side-padding', '24px');
    root.style.setProperty('--affine-background-code-block', backgroundColor);
  }, [
    paperColor,
    dividerColor,
    primaryText,
    hoverColor,
    selectedColor,
    primaryColor,
    secondaryText,
    backgroundColor,
  ]);
}
