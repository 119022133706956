import { useTranslation } from 'react-i18next';
import SingleModule from 'src/features/editor/view/grid/widgets/trainings-item/modules/SingleModule';
import { TrainingModuleWithStatus } from 'src/store/api/trainings/trainingModulesApi';
import { TrainingModulesProgress } from 'src/store/api/trainings/trainingsApi';

interface Props {
  modules: TrainingModuleWithStatus[];
  progressData?: TrainingModulesProgress[];
}

export default function ReadOnlyModules({ modules }: Props) {
  const { t } = useTranslation();
  return modules
    .filter(({ status }) => status === 'published')
    .map((module) => (
      <SingleModule
        key={module.id}
        readOnly
        {...module}
        name={module.name || t('trainingDetailPage.unnamedModule')}
        lessonsCount={module.lessonsCount}
        estimatedTime={module.estimatedTimeToComplete}
        progress={module.progress}
      />
    ));
}
