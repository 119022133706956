import {
  Avatar,
  Box,
  Divider,
  Popover,
  Stack,
  SvgIcon,
  ToggleButton,
  Tooltip,
} from '@mui/material';
import { usePopupState, bindTrigger, bindPopover } from 'material-ui-popup-state/hooks';
import { useTranslation } from 'react-i18next';
import { WorkbaseAdjustSettings } from 'src/assets/icons/workbaseIcons';
import UserProfileMenu from 'src/features/user-profile-menu';
import { useUnsavedChanges } from 'src/lib/utils/UnsavedChangesContext';
import { useCurrentUserQuery } from 'src/store/api/currentUserApi';
import CustomerPortalSwitch from './CustomerPortalSwitch';
import useHasAnyPermission from 'src/hooks/useHasAnyPermission';

interface Props {
  avatarUrl?: string;
  isImpersonated?: boolean;
}

function UserProfile({ avatarUrl, isImpersonated }: Readonly<Props>) {
  const popupState = usePopupState({ variant: 'popover', popupId: 'profile-menu' });
  const { t } = useTranslation();
  const popoverProps = bindPopover(popupState);
  const { data } = useCurrentUserQuery();
  const { hasUnsavedChanges, setHasUnsavedChanges, confirmUnsavedChanges } = useUnsavedChanges();
  const hasAnyPermission = useHasAnyPermission();
  const customerPortalSwitchVisible = hasAnyPermission([
    'MANAGE_CUSTOMERS',
    'MANAGE_CUSTOMER_GROUPS',
    'MANAGE_CUSTOMER_PORTAL',
  ]);

  const onClose = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (hasUnsavedChanges) {
      confirmUnsavedChanges().then(() => {
        popupState.close();
        setHasUnsavedChanges(false);
      });
    } else {
      popupState.close();
    }
  };
  return (
    <>
      <Popover
        slotProps={{
          paper: {
            variant: 'outlined',
          },
        }}
        {...popoverProps}
        onClose={onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <UserProfileMenu onClose={onClose} />
      </Popover>
      <Box
        borderColor={isImpersonated ? 'error.main' : 'divider'}
        borderRadius={1}
        sx={{
          borderWidth: 1,
          borderStyle: 'solid',
          '& > *': {
            margin: '-1px !important',
          },
        }}
      >
        <Stack direction="row">
          {customerPortalSwitchVisible && (
            <>
              <CustomerPortalSwitch />
              <Divider flexItem orientation="vertical" />
            </>
          )}
          <ToggleButton
            selected={popoverProps.open || isImpersonated}
            color={isImpersonated ? 'error' : 'standard'}
            value="profile-menu"
            disableActiveShrink
            sx={
              customerPortalSwitchVisible
                ? { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }
                : undefined
            }
            {...bindTrigger(popupState)}
            data-cy="user-avatar-button"
            size="small"
            disableFocusRipple
          >
            <Tooltip title={t('header.userAvatarTooltip')}>
              <Stack direction="row" pl={1.5} pr={0.5} gap={2} alignItems="center">
                <SvgIcon component={WorkbaseAdjustSettings} color="inherit" />
                <Box
                  borderRadius="100%"
                  borderColor="primary.main"
                  border={({ palette }) =>
                    `2px solid ${isImpersonated ? palette.error.main : palette.primary.main}`
                  }
                >
                  <Avatar src={avatarUrl}>{data?.firstName[0]}</Avatar>
                </Box>
              </Stack>
            </Tooltip>
          </ToggleButton>
        </Stack>
      </Box>
    </>
  );
}

export default UserProfile;
