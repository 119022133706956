import React, { createContext, useReducer, useEffect, useMemo, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { sidebarApi } from 'src/store/api/sidebarApi';
import { WebSocketLike } from 'react-use-websocket/dist/lib/types';
import { ChatState, ChatAction } from './types';
import { chatReducer } from './chatReducer';
import { useSocket } from './use-socket';
import useFetchChats from './use-fetchChats';
import { useStreamDispatch } from './streamContext';
import useIsCustomerPortal from 'src/hooks/useIsCustomerPortal';

const initialState: ChatState = {
  isOpen: false,
  isFullScreen: false,
  locationBeforeFullscreen: null,
  currentInput: '',
  messages: [],
  threads: [],
  threadId: null,
  error: null,
  needsUpdate: null,
  isLoading: false,
  imageUrl: null,
  attachment: null,
  lastUserMessage: null,
  selectedResources: [],
  waitingForResponse: false,
};

export const ChatContext = createContext<{
  state: ChatState;
}>({
  state: initialState,
});

const ChatDispatchContext = createContext<React.Dispatch<ChatAction>>(() => null);
const ChatSocketContext = createContext<{
  getWebSocket: () => WebSocketLike | null;
  sendJsonMessage: (message: any) => void;
}>({
  getWebSocket: () => null,
  sendJsonMessage: () => null,
});

export function ChatProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(chatReducer, initialState);
  const { getWebSocket, sendJsonMessage } = useSocket(state.threadId, dispatch);
  const rtkDispatch = useDispatch<any>();
  const isCustomerPortal = useIsCustomerPortal();
  useFetchChats(dispatch, state.threadId);
  const streamDispatch = useStreamDispatch();

  useEffect(() => {
    dispatch({ type: 'SET_IMAGE_URL', payload: null });
    dispatch({ type: 'WEBSOCKET_ERROR', payload: null });
    streamDispatch({ type: 'CLEAR_STREAM' });
  }, [state.threadId, streamDispatch, isCustomerPortal]);

  useEffect(() => {
    dispatch({ type: 'CLEAR_CURRENT_THREAD' });
  }, [isCustomerPortal]);

  useEffect(() => {
    if (state.needsUpdate) {
      rtkDispatch(sidebarApi.util.invalidateTags(['Sidebar']));
      dispatch({ type: 'SET_NEEDS_UPDATE', payload: null });
    }
  }, [state.needsUpdate, rtkDispatch]);

  const contextValue = useMemo(() => ({ state }), [state]);

  const socketContextValue = useMemo(
    () => ({ getWebSocket, sendJsonMessage }),
    [getWebSocket, sendJsonMessage]
  );

  return (
    <ChatContext.Provider value={contextValue}>
      <ChatDispatchContext.Provider value={dispatch}>
        <ChatSocketContext.Provider value={socketContextValue}>
          {children}
        </ChatSocketContext.Provider>
      </ChatDispatchContext.Provider>
    </ChatContext.Provider>
  );
}

export function useChatState<T>(selector: (state: ChatState) => T): T {
  const { state } = useContext(ChatContext);
  return useMemo(() => selector(state), [state, selector]);
}

export function useChatDispatch() {
  const dispatch = useContext(ChatDispatchContext);
  return dispatch;
}

export function useChatSocket() {
  const { getWebSocket, sendJsonMessage } = useContext(ChatSocketContext);
  return { getWebSocket, sendJsonMessage };
}
