import axios from 'axios';
import { API_URL } from 'src/global-config';
import { store } from '../store';

const axiosInstance = axios.create({
  baseURL: `${API_URL}`,
  headers: {
    'Content-Type': 'application/json',
  },
  validateStatus: (status) => (status >= 200 && status < 300) || status === 308,
});

axiosInstance.interceptors.request.use((config) => {
  const state = store.getState();
  if (state.auth.token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${state.auth.token}`;
  }

  const currentPortal = state.userPortal.currentPortal as string;
  const previewMode = state.userPortal.previewMode as boolean;
  const previewGroupIds = state.userPortal.previewGroupIds as string[];
  const userType = state.auth.userType as string;
  const portal = userType === 'customer' ? 'customerPortal' : currentPortal;

  if (portal) {
    // eslint-disable-next-line no-param-reassign
    config.headers['portal-mode'] = portal;
  }

  if (previewMode) {
    // eslint-disable-next-line no-param-reassign
    config.headers.previewCustomerGroups = previewGroupIds.join(',');
  }

  return config;
});

export default axiosInstance;
