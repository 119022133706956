import {
  Button,
  Divider,
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
  Tooltip,
} from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  WorkbaseBackIcon,
  WorkbaseDeleteSquareIcon,
  WorkbaseLinksIcon,
} from '../../../../../../../assets/icons/workbaseIcons';

interface Props {
  onRemove: () => void;
  value: string;
  onApply: (val: string) => void;
  onClear: () => void;
  setLinkInputValue: (val: string) => void;
  href: string;
}

export default function LinkInput({
  onRemove,
  value,
  href,
  setLinkInputValue,
  onApply,
  onClear,
}: Props) {
  const hasError = value === '';
  const { t } = useTranslation();
  useEffect(() => {
    if (href && !value) {
      setLinkInputValue(href);
    }
  }, [href, value, setLinkInputValue]);

  return (
    <>
      <TextField
        data-cy="editor-topbar-link-input-textfield"
        autoFocus
        placeholder={t('editor.topBar.linkInputPlaceholder')}
        size="small"
        value={value}
        onChange={(e) => setLinkInputValue(e.target.value)}
        error={hasError}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon component={WorkbaseLinksIcon} fontSize="small" />
              </InputAdornment>
            ),
          },
        }}
      />
      <Tooltip title={!value ? t('editor.topBar.tooltip.emptyLinkField') : ''}>
        <span>
          <Button
            data-cy="editor-topbar-apply-link-button"
            disabled={!value}
            disableElevation
            color="inherit"
            onClick={() => {
              onApply(value);
              onRemove();
            }}
          >
            {t('editor.topBar.applyLinkButton')}
          </Button>
        </span>
      </Tooltip>
      <Divider orientation="vertical" flexItem />
      <Tooltip title={t('editor.topBar.tooltip.remove')} placement="top">
        <IconButton
          data-cy="editor-topbar-link-input-delete-button"
          onClick={() => {
            onClear();
            onRemove();
            setLinkInputValue('');
          }}
        >
          <SvgIcon component={WorkbaseDeleteSquareIcon} fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('editor.topBar.tooltip.back')} placement="top">
        <IconButton
          data-cy="editor-topbar-link-input-back-button"
          onClick={() => {
            onRemove();
          }}
        >
          <SvgIcon component={WorkbaseBackIcon} fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );
}
