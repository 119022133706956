import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import EditorSlice from 'src/features/editor/controller/Editor.slice';
import AwarenessSlice from 'src/features/awareness-avatars/controller/awareness.slice';
import { persistStore } from 'redux-persist';
import { resetPasswordApi, resendInvitationApi } from 'src/features/company-users/controller';
import { departmentsApi } from 'src/features/company-settings/departments/controller/departmentsApi';
import { departmentsSlice } from 'src/features/company-settings/departments/controller/departments.slice';
import companyUserSlice from 'src/features/company-users/controller/companyUserDetails.slice';
import { apiKeysApi } from 'src/store/api/apiKeysApi';
import { driveApi } from 'src/store/api/driveApi';
import searchHistoryReducer from 'src/features/global-search/controller/search.slice';
import { wikiApi } from './api/wikiApi';
import { dashboardApi } from './api/dashboardApi';
import { trainingsApi } from './api/trainings/trainingsApi';
import { settingsApi } from './api/settingsApi';
import { rolesApi } from './api/roles.api';
import { trainingModulesApi } from './api/trainings/trainingModulesApi';
import { trainingSectionsApi } from './api/trainings/trainingSectionsApi';
import { trainingLessonsApi } from './api/trainings/trainingLessonsApi';
import { searchResourceApi } from './api/searchResourceApi';
import { shareResourceApi } from './api/shareResourceApi';
import { searchApi } from './api/searchApi';
import { userPermissionsApi } from './api/userPermissionsApi';
import { videoProgressApi } from './api/trainings/videoProgressApi';
import { videoRequiredApi } from './api/trainings/videoRequiredApi';
import { whiteboardsApi } from './api/whiteboardsApi';
import { companyUsersApi } from './api/company-users/companyUsersApi';
import { pagesApi } from './api/pagesApi';
import { embeddedAppsApi } from './api/embeddedAppsApi';
import { newsfeedApi } from './api/newsfeedApi';
import UnauthorizedMiddlewareGuard from './middlewares/UnauthorizedMiddlewareGuard';
import { currentUserApi } from './api/currentUserApi';
import { iframeApi } from './api/iframeApi';
import { sidebarApi } from './api/sidebarApi';
import themeSettingsReducer from './client/themeSettingsSlice';
import authReducer from './client/authSlice';
import { authApi } from './api/authApi';
import aiChatApi from './api/aiChatApi';
import { dashboardReducer } from './client/dashboardSlice';
import { linksApi } from './api/linksApi';
import { videosApi } from './api/videos';
import { userPortalMiddleware, userPortalReducer } from './client/userPortalSlice';
import { permissionGroupsApi } from './api/permissionGroupsApi';
import { customerGroupsSlice } from 'src/features/company-settings/customer-groups/store/customerGroupsSlice';
import { customerGroupsApi } from './api/customerGroupsApi';

export const store = configureStore({
  reducer: {
    editor: EditorSlice.reducer,
    awareness: AwarenessSlice.reducer,
    auth: authReducer,
    dashboard: dashboardReducer,
    userPortal: userPortalReducer,
    customerGroups: customerGroupsSlice.reducer,
    departments: departmentsSlice.reducer,
    companyUser: companyUserSlice.reducer,
    searchHistory: searchHistoryReducer,
    themeSettings: themeSettingsReducer,
    [aiChatApi.reducerPath]: aiChatApi.reducer,
    [wikiApi.reducerPath]: wikiApi.reducer,
    [linksApi.reducerPath]: linksApi.reducer,
    [apiKeysApi.reducerPath]: apiKeysApi.reducer,
    [driveApi.reducerPath]: driveApi.reducer,
    [sidebarApi.reducerPath]: sidebarApi.reducer,
    [currentUserApi.reducerPath]: currentUserApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [trainingsApi.reducerPath]: trainingsApi.reducer,
    [companyUsersApi.reducerPath]: companyUsersApi.reducer,
    [iframeApi.reducerPath]: iframeApi.reducer,
    [embeddedAppsApi.reducerPath]: embeddedAppsApi.reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
    [rolesApi.reducerPath]: rolesApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [departmentsApi.reducerPath]: departmentsApi.reducer,
    [permissionGroupsApi.reducerPath]: permissionGroupsApi.reducer,
    [trainingModulesApi.reducerPath]: trainingModulesApi.reducer,
    [resetPasswordApi.reducerPath]: resetPasswordApi.reducer,
    [resendInvitationApi.reducerPath]: resendInvitationApi.reducer,
    [trainingSectionsApi.reducerPath]: trainingSectionsApi.reducer,
    [trainingLessonsApi.reducerPath]: trainingLessonsApi.reducer,
    [searchApi.reducerPath]: searchApi.reducer,
    [searchResourceApi.reducerPath]: searchResourceApi.reducer,
    [shareResourceApi.reducerPath]: shareResourceApi.reducer,
    [userPermissionsApi.reducerPath]: userPermissionsApi.reducer,
    [videoProgressApi.reducerPath]: videoProgressApi.reducer,
    [videoRequiredApi.reducerPath]: videoRequiredApi.reducer,
    [whiteboardsApi.reducerPath]: whiteboardsApi.reducer,
    [pagesApi.reducerPath]: pagesApi.reducer,
    [newsfeedApi.reducerPath]: newsfeedApi.reducer,
    [videosApi.reducerPath]: videosApi.reducer,
    [customerGroupsApi.reducerPath]: customerGroupsApi.reducer,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({ serializableCheck: false }).concat(aiChatApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(wikiApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(apiKeysApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(driveApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(currentUserApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(dashboardApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(trainingsApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(companyUsersApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(iframeApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(embeddedAppsApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(settingsApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(rolesApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(authApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(departmentsApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(permissionGroupsApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(trainingModulesApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(resetPasswordApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(resendInvitationApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(trainingSectionsApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(trainingLessonsApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(searchApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(searchResourceApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(shareResourceApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(userPermissionsApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(videoProgressApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(videoRequiredApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(whiteboardsApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(pagesApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(newsfeedApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(sidebarApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(linksApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(videosApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(customerGroupsApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(userPortalMiddleware.middleware),
    UnauthorizedMiddlewareGuard,
  ],
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);

export const useAppSelector: TypedUseSelectorHook<ReturnType<typeof store.getState>> = useSelector;
type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
