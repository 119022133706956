import EditorProvider from './EditorProvider';
import { EditorProviderProps } from './types';

export default function EditorVerifyE2EProvider(props: EditorProviderProps) {
  const isE2E = window.location.hostname.endsWith('automated.workbase.dev');
  if (isE2E) {
    return null;
  }
  return <EditorProvider {...props} />;
}
