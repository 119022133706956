import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UUID } from 'uuidjs';
import { Department, Role } from '../model/types';

interface State {
  departmentsDraftState: Department[];
  checkedRoles: string[];
  savingFailed: boolean;
}

const initialState: State = {
  departmentsDraftState: [],
  checkedRoles: [],
  savingFailed: false,
};

export const departmentsSlice = createSlice({
  name: 'departmentsSlice',
  initialState,
  reducers: {
    addPermissionToRoles: (
      state,
      action: PayloadAction<{ permissionToAdd: string; rolesIds: string[] }>
    ) => {
      state.departmentsDraftState = state.departmentsDraftState.map((dep) => ({
        ...dep,
        roles: dep.roles.map((role) => ({
          ...role,
          permissionsCount: role.permissionsCount + 1,
          permissionIds: action.payload.rolesIds.includes(role.id)
            ? [...new Set([...role.permissionIds, action.payload.permissionToAdd])]
            : role.permissionIds,
        })),
      }));
    },
    removePermissionFromRoles: (
      state,
      action: PayloadAction<{ permissionToRemove: string; rolesIds: string[] }>
    ) => {
      state.departmentsDraftState = state.departmentsDraftState.map((dep) => ({
        ...dep,
        roles: dep.roles.map((role) => ({
          ...role,

          permissionIds: action.payload.rolesIds.includes(role.id)
            ? role.permissionIds.filter((roleId) => action.payload.permissionToRemove !== roleId)
            : role.permissionIds,
        })),
      }));
    },
    checkRoles: (state, action: PayloadAction<string[]>) => {
      const rolesToAdd = [...new Set(action.payload)];
      state.checkedRoles.push(...rolesToAdd);
    },
    uncheckRoles: (state, action: PayloadAction<string[]>) => {
      state.checkedRoles = state.checkedRoles.filter((role) => !action.payload.includes(role));
    },
    clearCheckedRoles: (state) => {
      state.checkedRoles = [];
    },

    setDepartments: (state, action: PayloadAction<Department[]>) => ({
      ...state,
      departmentsDraftState: action.payload,
    }),
    addUserToRole: (state, action: PayloadAction<{ roleId: string; userId: string }>) => ({
      ...state,
      departmentsDraftState: state.departmentsDraftState.map((dep) => ({
        ...dep,
        roles: dep.roles.map((role) => ({
          ...role,
          usersCount: role.id === action.payload.roleId ? role.usersCount + 1 : role.usersCount,
          userIds:
            role.id === action.payload.roleId
              ? [...role.userIds, action.payload.userId]
              : role.userIds,
        })),
      })),
    }),
    removeUserFromRole: (state, action: PayloadAction<{ roleId: string; userId: string }>) => ({
      ...state,
      departmentsDraftState: state.departmentsDraftState.map((dep) => ({
        ...dep,
        roles: dep.roles.map((role) => ({
          ...role,
          usersCount:
            role.id === action.payload.roleId ? Math.max(role.usersCount - 1, 0) : role.usersCount,
          userIds:
            role.id === action.payload.roleId
              ? role.userIds.filter((id) => id !== action.payload.userId)
              : role.userIds,
        })),
      })),
    }),
    addDepartment: (state) => {
      const depId = UUID.generate();
      state.departmentsDraftState = [
        ...state.departmentsDraftState,
        {
          id: depId,
          name: '',
          roles: [
            {
              id: UUID.generate(),
              name: '',
              permissionIds: [],
              permissionsCount: 0,
              usersCount: 0,
              userIds: [],
            },
          ],
        },
      ];
    },
    addRole: (state, action: PayloadAction<string>) => {
      state.departmentsDraftState = state.departmentsDraftState.map((dep) => ({
        ...dep,
        roles:
          dep.id === action.payload
            ? [
                ...dep.roles,
                {
                  id: UUID.generate(),
                  name: '',
                  departmentId: dep.id,
                  permissionIds: [],
                  permissionsCount: 0,
                  usersCount: 0,
                  userIds: [],
                },
              ]
            : dep.roles,
      }));
    },
    editDepartmentName: (state, action: PayloadAction<{ departmentId: string; name: string }>) => {
      state.departmentsDraftState = state.departmentsDraftState.map((dep) => ({
        ...dep,
        name: dep.id === action.payload.departmentId ? action.payload.name : dep.name,
      }));
    },
    editRoleName: (
      state,
      action: PayloadAction<{ departmentId: string; roleId: string; rolename: string }>
    ) => {
      const departmentIndex = state.departmentsDraftState.findIndex(
        (dep) => dep.id === action.payload.departmentId
      );

      if (departmentIndex !== -1) {
        const updatedDepartment = { ...state.departmentsDraftState[departmentIndex] };
        const roleIndex = updatedDepartment.roles.findIndex(
          (role) => role.id === action.payload.roleId
        );
        if (roleIndex !== -1) {
          updatedDepartment.roles[roleIndex].name = action.payload.rolename;
        }
        state.departmentsDraftState[departmentIndex] = updatedDepartment;
      }
    },
    deleteDepartment: (state, action: PayloadAction<{ departmentId: string }>) => {
      state.departmentsDraftState = state.departmentsDraftState.filter(
        (department) => department.id !== action.payload.departmentId
      );
    },
    deleteRole: (state, action: PayloadAction<{ departmentId: string; roleId: string }>) => {
      const departmentIndex = state.departmentsDraftState.findIndex(
        (dep) => dep.id === action.payload.departmentId
      );

      if (departmentIndex !== -1) {
        const updatedDepartment = { ...state.departmentsDraftState[departmentIndex] };
        const updatedRoles = updatedDepartment.roles.filter(
          (role) => role.id !== action.payload.roleId
        );

        if (updatedRoles.length === 0) {
          // If no roles remain, delete the department
          state.departmentsDraftState = state.departmentsDraftState.filter(
            (dep) => dep.id !== action.payload.departmentId
          );
        } else {
          // Update the roles and department
          updatedDepartment.roles = updatedRoles;
          state.departmentsDraftState[departmentIndex] = updatedDepartment;
        }
      }
    },
    /* eslint-disable consistent-return */
    moveRoleToNewPosition: (
      state,
      action: PayloadAction<{
        moveRoleId: string;
        dropRoleId: string;
        order: number;
        onDraggingOnlyDepRoleError?: () => void;
        onDraggingExistingDepRoleError?: () => void;
      }>
    ) => {
      const {
        moveRoleId,
        order,
        dropRoleId,
        onDraggingOnlyDepRoleError,
        onDraggingExistingDepRoleError,
      } = action.payload;
      const roleExistsInDepartment = (department: Department, roleId: string): boolean =>
        department.roles.findIndex((role) => role.id === roleId) !== -1;

      const findDepartmentByRoleId = (
        departments: Department[],
        drRoleId: string
      ): Department | undefined =>
        departments.find((department) => department.roles.some((role) => role.id === drRoleId));

      const targetDep = findDepartmentByRoleId(state.departmentsDraftState, dropRoleId);

      let removedRole: null | Role = null;
      let orderToDropRole = order;
      state.departmentsDraftState.forEach((department) => {
        const roleIndex = department.roles.findIndex((role) => role.id === moveRoleId);
        const moveRoleName = department.roles.find((role) => role.id === moveRoleId)?.name;

        if (roleIndex !== -1 && department.roles.length !== 1) {
          const isTheDropRoleInTheSameDep = roleExistsInDepartment(department, dropRoleId);
          const roleWithSameNameExistsInDepartment = targetDep?.roles.some(
            (rol) => rol.name === moveRoleName
          );
          if (
            !isTheDropRoleInTheSameDep &&
            roleWithSameNameExistsInDepartment &&
            onDraggingExistingDepRoleError
          ) {
            onDraggingExistingDepRoleError();
            return state;
          }

          if (roleIndex < order - 1 && isTheDropRoleInTheSameDep) {
            orderToDropRole -= 1;
          }
          const [role] = department.roles.splice(roleIndex, 1);
          removedRole = role;
        } else if (roleIndex !== -1 && department.roles.length === 1 && moveRoleId !== dropRoleId) {
          if (onDraggingOnlyDepRoleError !== undefined) {
            onDraggingOnlyDepRoleError();
          }
        }
      });

      if (!removedRole) return state;
      // find drop role item
      state.departmentsDraftState.forEach((department) => {
        const roleIndex = department.roles.findIndex((role) => role.id === dropRoleId);
        if (roleIndex !== -1) {
          department.roles.splice(orderToDropRole - 1, 0, removedRole as Role);
        }
      });
    },
    setSavingFailed: (state, action: PayloadAction<boolean>) => {
      state.savingFailed = action.payload;
    },
  },
});

export const {
  setDepartments,
  addDepartment,
  setSavingFailed,
  addRole,
  editDepartmentName,
  editRoleName,
  deleteDepartment,
  deleteRole,
  moveRoleToNewPosition,
  checkRoles,
  uncheckRoles,
  addPermissionToRoles,
  clearCheckedRoles,
  removePermissionFromRoles,
  addUserToRole,
  removeUserFromRole,
} = departmentsSlice.actions;
