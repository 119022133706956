import { createApi } from '@reduxjs/toolkit/query/react';
import { ShareLevel } from 'src/lib/types/share-level';
import { baseQuery } from './api';
import { trainingsApi } from './trainings/trainingsApi';
import { sidebarApi } from './sidebarApi';
import { driveApi } from './driveApi';
import { wikiApi } from './wikiApi';

export interface ResourceInfo {
  id: string;
  required?: boolean;
  shareLevel?: ShareLevel;
  companyUser: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    avatarUrl: null | string;
  } | null;
  department: {
    id: string;
    name: string;
    rolesCount: number;
    companyUsersCount: number;
  } | null;
  role: {
    id: string;
    name: string;
    companyUsersCount: number;
    department: {
      name: string;
    };
  } | null;
  customerGroup: {
    id: string;
    name: string;
  } | null;
}

interface GeneralAccess {
  generalAccess: {
    type: GeneralAccessType;
    required: boolean;
    shareLevel: ShareLevel;
  };
}

export type GeneralAccessType = 'restricted' | 'everyone' | 'public';

export type ResourceType = 'training' | 'embeddedApp' | 'page' | 'whiteboard' | 'wikiCard' | 'file';

export const shareResourceApi = createApi({
  reducerPath: 'shareResource',
  baseQuery,
  tagTypes: ['SharedResourceInfo', 'ShareLevel'],
  endpoints: (builder) => ({
    // queries
    sharedResourceInfo: builder.query<
      ResourceInfo[],
      { resourceId: string; resourceType: ResourceType }
    >({
      query: ({ resourceId, resourceType }) => ({
        url: `/authz/resources/share/?resourceId=${resourceId}&resourceType=${resourceType}`,
      }),
      providesTags: ['SharedResourceInfo'],
    }),
    shareLevel: builder.query<
      { shareLevel: ShareLevel },
      { resourceId: string; resourceType: ResourceType }
    >({
      query: ({ resourceId, resourceType }) => ({
        url: `/authz/resources/share-levels/?resourceId=${resourceId}&resourceType=${resourceType}`,
      }),
      providesTags: ['ShareLevel'],
    }),
    generalAccess: builder.query<GeneralAccess, { resourceId: string; resourceType: ResourceType }>(
      {
        query: ({ resourceId, resourceType }) => ({
          url: `/authz/resources/general-access/?resourceId=${resourceId}&resourceType=${resourceType}`,
        }),
      }
    ),
    permissionAfterChange: builder.query<
      { shareLevel: ShareLevel },
      {
        resourceId: string;
        resourceType: ResourceType;
        shareLevel: ShareLevel;
        resourceShareId: string;
      }
    >({
      query: ({ resourceId, resourceType, shareLevel, resourceShareId }) => ({
        url: `/authz/resources/share/permission-after-change?resourceId=${resourceId}&resourceType=${resourceType}&resourceShareId=${resourceShareId}&shareLevel=${shareLevel}`,
      }),
    }),
    // mutations
    patchGeneralAccess: builder.mutation<
      void,
      {
        resourceId: string;
        resourceType: ResourceType;
        required?: boolean;
        type?: GeneralAccessType;
        shareLevel?: ShareLevel;
      }
    >({
      query: ({ resourceId, resourceType, required, type, shareLevel }) => ({
        url: `/authz/resources/general-access`,
        method: 'PATCH',
        body: {
          resourceId,
          resourceType,
          generalAccess: {
            required,
            type,
            shareLevel,
          },
        },
      }),
      onQueryStarted: (
        { resourceId, resourceType, required, type, shareLevel },
        { dispatch, queryFulfilled }
      ) => {
        const disp = dispatch(
          shareResourceApi.util.updateQueryData(
            'generalAccess',
            { resourceType, resourceId },
            (draft) => {
              if (required !== undefined) {
                draft.generalAccess.required = required;
              }
              if (type !== undefined) {
                draft.generalAccess.type = type;
              }
              if (shareLevel !== undefined) {
                draft.generalAccess.shareLevel = shareLevel;
              }
            }
          )
        );
        dispatch(trainingsApi.util.invalidateTags(['TrainingsByUserId']));
        queryFulfilled.then(() => {
          dispatch(wikiApi.util.invalidateTags(['WikiSidebar']));
          dispatch(sidebarApi.util.invalidateTags(['Sidebar']));
          dispatch(driveApi.util.invalidateTags(['Documents']));
        });
        queryFulfilled.catch(disp.undo);
      },
    }),
    postShareResource: builder.mutation<
      ResourceInfo,
      {
        resourceId: string;
        resourceType: ResourceType;
        companyUserId?: string;
        roleId?: string;
        departmentId?: string;
        customerGroupId?: string;
        required?: boolean;
      }
    >({
      query: (payload) => ({
        url: `/authz/resources/share`,
        method: 'POST',
        body: { ...payload, shareLevel: ShareLevel.view },
      }),
      invalidatesTags: ['SharedResourceInfo'],
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(sidebarApi.util.invalidateTags(['Sidebar']));
          dispatch(shareResourceApi.util.invalidateTags(['ShareLevel']));
        });
      },
    }),
    deleteSharedResource: builder.mutation<
      ResourceInfo,
      {
        resourceId: string;
        resourceType: ResourceType;
        sharedWithId: string;
      }
    >({
      query: ({ resourceId, resourceType, sharedWithId }) => ({
        url: `/authz/resources/share?resourceId=${resourceId}&resourceType=${resourceType}&sharedWithId=${sharedWithId}`,
        method: 'DELETE',
      }),
      onQueryStarted: (
        { resourceType, resourceId, sharedWithId },
        { dispatch, queryFulfilled }
      ) => {
        const disp = dispatch(
          shareResourceApi.util.updateQueryData(
            'sharedResourceInfo',
            { resourceType, resourceId },
            (draft) => draft.filter((resource) => resource.id !== sharedWithId)
          )
        );
        queryFulfilled.then(() => {
          dispatch(trainingsApi.util.invalidateTags(['TrainingsByUserId']));
          dispatch(sidebarApi.util.invalidateTags(['Sidebar']));
        });
        queryFulfilled.catch(disp.undo);
      },
    }),
    patchSharedResource: builder.mutation<
      ResourceInfo,
      {
        id: string;
        resourceId: string;
        resourceType: ResourceType;
        required?: boolean;
        shareLevel?: ShareLevel;
      }
    >({
      query: (payload) => ({
        url: `/authz/resources/share/${payload.id}`,
        method: 'PATCH',
        body: {
          ...payload,
          resourceId: undefined,
          resourceType: undefined,
          id: undefined,
          sidebar: true,
        },
      }),
      onQueryStarted: (
        { resourceType, resourceId, id, required, shareLevel },
        { dispatch, queryFulfilled }
      ) => {
        const disp = dispatch(
          shareResourceApi.util.updateQueryData(
            'sharedResourceInfo',
            { resourceType, resourceId },
            (draft) =>
              draft.map((resource) =>
                resource.id === id
                  ? {
                      ...resource,
                      required: required === undefined ? resource.required : required,
                      shareLevel: shareLevel === undefined ? resource.shareLevel : shareLevel,
                    }
                  : resource
              )
          )
        );
        dispatch(trainingsApi.util.invalidateTags(['TrainingsByUserId']));
        queryFulfilled.then(() => {
          dispatch(sidebarApi.util.invalidateTags(['Sidebar']));
          dispatch(wikiApi.util.invalidateTags(['WikiSidebar']));
          dispatch(shareResourceApi.util.invalidateTags(['ShareLevel']));
          dispatch(driveApi.util.invalidateTags(['Documents', 'Files']));
        });
        queryFulfilled.catch(disp.undo);
      },
    }),
  }),
});

export const {
  useSharedResourceInfoQuery,
  usePostShareResourceMutation,
  usePatchSharedResourceMutation,
  useShareLevelQuery,
  useDeleteSharedResourceMutation,
  useGeneralAccessQuery,
  usePatchGeneralAccessMutation,
  useLazyPermissionAfterChangeQuery,
} = shareResourceApi;
