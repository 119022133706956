import { Stack, SvgIcon, ToggleButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { WorkbaseEmployeeportalIcon } from 'src/assets/icons/workbaseIcons';
import useIsCustomerPortal from 'src/hooks/useIsCustomerPortal';
import { useAppDispatch, useAppSelector } from 'src/store';
import { switchPortal } from 'src/store/client/userPortalSlice';

export default function CustomerPortalSwitch() {
  const currentPortal = useAppSelector((state) => state.userPortal.currentPortal);
  const navigate = useNavigate();
  const isCustomerPortal = useIsCustomerPortal();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <Tooltip title={isCustomerPortal ? t('switchToEmployeePortal') : t('switchToCustomerPortal')}>
      <ToggleButton
        color="primary"
        size="small"
        sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, px: 2 }}
        value={currentPortal}
        disableFocusRipple
        disableTouchRipple
        selected
        disableActiveShrink
        onClick={() => {
          dispatch(switchPortal(isCustomerPortal ? 'employeePortal' : 'customerPortal'));
          navigate('/');
        }}
        data-cy="customer-portal-switch-button"
      >
        <Stack direction="row" gap={1}>
          <SvgIcon component={WorkbaseEmployeeportalIcon} />
          {isCustomerPortal ? t('customer') : t('employee')}
        </Stack>
      </ToggleButton>
    </Tooltip>
  );
}
