import { SvgIcon, useTheme, SvgIconProps } from '@mui/material';
import { FileIcon as ReactFileIcon, defaultStyles } from 'react-file-icon';
export default function FileIcon({
  extension,
  svgIconProps,
}: {
  extension: string;
  svgIconProps?: SvgIconProps;
}) {
  const theme = useTheme();
  return (
    <SvgIcon fontSize="large" {...svgIconProps}>
      <ReactFileIcon
        extension={extension}
        {...defaultStyles[extension as keyof typeof defaultStyles]}
        glyphColor={theme.palette.primary.contrastText}
        gradientColor={theme.palette.primary.light}
        labelColor={theme.palette.primary.dark}
        labelTextColor={theme.palette.primary.contrastText}
        color={theme.palette.primary.main}
      />
    </SvgIcon>
  );
}
