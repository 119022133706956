import {
  Paper,
  Box,
  Stack,
  Typography,
  IconButton,
  SvgIcon,
  Divider,
  Collapse,
} from '@mui/material';
import { WorkbaseCloseCircleIcon } from 'src/assets/icons/workbaseIcons';
import LottieChevron from 'src/ui-components/custom/lottie/LottieChevron';
import UploadingFilesList from './UploadingFilesList';

import { memo } from 'react';
import { useTranslation } from 'react-i18next';
function UploadProgressWindow({
  collapsed,
  setCollapsed,
  setIsOpen,
  uploadsNumber,
  isAllUploadsComplete,
  successUploadsNumber,
}: {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
  setIsOpen: (isOpen: boolean) => void;
  uploadsNumber: number;
  isAllUploadsComplete: boolean;
  successUploadsNumber: number;
}) {
  const { t } = useTranslation();

  const uploadCompleteText = isAllUploadsComplete
    ? t('upload_window.upload_complete', { count: successUploadsNumber })
    : t('upload_window.uploading', { count: uploadsNumber - successUploadsNumber });

  return (
    <Paper component={Box} width={360}>
      <Stack pb={2}>
        <Stack
          pr={1.25}
          pl={2}
          py={0.5}
          justifyContent="space-between"
          direction="row"
          alignItems="center"
        >
          <Typography fontWeight="bold">{uploadCompleteText}</Typography>
          <Stack direction="row" alignItems="center" gap={0.25}>
            <IconButton size="small" onClick={() => setCollapsed(!collapsed)} disableTouchRipple>
              <LottieChevron
                open={collapsed}
                lottieProps={{
                  containerProps: {
                    height: 20,
                    width: 20,
                  },
                }}
              />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => setIsOpen(false)}
              disableTouchRipple
              disabled={!isAllUploadsComplete}
            >
              <SvgIcon component={WorkbaseCloseCircleIcon} fontSize="small" />
            </IconButton>
          </Stack>
        </Stack>
        <Divider />
        <Collapse in={!collapsed}>
          <UploadingFilesList />
        </Collapse>
      </Stack>
    </Paper>
  );
}

export default memo(UploadProgressWindow);
