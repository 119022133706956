import { Box, Button, Stack, SvgIcon } from '@mui/material';
import { useBoolean } from 'src/lib/hooks/use-boolean';
import { useTranslation } from 'react-i18next';
import { WorkbaseAddSquareIcon } from 'src/assets/icons/workbaseIcons';
import { ResourceType, useLinksQuery, usePatchLinkMutation } from 'src/store/api/linksApi';
import AddEditLinkModal from './AddEditLinkModal';
import Link from './Link';
import generateJitteredKeyBetween from 'src/lib/utils/generateJitteredKeyBetween';

interface Props {
  readOnly: boolean;
  resourceId: string;
  resourceType: ResourceType;
}

export default function Links({ readOnly, resourceId, resourceType }: Props) {
  const { data } = useLinksQuery({ resourceId, resourceType });
  const [patchLink] = usePatchLinkMutation();
  const { t } = useTranslation();
  const addLinkDialog = useBoolean();
  if (data && data.length === 0 && readOnly) return null;

  const handleDrop = (index: number, id: string) => {
    if (!data) return;
    const maxPos = index < data.length - 1 ? data[index]?.position : null;
    const minPos = index !== 0 ? data[index - 1]?.position : null;
    const key = generateJitteredKeyBetween(minPos, maxPos);
    patchLink({ resourceId, resourceType, id, position: key });
  };

  return (
    <Stack
      gap={2.5}
      direction="row"
      flexWrap="wrap"
      sx={{ '& > *': { flexGrow: 1, flexBasis: 0 } }}
    >
      {data?.map(({ url, name, id, imageUrl }, index) => (
        <Link
          data-cy={`${name}-link`}
          id={id}
          resourceId={resourceId}
          resourceType={resourceType}
          key={id}
          url={url}
          imageUrl={imageUrl || undefined}
          readOnly={readOnly}
          linkName={name}
          onDrop={handleDrop}
          index={index}
        />
      ))}
      {!readOnly && (
        <Box minWidth={200}>
          <Button
            data-cy="add-link-button"
            fullWidth
            onClick={() => addLinkDialog.onTrue()}
            startIcon={
              <SvgIcon fontSize="inherit">
                <WorkbaseAddSquareIcon />
              </SvgIcon>
            }
          >
            {t('links.addLinkButton')}
          </Button>
        </Box>
      )}
      {!readOnly && (
        <AddEditLinkModal
          resourceId={resourceId}
          resourceType={resourceType}
          open={addLinkDialog.value}
          onClose={() => addLinkDialog.onFalse()}
        />
      )}
    </Stack>
  );
}
