import { Stack, IconButton, SvgIcon, InputBase, Box, Container } from '@mui/material';
import { memo, useRef, useEffect, ChangeEvent, useCallback } from 'react';
import {
  WorkbaseArrowUpCircleIcon,
  WorkbaseAttachmentIcon,
  WorkbaseCloseCircleIcon,
} from 'src/assets/icons/workbaseIcons';
import { imageUpload } from 'src/lib/utils/imageUpload';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import { useTranslation } from 'react-i18next';
import { MessageContent, useCreateThreadMutation } from 'src/store/api/aiChatApi';
import { useNavigate } from 'react-router-dom';
import useIsMobile from 'src/hooks/useIsMobile';
import { useChatState, useChatDispatch, useChatSocket } from '../../helpers/chatContext';
import AttachmentImage from './AttachmentImage';
import { loadAllUserData } from '../../helpers/use-socket';
import { ChatState } from '../../helpers/types';
import SuggestionTextfield from './suggestions-textfield/SuggestionTextfield';
import { ResultData } from 'src/store/api/searchApi';
import { useStreamState } from '../../helpers/streamContext';

// eslint-disable-next-line max-statements
function DialogInputs() {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const isMobile = useIsMobile();
  const textFieldRef = useRef<HTMLInputElement>(null);

  // Memoize the selector function
  const selector = useCallback(
    (state: ChatState) => ({
      threadId: state.threadId,
      currentInput: state.currentInput,
      imageUrl: state.imageUrl,
      attachment: state.attachment,
      selectedResources: state.selectedResources,
      waitingResponse: state.waitingForResponse,
      isFullScreen: state.isFullScreen,
    }),
    []
  );

  const state = useChatState(selector);
  const { isStreaming } = useStreamState();
  // Use destructuring after getting the state
  const {
    threadId,
    currentInput,
    imageUrl,
    attachment,
    selectedResources,
    waitingResponse,
    isFullScreen,
  } = state;

  const dispatch = useChatDispatch();
  const { sendJsonMessage } = useChatSocket();
  const { t } = useTranslation();
  const [createThread] = useCreateThreadMutation();
  const navigate = useNavigate();
  useEffect(() => {
    textFieldRef.current?.focus();
  }, [threadId, imageUrl, textFieldRef]);

  const createMessageContents = useCallback((): MessageContent[] => {
    const contents: MessageContent[] = [];
    if (currentInput?.trim()) {
      contents.push({ type: 'text', text: currentInput });
    }
    if (imageUrl) {
      contents.push({ type: 'image', image: imageUrl.image, file_name: imageUrl.file_name });
    }
    if (attachment && attachment.data) {
      const reader = new FileReader();
      reader.readAsDataURL(attachment.data);
      reader.onload = () => {
        contents.push({
          type: 'file',
          file_name: attachment?.file_name,
          data: reader.result as string,
        });
      };
    }
    return contents;
  }, [currentInput, imageUrl, attachment]);

  const createNewThread = useCallback(async (): Promise<string> => {
    const { id } = await createThread().unwrap();
    dispatch({ type: 'SET_SELECTED_CHAT', payload: id });
    if (isFullScreen || isMobile) {
      navigate(`/ai/${id}`);
    }
    return id;
  }, [createThread, dispatch, isFullScreen, isMobile, navigate]);

  const updateState = useCallback(
    (currentThreadId: string): void => {
      dispatch({ type: 'WEBSOCKET_ERROR', payload: null });
      dispatch({ type: 'SET_SELECTED_CHAT', payload: currentThreadId });
      dispatch({ type: 'SET_IMAGE_URL', payload: null });
    },
    [dispatch]
  );

  const handleSendMessage = useCallback(async (): Promise<void> => {
    const messageContents = createMessageContents();
    dispatch({ type: 'SET_CURRENT_INPUT', payload: '' });
    if (messageContents.length === 0) return;
    let currentThreadId = threadId;
    dispatch({
      type: 'SEND_CHAT_MESSAGE',
      payload: {
        thread_id: currentThreadId ?? 'temp',
        role: 'user',
        content: messageContents,
        resources: selectedResources,
        createdAt: new Date().getTime(),
      },
    });
    dispatch({ type: 'SET_SELECTED_RESOURCES', payload: [] });
    dispatch({ type: 'SET_WAITING_RESPONSE', payload: true });
    try {
      if (!currentThreadId) {
        currentThreadId = await createNewThread();
      }
      sendJsonMessage({
        type: 'content',
        content: messageContents,
        metadata: { ...loadAllUserData(), retry: false, resources: selectedResources },
        threadId: currentThreadId,
      });
      updateState(currentThreadId);
    } catch (_error) {
      dispatch({ type: 'SET_SELECTED_RESOURCES', payload: [] });
      dispatch({ type: 'SET_CURRENT_INPUT', payload: '' });
      dispatch({ type: 'SET_WAITING_RESPONSE', payload: true });
      dispatch({ type: 'SET_IMAGE_URL', payload: null });
    }
  }, [
    sendJsonMessage,
    updateState,
    selectedResources,
    threadId,
    createNewThread,
    dispatch,
    createMessageContents,
  ]);

  const handleImageSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && fileInputRef.current) {
      try {
        const response = await imageUpload(file);
        const { variants } = response.data.result;
        dispatch({
          type: 'SET_IMAGE_URL',
          payload: {
            type: 'image',
            file_name: file.name,
            image: variants[0],
          },
        });

        fileInputRef.current.value = '';
      } catch (_err) {
        showNotification(t('validationErrorMessages.UploadImageErrorMessage'), 'error');
      }
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'SET_ATTACHMENT',
      payload: {
        type: 'file',
        file_name: e.target.files?.[0]?.name,
        data: e.target.files?.[0],
      },
    });
    showNotification('attached!');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };
  // Set the cursor to the end of the input when component is mounted
  useEffect(() => {
    if (textFieldRef.current && currentInput) {
      const input = textFieldRef.current;
      const { length } = currentInput;
      input.setSelectionRange(length, length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAttachmentClick = () => {
    fileInputRef.current?.click();
  };

  const handleCancelMessage = () => {
    sendJsonMessage({
      type: 'system',
      content: 'cancelRun',
    });
  };

  const handleInputChange = useCallback(
    (value: string) => {
      dispatch({ type: 'SET_CURRENT_INPUT', payload: value });
    },
    [dispatch]
  );

  const handleSelectedItemsChange = useCallback(
    (items: ResultData[]) => {
      dispatch({ type: 'SET_SELECTED_RESOURCES', payload: items });
    },
    [dispatch]
  );

  return (
    <Container maxWidth="lg" disableGutters>
      {imageUrl && (
        <Box px={2} py={0.5} position="relative" display="flex">
          <AttachmentImage
            imageUrl={imageUrl.image}
            fileName={imageUrl.file_name}
            readonly={false}
            onClose={() => dispatch({ type: 'SET_IMAGE_URL', payload: null })}
          />
        </Box>
      )}
      <Stack py={1} px={1} direction="row" gap={1} alignItems="center">
        <InputBase
          type="file"
          inputProps={{
            accept: 'image/jpeg,image/png',
          }}
          sx={{ display: 'none' }}
          inputRef={fileInputRef}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.files?.[0]?.type?.includes('image')) handleImageSelect(e);
            else handleFileSelect(e);
          }}
        />
        <IconButton
          size="medium"
          onClick={handleAttachmentClick}
          data-cy="ai-assistant-chat-attach-file-button"
        >
          <SvgIcon component={WorkbaseAttachmentIcon} />
        </IconButton>
        <SuggestionTextfield
          value={currentInput}
          setValue={handleInputChange}
          selectedItems={selectedResources}
          setSelectedItems={handleSelectedItemsChange}
          handleSendMessage={handleSendMessage}
          inputRef={textFieldRef}
          blockSend={isStreaming || waitingResponse}
        />
        {/* Send Button */}
        {isStreaming ? (
          <IconButton
            size="medium"
            onClick={handleCancelMessage}
            data-cy="ai-assistant-chat-cancel-message-button"
          >
            <SvgIcon component={WorkbaseCloseCircleIcon} />
          </IconButton>
        ) : (
          <IconButton
            data-cy="ai-assistant-chat-send-message-button"
            size="medium"
            onClick={handleSendMessage}
            disabled={isStreaming || waitingResponse || !currentInput?.trim()}
          >
            <SvgIcon component={WorkbaseArrowUpCircleIcon} />
          </IconButton>
        )}
      </Stack>
    </Container>
  );
}

export default memo(DialogInputs);
