import { Stack } from '@mui/material';
import GlobalSearch from 'src/features/global-search';
import { useCurrentUserQuery } from 'src/store/api/currentUserApi';
import useIsMobile from 'src/hooks/useIsMobile';
import UserProfile from './UserProfile';
import ActionButtons from './action-buttons/ActionButtons';
import { UnsavedChangesProvider } from 'src/lib/utils/UnsavedChangesContext';

function AppHeader() {
  const { data } = useCurrentUserQuery();

  const isMobile = useIsMobile();
  return (
    <Stack py={1} position="relative" component="header" width="100%">
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2}>
        <Stack direction="row" gap={2} flexGrow={1}>
          {!isMobile && <GlobalSearch />}
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          {!isMobile && <ActionButtons />}
          <UnsavedChangesProvider>
            <UserProfile
              avatarUrl={data?.avatarUrl || ''}
              isImpersonated={data?.isImpersonated || false}
            />
          </UnsavedChangesProvider>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default AppHeader;
