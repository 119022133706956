import {
  Box,
  Stack,
  Typography,
  IconButton,
  SvgIcon,
  ListItemButton,
  Paper,
  ListItemText,
} from '@mui/material';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  WorkbaseCheckmarkIcon,
  WorkbaseClockIcon,
  WorkbaseResetIcon,
} from 'src/assets/icons/workbaseIcons';
import {
  TrainingLesson,
  useMarkTrainingLessonAsUndoneMutation,
} from 'src/store/api/trainings/trainingLessonsApi';
import ImageWithDefaultFallback from 'src/ui-components/image/ImageWithDefaultFallback';
import useScrollIntoView from './useScrollIntoView';

export default function ReadOnlyLesson({ lesson }: { lesson: TrainingLesson }) {
  const { t } = useTranslation();
  const [undoneLesson] = useMarkTrainingLessonAsUndoneMutation();
  const params = useParams<{ trainingId: string; moduleId: string; lessonId?: string }>();
  const isLessonActive = params.lessonId === lesson.id;
  const estimatedTimeLabel = t('minute', {
    count: lesson.estimatedTimeToComplete ? lesson.estimatedTimeToComplete : 0,
  });
  const containerRef = useRef<HTMLDivElement>(null);
  useScrollIntoView(lesson.id, containerRef);
  return (
    <Box pb={2}>
      <Paper component={Box} overflow="hidden" sx={{ borderRadius: 1 }}>
        <ListItemButton
          disableActiveShrink
          selected={isLessonActive}
          disableTouchRipple
          component={Box}
        >
          <Stack
            alignItems="center"
            flexDirection="row"
            gap={1}
            minWidth="0"
            overflow="hidden"
            justifyContent="space-between"
            width="100%"
          >
            <Stack flexShrink={0}>
              <ImageWithDefaultFallback
                width={96}
                height={54}
                defaultImage={lesson.defaultImage}
                src={lesson.imageUrl}
                alt={lesson.name}
              />
            </Stack>

            <Box flexGrow={1} minWidth="0" overflow="hidden">
              <ListItemText
                primary={lesson.name || t('modulePage.unnamedLesson')}
                primaryTypographyProps={{
                  noWrap: true,
                  variant: 'body1',
                  fontWeight: 'bold',
                  color: 'text.secondary',
                }}
                secondary={
                  <Stack flexDirection="row" gap={0.5} alignItems="center" color="text.secondary">
                    <SvgIcon component={WorkbaseClockIcon} fontSize="tiny" htmlColor="inherit" />
                    <Typography
                      fontWeight="medium"
                      variant="body2"
                      color="inherit"
                      lineHeight={1.25}
                    >
                      {estimatedTimeLabel}
                    </Typography>
                  </Stack>
                }
              />
            </Box>

            {lesson.done && (
              <IconButton
                size="small"
                color="success"
                onClick={(e) => {
                  undoneLesson({
                    lessonId: lesson.id,
                    sectionId: lesson.trainingSectionId,
                    moduleId: params.moduleId,
                  });
                  e.stopPropagation();
                }}
                sx={{
                  '.reset-icon': {
                    display: 'none',
                  },
                  '&:hover': {
                    '.reset-icon': {
                      display: 'block',
                    },
                    '.checkmark-icon': {
                      display: 'none',
                    },
                  },
                }}
              >
                <SvgIcon
                  component={WorkbaseCheckmarkIcon}
                  className="checkmark-icon"
                  fontSize="inherit"
                />
                <SvgIcon component={WorkbaseResetIcon} className="reset-icon" fontSize="inherit" />
              </IconButton>
            )}
          </Stack>
        </ListItemButton>
      </Paper>
    </Box>
  );
}
