import { createBrowserRouter } from 'react-router-dom';
import UnauthorizedLayout from 'src/layouts/UnauthorizedLayout';
import CurrentUserLanguageProvider from 'src/providers/CurrentUserLanguageProvider';
import DefaultLanguageProvider from 'src/providers/DefaultLanguageProvider';
import UnauthorizedView from '../views/unauthorized/UnauthorizedView';
import AuthProvider from '../providers/AuthProvider';
import AuthorizedRoutes from './AuthorizedRoutes';
import CustomerPortalGuard from 'src/providers/CustomerPortalGuard';

const guestRoutes = [
  {
    path: '/unauthorized',
    element: (
      <UnauthorizedLayout>
        <UnauthorizedView />
      </UnauthorizedLayout>
    ),
  },
];

const authRoutes = [
  {
    path: '/*',
    element: <AuthorizedRoutes />,
  },
];
const authGuardedRoutes = authRoutes.map((route) => ({
  ...route,
  element: (
    <AuthProvider>
      <CustomerPortalGuard>
        <CurrentUserLanguageProvider>{route.element}</CurrentUserLanguageProvider>
      </CustomerPortalGuard>
    </AuthProvider>
  ),
}));

const guestGuardedRoutes = guestRoutes.map((route) => ({
  ...route,
  element: <DefaultLanguageProvider>{route.element}</DefaultLanguageProvider>,
}));

export const router = createBrowserRouter([...guestGuardedRoutes, ...authGuardedRoutes]);
