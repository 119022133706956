import { useEffect, useRef } from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/store';
import LottieFullScreenSpinner from 'src/core/lottie-logo-spinner/LottieFullScreenSpinner';
import { useExchangeTokenMutation, useRefreshTokenMutation } from '../store/api/authApi';
import { setAuthState } from '../store/client/authSlice';
import PublicPageLayout from 'src/layouts/PublicPageLayout';

interface AuthProviderProps {
  children: React.ReactNode;
}

function TokenExchange({ queryToken }: { queryToken: string }) {
  const dispatch = useAppDispatch();
  const [exchangeToken, { error, isSuccess, data }] = useExchangeTokenMutation();

  useEffect(() => {
    dispatch(
      setAuthState({
        token: undefined,
        expirationTime: undefined,
        refreshToken: undefined,
        userType: undefined,
      })
    );
    exchangeToken(queryToken);
  }, [queryToken, exchangeToken, dispatch]);

  useEffect(() => {
    if (isSuccess && data) {
      const { accessToken, accessTokenExpiresIn, refreshToken, userType } = data;
      const expirationTime = Date.now() + accessTokenExpiresIn;
      dispatch(setAuthState({ token: accessToken, expirationTime, refreshToken, userType }));
    }
  }, [isSuccess, data, dispatch]);

  if (error) {
    return <Navigate to="/unauthorized" />;
  }

  if (isSuccess) {
    return <Navigate to="/" />;
  }

  return <LottieFullScreenSpinner />;
}
// we have token and refresh token or just token or just refreshToken, we can have expiration time or not in all cases
function RefreshTokenProvider({ children }: AuthProviderProps) {
  const { refreshToken, expirationTime, token } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [handleRefreshToken, { data, error }] = useRefreshTokenMutation();
  const refreshIntervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const refreshAdvanceTime = 5 * 60 * 1000;
    const shouldRefresh = expirationTime && Date.now() < expirationTime - refreshAdvanceTime;
    function refreshAccessToken() {
      if (refreshToken) {
        handleRefreshToken(refreshToken);
      }
    }
    if (!token) return refreshAccessToken();
    if (shouldRefresh && expirationTime) {
      const intervalTime = expirationTime - Date.now() - refreshAdvanceTime;
      refreshIntervalRef.current = setInterval(refreshAccessToken, intervalTime);
    } else {
      refreshAccessToken();
    }

    return () => {
      if (refreshIntervalRef.current) {
        clearInterval(refreshIntervalRef.current);
      }
    };
  }, [refreshToken, expirationTime, handleRefreshToken, token]);

  useEffect(() => {
    if (data) {
      const { accessToken, accessTokenExpiresIn } = data;
      const newExpirationTime = Date.now() + accessTokenExpiresIn;
      dispatch(
        setAuthState({
          token: accessToken,
          expirationTime: newExpirationTime,
        })
      );
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (error) {
      dispatch(
        setAuthState({
          token: undefined,
          expirationTime: undefined,
          refreshToken: undefined,
          userType: undefined,
        })
      );
    }
  }, [error, dispatch]);

  if (error) {
    return <Navigate to="/unauthorized" />;
  }

  if ((expirationTime && Date.now() > expirationTime) || !token) {
    return <LottieFullScreenSpinner />;
  }

  return children;
}

function AuthProvider({ children }: AuthProviderProps) {
  const { refreshToken, token } = useAppSelector((state) => state.auth);
  const [searchParams] = useSearchParams();
  const pathname = useLocation().pathname;
  const queryToken = searchParams.get('exchangeToken');

  if (pathname.includes('page')) {
    if (!refreshToken && !queryToken && !token) {
      return <PublicPageLayout>{children}</PublicPageLayout>;
    }
  }

  if (!refreshToken && !queryToken && !token) {
    return <Navigate to="/unauthorized" />;
  }

  if (queryToken) {
    return <TokenExchange queryToken={queryToken} />;
  }

  return <RefreshTokenProvider>{children}</RefreshTokenProvider>;
}

export default AuthProvider;
