import { Typography } from '@mui/material';
import Purify from 'dompurify';

interface Props {
  content: string;
}

function HtmlContent({ content }: Props) {
  const createMarkup = (html: string) => ({ __html: Purify.sanitize(html) });

  return <Typography noWrap dangerouslySetInnerHTML={createMarkup(content)} />;
}

export default HtmlContent;
