import { useState } from 'react';
import { Stack, Box, Container } from '@mui/material';
import { usePatchFileMutation } from 'src/store/api/driveApi';
import { TabContext } from '@mui/lab';
import { DropzoneProps, useDropzone } from 'react-dropzone';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import { useTranslation } from 'react-i18next';
import DocumentsDataList from './DocumentsDataList';
import DocumentsHeader from './DocumentsHeader';
import useTabsData from './useTabsData';
import { useUpload } from 'src/core/upload/UploadContext';
import { useSearchParams } from 'react-router-dom';

export default function Documents() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { uploadFile } = useUpload();
  const [isDragging, setIsDragging] = useState(false);
  const { t } = useTranslation();

  const [patchFile] = usePatchFileMutation();

  const hadleUpload = (files: File[]) => {
    files.forEach((file) => {
      uploadFile({
        file,
        resource: {
          type: 'drive',
          id: '',
          name: 'Drive',
          route: '/documents',
          params: 'tab=files',
        },
        errorMessage: t('validationErrorMessages.UploadFailed'),
        onFileUploadSuccess: (id) => {
          showNotification(t('documentsPage.fileSuccessfulUpload'));
          patchFile({ id, uploadStatus: 'uploaded' });
        },
        onFileUploadError: (id) => {
          patchFile({ id, uploadStatus: 'failed' });
        },
      });
    });
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: (acceptedFiles) => {
      hadleUpload(acceptedFiles);
      setIsDragging(false); // Reset drag state when files are dropped
    },
    noClick: true, // Prevents the file dialog from opening on click
    onDragEnter: () => setIsDragging(true),
    onDragLeave: () => setIsDragging(false),
  });

  const dropzoneProps = {
    ...getRootProps({
      onClick: (e) => {
        e.preventDefault(); // Prevent clicking from triggering the file dialog
      },
    }),
  };

  const {
    tabsData,
    isDocumentsDataLoading,
    isFilesDataLoading,
    setDocumentsPage,
    setFilesPage,
    filesRowCount,
    documentsRowCount,
    setDocumentsSortModel,
    setFilesSortModel,
  } = useTabsData();

  return (
    <Stack overflow="auto" height="100%">
      <TabContext value={searchParams.get('tab') || 'documents'}>
        <Container component={Box} maxWidth="xl">
          <Stack gap={2}>
            <DocumentsHeader
              tabsData={tabsData}
              onActiveTabChange={(val) => setSearchParams({ tab: val })}
              openUpload={open}
            />
            <DocumentsDataList
              tabsData={tabsData}
              dropZoneProps={dropzoneProps as DropzoneProps}
              isDragging={isDragging}
              setDocumentsPage={setDocumentsPage}
              setFilesPage={setFilesPage}
              filesRowCount={filesRowCount}
              documentsRowCount={documentsRowCount}
              setDocumentsSortModel={setDocumentsSortModel}
              setFilesSortModel={setFilesSortModel}
              isDocumentsDataLoading={isDocumentsDataLoading}
              isFilesDataLoading={isFilesDataLoading}
            />
          </Stack>
        </Container>
      </TabContext>
      <input {...getInputProps()} />
    </Stack>
  );
}
