import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import { API_URL } from 'src/global-config';

export const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { getState }) => {
    const state = getState() as any;
    const token = state.auth.token;
    const userType = state.auth.userType as string;
    const currentPortal = state.userPortal.currentPortal as string;
    const previewMode = state.userPortal.previewMode as boolean;
    const previewGroupIds = state.userPortal.previewGroupIds as string[];
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    const portal = userType === 'customer' ? 'customerPortal' : currentPortal;
    if (portal) {
      headers.set('portal-mode', portal);
    }
    if (previewMode) {
      headers.set('previewCustomerGroups', previewGroupIds.join(','));
    }
    return headers;
  },
});
