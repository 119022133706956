import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export interface AuthState {
  token?: string;
  refreshToken?: string;
  expirationTime?: number;
  userType?: UserType;
}

export type UserType = 'employee' | 'customer';

const authPersistConfig = {
  key: 'auth',
  storage,
};

const initialState: AuthState = {};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthState: (state, action: PayloadAction<AuthState>) => ({ ...state, ...action.payload }),
    clearAuthToken: (state) => {
      state.token = undefined;
      state.expirationTime = undefined;
      state.refreshToken = undefined;
    },
  },
});

export const { setAuthState, clearAuthToken } = authSlice.actions;
const authReducer = persistReducer(authPersistConfig, authSlice.reducer);
export default authReducer;
