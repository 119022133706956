import { useState } from 'react';
import { FormControlLabel, Stack, Switch, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  initialChecked: boolean;
  onChange: (hasAccess: boolean) => void;
  dataCy?: string;
}

export default function AccessSettings({ initialChecked, onChange, dataCy }: Props) {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(initialChecked);
  const handleChange = () => {
    setChecked(!checked);
    onChange(!checked);
  };

  return (
    <Stack gap={1}>
      <FormControlLabel
        label={
          <Typography noWrap variant="body2">
            {t('generalSettings.access.switchLabel')}
          </Typography>
        }
        control={
          <Switch
            checked={checked}
            onChange={handleChange}
            size="small"
            data-cy={dataCy && `${dataCy}-access-toggle`}
          />
        }
      />
      <Typography variant="caption" color="text.secondary">
        {t('generalSettings.access.switchDescription')}
      </Typography>
    </Stack>
  );
}
