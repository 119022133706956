import { Dialog, DialogContent } from '@mui/material';
import ScreenCameraRecorder from 'src/features/screen-camera-recorder';
import { useAppDispatch, useAppSelector } from 'src/store';
import { setRecordingModalPayload } from 'src/features/editor/controller/Editor.slice';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import getVideoAspectRatio from 'src/lib/utils/getVideoAspectRatio';
import { getFirstFrameFromVideoFile } from 'src/lib/utils/videoFrame';
import { imageUpload } from 'src/lib/utils/imageUpload';
import { useConfirm } from 'material-ui-confirm';
import { useEditor } from '../controller';
import { DocumentNameContext } from './DocumentNameContext';
import { useUpload } from 'src/core/upload/UploadContext';

export default function RecordingModal() {
  const dispatch = useAppDispatch();
  const recordingModalPayload = useAppSelector((state) => state.editor.recordingModalPayload);
  const { handleSetVideo } = useEditor();
  const { uploadVideo } = useUpload();
  const { documentName, documentTitle } = useContext(DocumentNameContext);
  const { t } = useTranslation();
  const [isRecording, setIsRecording] = useState(false);
  const confirm = useConfirm();

  const handleClearRecordingModalPayload = useCallback(
    () => dispatch(setRecordingModalPayload(undefined)),
    [dispatch]
  );
  const uploadVid = useCallback(
    // eslint-disable-next-line max-statements
    async (newFile: File) => {
      if (!recordingModalPayload) return undefined;
      recordingModalPayload.onLoading?.(true);
      const aspectRatio = await getVideoAspectRatio(newFile);
      const currentFrame = await getFirstFrameFromVideoFile(newFile);

      let poster: string | undefined;

      if (currentFrame) {
        const response = await imageUpload(new File([currentFrame], 'poster.jpg'));
        if (response.status !== 201) {
          showNotification(t('validationErrorMessages.generalUploadErrorMessage'), 'error');
          return undefined;
        }

        const { result } = response.data;
        const { variants } = result;
        const [imageUrl] = variants;
        poster = imageUrl;
      }

      const uploadVid = (videoId: string) => {
        handleSetVideo(recordingModalPayload.id)({
          url: null,
          fileName: newFile.name,
          videoId,
          originalAspectRatio: aspectRatio,
          poster,
          captions: false,
          chapters: false,
        });
        recordingModalPayload.onLoading?.(false);
        setTimeout(() => {
          (
            document
              .getElementById(recordingModalPayload.id)
              ?.querySelector('.editor-item-button') as HTMLButtonElement
          )?.focus();
        });
      };
      try {
        await uploadVideo({
          file: newFile,
          errorMessage: t('validationErrorMessages.UploadFailed'),
          onVideoUploadSuccess: uploadVid,
          documentName,
          resource: {
            type: documentName?.split('.')[0] as any,
            id: documentTitle as string,
            name: documentTitle as string,
            route: window.location.pathname,
          },
          originalAspectRatio: aspectRatio,
          posterUrl: poster,
        });
      } catch {
        showNotification(t('validationErrorMessages.UploadFailed'), 'error');
        recordingModalPayload.onLoading?.(false);
      }
      return undefined;
    },
    [documentName, handleSetVideo, t, recordingModalPayload, uploadVideo, documentTitle]
  );

  const onRecordingFinish = useCallback(
    (blob: Blob) => {
      const f = new File([blob], 'recording.mp4', { type: 'video/mp4' });
      uploadVid(f);
      handleClearRecordingModalPayload();
      setIsRecording(false);
    },
    [uploadVid, handleClearRecordingModalPayload]
  );

  const onStartRecording = useCallback(() => {
    setIsRecording(true);
  }, []);

  const handleCloseModal = () => {
    if (isRecording) {
      confirm({
        title: t('areYouSure'),
        description: t('editor.uploadMediaModal.removeVideo'),
      }).then(() => {
        setIsRecording(false);
        handleClearRecordingModalPayload();
      });
    } else {
      handleClearRecordingModalPayload();
    }
  };

  return (
    <Dialog
      open={!!recordingModalPayload}
      fullWidth
      maxWidth="lg"
      onClose={handleCloseModal}
      data-cy="recording-modal-dialog"
    >
      <DialogContent>
        <ScreenCameraRecorder
          onRecordingFinish={onRecordingFinish}
          onRecordingStarted={onStartRecording}
        />
      </DialogContent>
    </Dialog>
  );
}
