import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './api';
import { ResultData } from './searchApi';
import { ChatAttachment } from 'src/features/ai-chat/helpers/types';

export interface MessageContent {
  type: 'text' | 'image' | 'file'; // Expandable for other types of content
  text?: string; // Only present if the type is 'text'
  image?: string; // Present if the type is 'image'
  file_name?: string; // Present if the type is 'image_file'
  data?: ArrayBuffer | string; // Present if the type is 'file'
}

// Define the type for individual message object
export interface ChatMessage {
  id?: string;
  assistant_id?: string;
  attachment?: ChatAttachment;
  content: MessageContent[];
  createdAt: number | null;
  metadata?: Record<string, any>;
  object?: string;
  role: 'assistant' | 'user';
  run_id?: string;
  thread_id: string;
  resources?: ResultData[];
}

export interface Thread {
  id: string;
  created_at: Date;
  updated_at: Date;
  summary: string;
}

// Define the main thread response structure
export interface ChatThread {
  id: string; // Thread ID
  messages: ChatMessage[]; // Array of messages
  created_at: Date;
  updated_at: Date;
  summary: string;
}

const aiChatApi = createApi({
  reducerPath: 'aiChatApi',
  tagTypes: ['ChatThread', 'Threads', 'Assistant-settings'],
  baseQuery,
  endpoints: (builder) => ({
    fetchMessages: builder.query<ChatThread, string>({
      query: (threadId) => `ai-assistant/threads/${threadId}`,
      transformResponse: (response: any) => ({
        ...response,
        messages: response.messages.map((message: ChatMessage) => ({
          ...message,
          // @ts-expect-error weird object structure, need to adjust backend
          content: message.content.content,
        })),
      }),
      providesTags: ['ChatThread'],
    }),
    fetchThreads: builder.query<ChatThread[], void>({
      query: () => 'ai-assistant/threads',
      providesTags: ['Threads'],
    }),
    deleteThread: builder.mutation<void, string>({
      query: (threadId) => ({
        url: `ai-assistant/threads/${threadId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Threads'],
    }),
    createThread: builder.mutation<Thread, void>({
      query: () => ({
        url: 'ai-assistant/threads',
        method: 'POST',
      }),
      invalidatesTags: ['Threads', 'ChatThread'],
    }),
    fetchSettings: builder.query<{ name: string; customInstructions: string }, void>({
      query: () => '/companies/ai-assistant/settings',
      providesTags: ['Assistant-settings'],
    }),
    updateSettings: builder.mutation<void, { name: string; customInstructions: string }>({
      query: (settings) => ({
        url: '/companies/ai-assistant/settings',
        method: 'PATCH',
        body: settings,
      }),
      invalidatesTags: ['Assistant-settings'],
    }),
  }),
});

export const {
  useFetchMessagesQuery,
  useCreateThreadMutation,
  useFetchThreadsQuery,
  useDeleteThreadMutation,
  useFetchSettingsQuery,
  useUpdateSettingsMutation,
} = aiChatApi;

export default aiChatApi;
