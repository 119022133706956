import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import { Training } from 'src/store/api/trainings/trainingsApi';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import ImageWithDefaultFallback from 'src/ui-components/image/ImageWithDefaultFallback';
import StartCompleteProgress from 'src/ui-components/custom/progress/StartCompleteProgress';

const IMAGE_BOX_CLASS_NAME = 'image-box';

export default function TrainingCard({
  name,
  imageUrl,
  hasPermission,
  id,
  defaultImage,
  lessonsCount,
  estimatedTimeToComplete,
  progress,
}: Training) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const lessonsCountLabel = t('lesson', { count: lessonsCount || 0 });
  const estimatedTimeLabel = t('minute', { count: Number(estimatedTimeToComplete) || 0 });

  const isEditing = pathname.includes('/edit');

  const handleClick = () => {
    navigate(`/trainings/${id}${isEditing ? '/edit' : ''}`, { relative: 'route' });
  };
  return (
    <Card
      data-cy={`trainings-card-${id}`}
      onClick={handleClick}
      variant="outlined"
      component={Box}
      sx={{
        [`.${IMAGE_BOX_CLASS_NAME} img, .${IMAGE_BOX_CLASS_NAME} svg`]: {
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.short,
              easing: theme.transitions.easing.easeInOut,
            }),
        },
        [`&:hover .${IMAGE_BOX_CLASS_NAME} img, &:hover .${IMAGE_BOX_CLASS_NAME} svg`]:
          hasPermission
            ? {
                transform: 'scale(1.2)',
              }
            : {},
        cursor: hasPermission ? 'pointer' : 'auto',
      }}
      overflow="hidden"
    >
      <Stack>
        <Box
          className={IMAGE_BOX_CLASS_NAME}
          sx={{
            overflow: 'hidden',
            width: '100%',
            aspectRatio: 16 / 9,
          }}
        >
          <ImageWithDefaultFallback
            borderRadius={0}
            src={imageUrl}
            alt={name}
            defaultImage={defaultImage as any}
          />
        </Box>
        <CardContent>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack minWidth={0}>
              <Typography variant="h6" noWrap>
                {name || t('trainingDetailPage.unnamedTraining')}
              </Typography>
              <Typography variant="body2" fontWeight="bold" color="grey[500]">
                {lessonsCountLabel} – {estimatedTimeLabel}
              </Typography>
            </Stack>
            <StartCompleteProgress value={progress || 0} onClick={handleClick} />
          </Stack>
        </CardContent>
      </Stack>
    </Card>
  );
}
