import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { setSelectedCustomerGroup } from 'src/store/client/dashboardSlice';
import { useAppSelector } from 'src/store';
import Selector from './Selector';
import useFilteredCustomerGroups from './useFilteredCustomerGroups';

export default function CustomerGroupSelector() {
  const dispatch = useDispatch();
  const selectedCustomerGroup = useAppSelector((state) => state.dashboard.selectedCustomerGroup);
  const { filteredCustomerGroups } = useFilteredCustomerGroups();

  const options = useMemo(
    () =>
      (filteredCustomerGroups || []).map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [filteredCustomerGroups]
  );

  useEffect(() => {
    if (
      selectedCustomerGroup &&
      !filteredCustomerGroups.some((group) => group.id === selectedCustomerGroup)
    ) {
      if (filteredCustomerGroups.length > 0) {
        dispatch(setSelectedCustomerGroup(filteredCustomerGroups[0].id));
      } else {
        dispatch(setSelectedCustomerGroup(undefined));
      }
    }
  }, [selectedCustomerGroup, filteredCustomerGroups, dispatch]);

  if (options.length <= 1) return null;

  return (
    <Selector
      value={selectedCustomerGroup}
      options={options}
      onChange={(_, option) => {
        if (option) {
          dispatch(setSelectedCustomerGroup(option.value));
        }
      }}
    />
  );
}
