import { Box, Stack } from '@mui/material';
import { formatDateTimeFromTimeStamp } from 'src/lib/utils/format-time';
import { Document } from 'src/store/api/driveApi';
import { useTranslation } from 'react-i18next';
import { ShareLevel } from 'src/lib/types/share-level';
import ShareModal from 'src/core/share-modal/ShareModal';
import DocumentsActions from './DocumentsAction';
import DocumentNameCell from './DocumentNameCell';
import getManagerTitle from './getManagerTitle';
import DocumentsSwitch from './DocumentsSwitch';
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid';

const pageSize = 50;
interface DocumentsTableProps {
  rows: Document[];
  setDocumentsPage: (page: number) => void;
  rowCount: number;
  setDocumentsSortModel: (model: GridSortModel) => void;
  isLoading: boolean;
}

function DocumentsTable({
  rows,
  setDocumentsPage,
  rowCount,
  setDocumentsSortModel,
  isLoading,
}: DocumentsTableProps) {
  const { t } = useTranslation();

  const columns: GridColDef[] = [
    {
      field: 'name',
      align: 'left',
      headerName: t('documentsPage.table.columnNames.name'),
      flex: 2,
      renderCell: ({ row }) => (
        <Box
          sx={{
            width: '100%',
            maxWidth: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',
          }}
        >
          <DocumentNameCell
            icon={row.icon}
            title={row.name}
            sharedWithOthers={row.sharedWithOthers}
            resourceType={row.resourceType}
            id={row.id}
          />
        </Box>
      ),
    },
    {
      field: 'managersCount',
      headerName: t('documentsPage.table.columnNames.manager'),
      width: 180,
      align: 'left',
      sortable: false,
      valueGetter: (_, row) => getManagerTitle(row, t),
      renderCell: (props) => <Box maxWidth={200}>{props.value}</Box>,
    },
    {
      field: 'updatedAt',
      headerName: t('documentsPage.table.columnNames.lastModified'),
      width: 180,
      align: 'left',
      valueGetter: (_, row) => row.updatedAt,
      renderCell: ({ row }) => <Box>{formatDateTimeFromTimeStamp(row.updatedAt)}</Box>,
    },
    {
      field: 'actions',
      headerName: t('documentsPage.table.columnNames.actions'),
      width: 140,
      headerAlign: 'left',
      sortable: false,
      renderCell: ({ row }) => (
        <Stack direction="row" justifyContent="flex-end" gap={1} alignItems="center" height="100%">
          <DocumentsSwitch
            checkedInit={row.sidebar}
            disabled={row.shareLevel === ShareLevel.view}
            id={row.id}
            resourceType={row.resourceType}
          />
          <ShareModal
            name={row.name}
            resourceId={row.id as string}
            resourceType={row.resourceType}
            shareLevel={row.shareLevel}
            dataCy="documents-table"
          />
          <DocumentsActions id={row.id} resourceType={row.resourceType} />
        </Stack>
      ),
    },
  ];

  return (
    <Box>
      <DataGrid
        rows={rows}
        columns={columns}
        pagination
        paginationMode="server"
        sortingMode="server"
        loading={isLoading}
        rowCount={rowCount}
        sortingOrder={['desc', 'asc']}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize,
            },
          },
          sorting: {
            sortModel: [
              {
                field: 'updatedAt',
                sort: 'desc',
              },
            ],
          },
        }}
        onSortModelChange={(model) => {
          setDocumentsSortModel(model);
        }}
        pageSizeOptions={[pageSize]}
        onPaginationModelChange={(paginationModel) => {
          setDocumentsPage(paginationModel.page + 1);
        }}
        disableColumnResize
        disableColumnFilter
        hideFooterSelectedRowCount
        disableRowSelectionOnClick
        disableColumnMenu
        disableVirtualization
      />
    </Box>
  );
}

export default DocumentsTable;
