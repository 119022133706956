import { Container, Stack } from '@mui/material';
import { Training, useTrainingsByUserIdQuery } from 'src/store/api/trainings/trainingsApi';
import { useTranslation } from 'react-i18next';
import LottieLogoSpinner from 'src/core/lottie-logo-spinner';
import Empty from 'src/ui-components/custom/empty/Empty';
import TrainingsCategory from './TrainingsCategory';
import TrainingsHeader from './TrainingsHeader';

function TrainingsView() {
  const { t } = useTranslation();
  const { data, isLoading } = useTrainingsByUserIdQuery('me');

  const trainingsForMe = data?.filter((training) => training.category === 'trainingsForMe') || [];
  const publicTrainings =
    data?.filter((training) => training.category === 'trainingsForMePublic') || [];

  const optionalTrainings =
    data?.filter((training) => training.category === 'optionalTrainings') || [];

  const findIncompleteTraining = (trainings: Training[]) =>
    trainings?.find((training) => training.progress < 100);

  const headerTraining =
    findIncompleteTraining(trainingsForMe) ||
    findIncompleteTraining(publicTrainings) ||
    findIncompleteTraining(optionalTrainings) ||
    trainingsForMe?.[0] ||
    publicTrainings?.[0] ||
    optionalTrainings?.[0];

  const isNoTrainings =
    trainingsForMe.length === 0 && publicTrainings.length === 0 && optionalTrainings.length === 0;

  const categories = [
    {
      title: t('trainingsPage.trainingsForYou'),
      trainings: trainingsForMe,
      emptyText: t('trainingsPage.trainingsForYouEmptyText'),
    },
    {
      title: t('trainingsPage.publicTitle'),
      trainings: publicTrainings,
      emptyText: t('trainingsPage.trainingsPublicEmptyText'),
    },
    {
      title: t('trainingsPage.optionalTrainings'),
      trainings: optionalTrainings,
      emptyText: t('trainingsPage.trainingsOptionalEmptyText'),
    },
  ];

  return (
    <Stack minHeight="100%">
      {isLoading && <LottieLogoSpinner margin="auto" />}
      {headerTraining && <TrainingsHeader {...headerTraining} />}
      {isNoTrainings && !isLoading && (
        <Empty description={t('trainingsPage.trainingsForYouEmptyText')} />
      )}
      {!isLoading && !isNoTrainings && (
        <Container>
          <Stack gap={4} my={4}>
            {categories.map(
              (category) =>
                category.trainings && <TrainingsCategory key={category.title} {...category} />
            )}
          </Stack>
        </Container>
      )}
    </Stack>
  );
}

export default TrainingsView;
