import { useState } from 'react';
import { SvgIcon, Typography, Stack, Tooltip, Box, IconButton } from '@mui/material';
import { File, useDeleteFileMutation } from 'src/store/api/driveApi';
import { WorkbaseDeleteSquareIcon, WorkbaseEditIcon } from 'src/assets/icons/workbaseIcons';
import { useTranslation } from 'react-i18next';
import { formatDateTimeFromTimeStamp } from 'src/lib/utils/format-time';
import ShareModal from 'src/core/share-modal/ShareModal';
import { ShareLevel } from 'src/lib/types/share-level';
import { useConfirm } from 'material-ui-confirm';
import formatBytes from '../helpers/formatBytes';
import FileRenameDialog from '../file-viewer/FileRenameDialog';
import FilePreviewDialog from '../file-viewer/FilePreviewDialog';
import getManagerTitle from './getManagerTitle';
import DocumentNameCell from './DocumentNameCell';
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid';

interface FilesTableProps {
  files: File[];
  setFilesPage: (page: number) => void;
  rowCount: number;
  setFilesSortModel: (model: GridSortModel) => void;
  isLoading: boolean;
}

const pageSize = 50;

function FilesTable(props: FilesTableProps) {
  const { t } = useTranslation();
  const [openedFile, setOpenedFile] = useState<File | null>(null);
  const [fileEditData, setFileEditData] = useState<File | null>(null);
  const [deleteFile] = useDeleteFileMutation();
  const confirm = useConfirm();
  const { files, rowCount, setFilesSortModel, setFilesPage, isLoading } = props;

  const handleOpenFile = (file: File) => {
    setOpenedFile(file);
  };

  const columns: GridColDef[] = [
    {
      field: 'filename',
      headerName: t('documentsPage.table.columnNames.fileName'),
      flex: 2,
      renderCell: (params: GridRenderCellParams) => (
        <Stack height="100%" justifyContent="center" width="100%">
          <DocumentNameCell
            id={params.row.id}
            onClick={() => handleOpenFile(params.row)}
            fileType={params.row.filename.split('.').pop()}
            title={params.row.filename}
            sharedWithOthers={params.row.sharedWithOthers}
            resourceType={params.row.resourceType}
          />
        </Stack>
      ),
    },
    {
      field: 'owner',
      headerName: t('documentsPage.table.columnNames.manager'),
      width: 180,
      sortable: false,
      align: 'left',
      renderCell: (params: GridRenderCellParams) => (
        <Stack height="100%" alignItems="flex-start" justifyContent="center">
          <Typography variant="subtitle1" noWrap align="center">
            {getManagerTitle(params.row, t)}
          </Typography>
        </Stack>
      ),
    },
    {
      field: 'updatedAt',
      headerName: t('documentsPage.table.columnNames.lastModified'),
      width: 180,
      align: 'left',
      renderCell: (params: GridRenderCellParams) => (
        <Stack height="100%" alignItems="flex-start" justifyContent="center">
          <Typography variant="subtitle1" noWrap align="center">
            {formatDateTimeFromTimeStamp(params.row.updatedAt)}
          </Typography>
        </Stack>
      ),
    },
    {
      field: 'fileSize',
      headerName: t('documentsPage.table.columnNames.fileSize'),
      width: 100,
      align: 'left',
      renderCell: (params: GridRenderCellParams) => (
        <Stack height="100%" alignItems="flex-start" justifyContent="center">
          <Typography variant="subtitle1" noWrap align="center">
            {formatBytes(params.row.fileSize)}
          </Typography>
        </Stack>
      ),
    },
    {
      field: 'actions',
      headerName: t('documentsPage.table.columnNames.actions'),
      width: 140,
      sortable: false,
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row" gap={1} justifyContent="flex-end" alignItems="center" height="100%">
          <ShareModal
            name={params.row.filename}
            resourceId={params.row.id}
            resourceType="file"
            shareLevel={params.row.shareLevel}
            dataCy="files-table"
          />
          {params.row.shareLevel !== ShareLevel.view && (
            <Tooltip title={t('edit')} disableInteractive>
              <IconButton onClick={() => setFileEditData(params.row)} variant="soft">
                <SvgIcon component={WorkbaseEditIcon} fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          {params.row.shareLevel === ShareLevel.manage && (
            <Tooltip title={t('delete')} disableInteractive>
              <IconButton
                variant="soft"
                onClick={() => {
                  confirm({
                    title: t('areYouSure'),
                    description: t('documentsPage.deleteFileConfirmDescription', {
                      name: params.row.filename,
                    }),
                    confirmationText: t('delete'),
                  }).then(() => deleteFile(params.row.id));
                }}
              >
                <SvgIcon component={WorkbaseDeleteSquareIcon} fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      ),
    },
  ];

  return (
    <>
      <FileRenameDialog fileData={fileEditData} onClose={() => setFileEditData(null)} />
      <FilePreviewDialog
        open={!!openedFile}
        onClose={() => setOpenedFile(null)}
        fileId={openedFile?.id as string}
        fileType={openedFile?.fileType as string}
        fileName={openedFile?.filename}
      />
      <Box>
        <DataGrid
          rows={files}
          columns={columns}
          paginationMode="server"
          sortingMode="server"
          loading={isLoading}
          disableColumnMenu
          sortingOrder={['desc', 'asc']}
          onSortModelChange={(model) => {
            setFilesSortModel(model);
          }}
          onPaginationModelChange={(model) => {
            setFilesPage(model.page + 1);
          }}
          rowCount={rowCount}
          // autoHeight
          initialState={{
            pagination: {
              paginationModel: {
                pageSize,
              },
            },
            sorting: {
              sortModel: [
                {
                  field: 'updatedAt',
                  sort: 'desc',
                },
              ],
            },
          }}
          pageSizeOptions={[pageSize]}
          disableColumnResize
          disableColumnFilter
          disableRowSelectionOnClick
          disableVirtualization
          hideFooterSelectedRowCount
        />
      </Box>
    </>
  );
}

export default FilesTable;
