import { createContext, useReducer, useContext, useMemo } from 'react';

interface StreamState {
  currentStream: string;
  isStreaming: boolean;
}

type StreamAction =
  | { type: 'UPDATE_STREAM'; payload: string }
  | { type: 'CLEAR_STREAM' }
  | { type: 'SET_STREAMING'; payload: boolean };

const initialState: StreamState = {
  currentStream: '',
  isStreaming: false,
};

const StreamContext = createContext<StreamState>(initialState);

const StreamDispatchContext = createContext<React.Dispatch<StreamAction>>(() => null);

function streamReducer(state: StreamState, action: StreamAction): StreamState {
  switch (action.type) {
    case 'UPDATE_STREAM':
      return { ...state, currentStream: state.currentStream + action.payload, isStreaming: true };
    case 'CLEAR_STREAM':
      return { ...state, currentStream: '', isStreaming: false };
    case 'SET_STREAMING':
      return { ...state, isStreaming: action.payload };
    default:
      return state;
  }
}
export function StreamProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(streamReducer, initialState);
  const memoizedValue = useMemo(() => state, [state]);
  return (
    <StreamContext.Provider value={memoizedValue}>
      <StreamDispatchContext.Provider value={dispatch}>{children}</StreamDispatchContext.Provider>
    </StreamContext.Provider>
  );
}

export const useStreamState = () => useContext(StreamContext);
export const useStreamDispatch = () => useContext(StreamDispatchContext);
