import { Dispatch, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'src/store';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { API_URL } from 'src/global-config';
import aiChatApi from 'src/store/api/aiChatApi';
import { AnyAction } from '@reduxjs/toolkit';
import UAParser from 'ua-parser-js';
import useHasAnyPermission from 'src/hooks/useHasAnyPermission';
import { PermissionCodes } from 'src/store/api/userPermissionsApi';
import { useStreamDispatch, useStreamState } from './streamContext';

export function loadAllUserData() {
  const parser = new UAParser();
  const result = parser.getResult();

  return {
    browser: {
      name: result.browser.name,
    },
    os: {
      name: result.os.name,
      version: result.os.version,
    },
    device: {
      type: result.device.type || 'N/A',
      model: result.device.model || 'N/A',
      vendor: result.device.vendor || 'N/A',
    },
    role: localStorage.getItem('persist:dashboard')
      ? JSON.parse(JSON.parse(localStorage.getItem('persist:dashboard') || '{}').selectedRole)
      : null,
    location: window.location.pathname,
  };
}

type MessageType = 'authenticated' | 'textDelta' | 'end' | 'error' | 'notification';

interface MessageData {
  type: MessageType;
  value?: any;
  error?: string;
  message?: {
    type: string;
    args?: Record<string, any>;
  };
}

const wsUrl = new URL(API_URL);
wsUrl.pathname = '/ai-assistant';
const socketProtocol = wsUrl.protocol === 'https:' ? 'wss' : 'ws';
const socketUrl = `${socketProtocol}://${wsUrl.host}/ai-assistant`;
// TODO: refactor after we have pings
// eslint-disable-next-line max-statements
export const useSocket = (threadId: string | null, dispatch: React.Dispatch<any>) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [noResponseTimeout, setNoResponseTimeout] = useState<NodeJS.Timeout | null>(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [shouldReset, setShouldReset] = useState(false);
  const [safeReconnectTimeout, setSafeReconnectTimeout] = useState<NodeJS.Timeout | null>(null);
  const [firstMessage, setFirstMessage] = useState(null);
  const authToken = useAppSelector(({ auth }) => auth.token);
  const userType = useAppSelector(({ auth }) => auth.userType);
  const currentPortal = useAppSelector(({ userPortal }) => userPortal.currentPortal);
  const hasAnyPermission = useHasAnyPermission();
  const canAccessAiAssistant = hasAnyPermission([PermissionCodes.ACCESS_AI_ASSISTANT]);
  const navigate = useNavigate();
  const rtkDispatch = useDispatch();
  const portalToSend = userType === 'customer' ? 'customerPortal' : currentPortal;
  const state = useStreamState();
  const streamDispatch = useStreamDispatch();

  const createMessageHandlers = (
    dispatch: Dispatch<AnyAction>,
    rtkDispatch: Dispatch<AnyAction>,
    navigate: (path: string) => void,
    streamDispatch: Dispatch<AnyAction>,
    state: { currentStream: any },
    noResponseTimeout: NodeJS.Timeout | null,
    setNoResponseTimeout: (timeout: NodeJS.Timeout | null) => void,
    setIsGenerating: (isGenerating: boolean) => void,
    setIsAuthenticated: (isAuthenticated: boolean) => void
    // eslint-disable-next-line max-params
  ): { [key: string]: (data: MessageData) => void } => ({
    authenticated: () => {
      setIsAuthenticated(true);
    },

    textDelta: (data) => {
      if (noResponseTimeout) {
        clearTimeout(noResponseTimeout);
        setNoResponseTimeout(null);
        dispatch({ type: 'SET_WAITING_RESPONSE', payload: false });
      }
      streamDispatch({ type: 'UPDATE_STREAM', payload: data.value });
    },

    end: () => {
      if (state.currentStream) {
        dispatch({ type: 'COMPLETE_STREAM', payload: state.currentStream });
      }
      streamDispatch({ type: 'CLEAR_STREAM' });
      dispatch({ type: 'SET_NEEDS_UPDATE', payload: '1' });
      setNoResponseTimeout(null);
      setIsGenerating(false);
    },

    error: (data) => {
      dispatch({ type: 'WEBSOCKET_ERROR', payload: data.error });
      dispatch({ type: 'SET_WAITING_RESPONSE', payload: false });
    },

    notification: (data) => {
      const handlePageCreated = (pageId: string) => {
        if (noResponseTimeout) {
          clearTimeout(noResponseTimeout);
        }
        dispatch({ type: 'SET_IS_STREAMING', payload: true });
        dispatch({ type: 'SET_NEEDS_UPDATE', payload: '1' });
        navigate(`/page/${pageId}`);
      };

      const handleSummaryCreated = (args: { threadId: string; summary: string }) => {
        const { threadId, summary } = args;
        rtkDispatch(
          aiChatApi.util.updateQueryData('fetchThreads', undefined, (draft) => {
            const thread = draft.find((thrd) => thrd.id === threadId);
            if (thread) {
              thread.summary = summary;
            }
          }) as unknown as AnyAction
        );
        dispatch({
          type: 'UPDATE_THREAD_SUMMARY',
          payload: { id: threadId, summary },
        });
      };

      const { type, args } = data.message || {};
      if (type === 'pageCreated') {
        handlePageCreated(args?.pageId);
      } else if (type === 'pageAiUpdateContentCompleted') {
        dispatch({ type: 'SET_NEEDS_UPDATE', payload: args?.pageId });
      } else if (type === 'summaryCreated' && args) {
        handleSummaryCreated(args as { threadId: string; summary: string });
      }
    },
  });

  const messageHandlers = useMemo(
    () =>
      createMessageHandlers(
        dispatch,
        rtkDispatch,
        navigate,
        streamDispatch,
        state,
        noResponseTimeout,
        setNoResponseTimeout,
        setIsGenerating,
        setIsAuthenticated
      ),
    [dispatch, rtkDispatch, navigate, streamDispatch, state, noResponseTimeout]
  );

  const { getWebSocket, sendJsonMessage, readyState } = useWebSocket(
    socketUrl,
    {
      retryOnError: true,
      shouldReconnect: () => threadId !== null && canAccessAiAssistant,
      onOpen: () => {
        sendJsonMessage({
          type: 'auth',
          token: authToken as string,
          threadId,
          portalMode: portalToSend,
        });
      },
      onClose: () => {
        dispatch({ type: 'CLEAR_CURRENT_STREAM' });
        dispatch({ type: 'SET_WAITING_RESPONSE', payload: false });
      },
      onMessage: (message) => {
        const messageData = JSON.parse(message.data);
        if (messageHandlers[messageData.type]) {
          messageHandlers[messageData.type](messageData);
        }
      },
    },
    !!threadId && canAccessAiAssistant
  );

  const resetSocket = useCallback(() => {
    getWebSocket()?.close();
    setIsAuthenticated(false);
  }, [getWebSocket]);

  useEffect(() => {
    if (shouldReset && !isGenerating) {
      resetSocket();
      setShouldReset(false);
    }
  }, [shouldReset, isGenerating, resetSocket]);

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      setSafeReconnectTimeout(
        setTimeout(() => {
          setShouldReset(true);
        }, 210000) // 3.5min
      );
    }
    return () => {
      if (safeReconnectTimeout) {
        clearTimeout(safeReconnectTimeout);
        setSafeReconnectTimeout(null);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyState]);

  useEffect(
    () => () => {
      resetSocket();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [threadId]
  );

  const handleMessage = useCallback(
    (message: any) => {
      if (isAuthenticated) {
        setNoResponseTimeout(
          setTimeout(() => {
            requestAnimationFrame(() => {
              dispatch({ type: 'WEBSOCKET_ERROR', payload: 'No response from AI assistant' });
              dispatch({ type: 'SET_WAITING_RESPONSE', payload: false });
              getWebSocket()?.close();
              setIsAuthenticated(false);
              setIsGenerating(false);
            });
          }, 20000)
        );
        setIsGenerating(true);
        sendJsonMessage(message);
      } else {
        setFirstMessage(message);
      }
    },
    [isAuthenticated, sendJsonMessage, dispatch, setNoResponseTimeout, getWebSocket]
  );

  useEffect(() => {
    if (firstMessage && isAuthenticated) {
      handleMessage(firstMessage);
      setFirstMessage(null);
    }
  }, [isAuthenticated, firstMessage, handleMessage]);

  return { getWebSocket, sendJsonMessage: handleMessage };
};
