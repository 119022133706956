import TextItem from 'src/features/editor/view/grid/widgets/text-item';
import EditorDivider from 'src/features/editor/view/grid/widgets/divider';
import Button from 'src/features/editor/view/grid/widgets/button';
import HeadlineItem from 'src/features/editor/view/grid/widgets/headline-item';
import InfoPanel from 'src/features/editor/view/grid/widgets/info-panel';
import UploadItem from 'src/features/editor/view/grid/widgets/upload-item';
import SpacerItem from 'src/features/editor/view/grid/widgets/spacer/SpacerItem';
import { useMemo } from 'react';
import { ConnectDragPreview, ConnectDragSource } from 'react-dnd';
import TrainingsItem, {
  TrainingsItemProps,
} from 'src/features/editor/view/grid/widgets/trainings-item/TrainingsItem';
import EmbeddedItem from 'src/features/editor/view/grid/widgets/embedded/EmbeddedItem';
import { useAllTrainingModulesQuery } from 'src/store/api/trainings/trainingModulesApi';
import downloadVideo from 'src/lib/utils/downloadVideo';
import { useEditor } from 'src/features/editor/controller';
import { imageDuplicate } from 'src/lib/utils/imageDuplicate';
import { downloadFile } from 'src/lib/utils';
import { videoDuplicate } from 'src/lib/utils/videoDuplicate';
import VideoWithProgress from '../widgets/video-item/VideoWithProgress';
import {
  BaseGridItem,
  ElementType,
  GridButtonItem,
  GridContentItem,
  GridEmbeddedItem,
  GridInfoPanelItem,
  GridItemType,
  GridSpacerItem,
  GridTrainingsItem,
  GridUploadItem,
  ImageGridItem,
  TrainingType,
  VideoGridItem,
} from '../../../model/types';
import ImageItem from '../widgets/image-item';
import { useTrainingsByUserIdQuery } from 'src/store/api/trainings/trainingsApi';

interface RenderFunctionProps {
  item: Partial<GridItemType>;
  readOnly: boolean;
  dragging?: {
    dragRef: ConnectDragSource;
    previewRef: ConnectDragPreview;
  };
}

interface TrainingItemWithTrainingsProps extends TrainingsItemProps {
  handleTrainingsTypeChange: (trainingsType: TrainingType) => void;
}

function TrainingItemWithTrainings({ ...props }: TrainingItemWithTrainingsProps) {
  const { data } = useTrainingsByUserIdQuery('me', { pollingInterval: 30000 });
  const { data: modulesData } = useAllTrainingModulesQuery();

  return <TrainingsItem {...props} trainings={data} modules={modulesData} />;
}

const useElementConfigs = () => {
  const {
    handleRemoveItem,
    handleEditorChange,
    handleInfoTypeChange,
    handleDuplicateItem,
    handleMediaTypeChange,
    handleImageUpload,
    handleSetImage,
    handleTrainingsTitleChange,
    handleTrainingsIconChange,
    handleSelectedTrainingsChange,
    handleSetVideo,
    handleUpdateVideoPoster,
    handleUpdateVideoTitle,
    handleUpdateEmbeddedItem,
    handleTrainingsTypeChange,
    handleDisableForwardVideo,
    handleRequiredVideo,
    handleHrefChange,
    handleUndoCrop,
  } = useEditor();
  const elementConfigs = useMemo(() => {
    const configs: Record<
      ElementType,
      {
        render: (props: RenderFunctionProps) => React.ReactNode;
      }
    > = {
      spacer: {
        render: ({ item, readOnly, dragging, ...props }) => {
          const { id, height } = item as GridSpacerItem;
          return (
            <SpacerItem
              id={id}
              onDelete={() => handleRemoveItem(id)}
              onDuplicate={() => handleDuplicateItem(id)}
              height={height}
              readOnly={readOnly}
              dragging={dragging}
              {...props}
            />
          );
        },
      },
      textItem: {
        render: ({ item, readOnly, dragging, ...props }) => {
          const { content, id } = item as GridContentItem;
          return (
            <TextItem
              value={content}
              readOnly={readOnly}
              id={id}
              onDelete={() => handleRemoveItem(id)}
              onDuplicate={() => handleDuplicateItem(id)}
              onChange={handleEditorChange(id)}
              dragging={dragging}
              {...props}
            />
          );
        },
      },
      button: {
        render: ({ item, readOnly, dragging, ...props }) => {
          const { content, href, id } = item as GridButtonItem;
          return (
            <Button
              value={content}
              readOnly={readOnly}
              id={id}
              onDelete={() => handleRemoveItem(id)}
              onChange={handleEditorChange(id)}
              onDuplicate={() => handleDuplicateItem(id)}
              dragging={dragging}
              href={href}
              onHrefChange={handleHrefChange(id)}
              {...props}
            />
          );
        },
      },
      divider: {
        render: ({ item, readOnly, dragging, ...props }) => {
          const { id } = item as BaseGridItem;
          return (
            <EditorDivider
              readOnly={readOnly}
              id={id}
              onDelete={() => handleRemoveItem(id)}
              onDuplicate={() => handleDuplicateItem(id)}
              dragging={dragging}
              {...props}
            />
          );
        },
      },
      heading: {
        render: ({ item, readOnly, dragging, ...props }) => {
          const { content, id } = item as GridContentItem;
          return (
            <HeadlineItem
              value={content}
              id={id}
              readOnly={readOnly}
              onDelete={() => handleRemoveItem(id)}
              onChange={handleEditorChange(id)}
              onDuplicate={() => handleDuplicateItem(id)}
              dragging={dragging}
              {...props}
            />
          );
        },
      },
      infoPanel: {
        render: ({ item, readOnly, dragging, ...props }) => {
          const { content, infoType, id } = item as GridInfoPanelItem;
          return (
            <InfoPanel
              value={content}
              id={id}
              readOnly={readOnly}
              infoType={infoType}
              handleInfoTypeChange={handleInfoTypeChange(id)}
              onDelete={() => handleRemoveItem(id)}
              onChange={handleEditorChange(id)}
              onDuplicate={() => handleDuplicateItem(id)}
              dragging={dragging}
              {...props}
            />
          );
        },
      },
      upload: {
        render: ({ item, readOnly, dragging, ...props }) => {
          if (readOnly) return null;
          const { id, mediaType } = item as GridUploadItem;
          return (
            <UploadItem
              id={id}
              type={mediaType}
              onDelete={() => handleRemoveItem(id)}
              onDuplicate={() => handleDuplicateItem(id)}
              onChangeMediaType={handleMediaTypeChange(id)}
              onImageUpload={handleSetImage(id)}
              onVideoUpload={handleSetVideo(id)}
              dragging={dragging}
              {...props}
            />
          );
        },
      },
      image: {
        render: ({ item, readOnly, dragging, ...props }) => {
          const { id, url, height, href, fileName, imageId } = item as ImageGridItem;
          return (
            <ImageItem
              id={id}
              url={url}
              href={href}
              fileName={fileName}
              height={height}
              readOnly={readOnly}
              onDelete={() => handleRemoveItem(id)}
              onUndoCrop={handleUndoCrop(id)}
              onDuplicate={async () => {
                const response = await imageDuplicate(imageId);
                const newImageId = response.data.result.id;
                handleDuplicateItem(id, { imageId: newImageId });
              }}
              onHrefUpdate={handleHrefChange(id)}
              onDownload={() => downloadFile(url, fileName)}
              onUploadImage={
                // TODO: do it here...
                handleImageUpload(id)
              }
              onAddCaption={() => {}}
              dragging={dragging}
              {...props}
            />
          );
        },
      },
      trainings: {
        render: ({ item, readOnly, dragging, ...props }) => {
          const i = item as GridTrainingsItem;
          const { id, selectedItems, title = '', icon, mode = 'default', trainingsType } = i;

          return (
            <TrainingItemWithTrainings
              title={title}
              id={id}
              icon={icon}
              mode={mode}
              onTitleChange={handleTrainingsTitleChange(id)}
              onIconChange={handleTrainingsIconChange(id)}
              onTrainingsChange={handleSelectedTrainingsChange(id)}
              selectedItems={selectedItems}
              readOnly={readOnly}
              onDelete={() => handleRemoveItem(id)}
              onDuplicate={() => handleDuplicateItem(id)}
              dragging={dragging}
              trainingsType={trainingsType}
              handleTrainingsTypeChange={handleTrainingsTypeChange(id)}
              columnsCountNum={1}
              {...props}
            />
          );
        },
      },
      video: {
        render: ({ item, readOnly, dragging, ...props }) => {
          const {
            videoId,
            id,
            url,
            fileName,
            height,
            poster,
            title = '',
            isFastForwardDisabled,
            isVideoRequired,
            originalAspectRatio,
            chapters,
            captions,
          } = item as VideoGridItem;

          return (
            <VideoWithProgress
              id={id}
              videoId={videoId}
              title={title}
              url={url}
              onUndoCrop={handleUndoCrop(id)}
              videoPoster={poster}
              readOnly={readOnly}
              height={height}
              onDelete={() => handleRemoveItem(id)}
              onDuplicate={async () => {
                const response = await videoDuplicate(videoId);
                const newVideoId = response.data.id;
                handleDuplicateItem(id, { videoId: newVideoId });
              }}
              onVideoDownload={() => downloadVideo(videoId, fileName)}
              onUpdateVideoTitle={handleUpdateVideoTitle(id)}
              onUpdateVideoPoster={handleUpdateVideoPoster(id)}
              dragging={dragging}
              disabledFastForward={isFastForwardDisabled}
              isVideoRequired={isVideoRequired}
              onDisableFastForward={handleDisableForwardVideo(id)}
              onRequired={handleRequiredVideo(id)}
              onVideoUpload={handleSetVideo(id)}
              originalAspectRatio={originalAspectRatio}
              chapters={chapters}
              captions={captions}
              {...props}
            />
          );
        },
      },
      embedded: {
        render: ({ item, dragging, ...props }) => {
          const { id, height, contentType, content } = item as GridEmbeddedItem;
          return (
            <EmbeddedItem
              id={id}
              onDelete={() => handleRemoveItem(id)}
              onDuplicate={() => handleDuplicateItem(id)}
              dragging={dragging}
              height={height}
              contentType={contentType}
              content={content}
              onUpdate={handleUpdateEmbeddedItem(id)}
              {...props}
            />
          );
        },
      },
    };
    return configs;
  }, [
    handleRemoveItem,
    handleEditorChange,
    handleInfoTypeChange,
    handleDuplicateItem,
    handleMediaTypeChange,
    handleSetImage,
    handleImageUpload,
    handleSetVideo,
    handleUpdateVideoPoster,
    handleUpdateVideoTitle,
    handleTrainingsTitleChange,
    handleTrainingsIconChange,
    handleSelectedTrainingsChange,
    handleUpdateEmbeddedItem,
    handleTrainingsTypeChange,
    handleDisableForwardVideo,
    handleRequiredVideo,
    handleHrefChange,
    handleUndoCrop,
  ]);

  return elementConfigs;
};

export default useElementConfigs;
