// eslint-disable-next-line import-x/no-unresolved
import { useRegisterSW } from 'virtual:pwa-register/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Stack, SvgIcon, Tooltip } from '@mui/material';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import { WorkbaseResetIcon } from 'src/assets/icons/workbaseIcons';

interface Props {
  children: React.ReactNode;
}

export default function RegisterProvider({ children }: Props) {
  const { t } = useTranslation();

  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW();

  useEffect(() => {
    if (needRefresh) {
      showNotification(
        t('appUpdates'),
        'info',
        <Stack direction="row" gap={1}>
          <Tooltip title={t('reload')}>
            <IconButton
              onClick={() => {
                updateServiceWorker(true);
                setNeedRefresh(false);
                window.location.reload();
              }}
              color="inherit"
              data-cy="update-app-to-new-version"
            >
              <SvgIcon component={WorkbaseResetIcon} />
            </IconButton>
          </Tooltip>
        </Stack>,
        true
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needRefresh]);

  return children;
}
