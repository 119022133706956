import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { setSelectedRole } from 'src/store/client/dashboardSlice';
import { useAppSelector } from 'src/store';
import { useDashboardQuery } from 'src/store/api/dashboardApi';
import Selector from './Selector';

export default function RoleSelector() {
  const dispatch = useDispatch();
  const selectedRole = useAppSelector((state) => state.dashboard.selectedRole);
  const { data: dashboardData } = useDashboardQuery();
  const options = useMemo(
    () =>
      (dashboardData?.roles || []).map((role) => ({
        value: role.id,
        label: `${role.department.name} - ${role.name}`,
      })),
    [dashboardData?.roles]
  );

  if (options.length <= 1) return null;

  return (
    <Selector
      value={selectedRole}
      options={options}
      onChange={(_, option) => {
        if (option) {
          dispatch(setSelectedRole(option.value));
        }
      }}
    />
  );
}
