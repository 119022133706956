import { useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import VideoItem from 'src/features/editor/view/grid/widgets/video-item';
import {
  VideoProgress,
  useGetVideoProgressQuery,
  usePostVideoProgressMutation,
} from 'src/store/api/trainings/videoProgressApi';
import { usePatchVideoRequiredMutation } from 'src/store/api/trainings/videoRequiredApi';
import throttle from 'lodash.throttle';
import { DocumentNameContext } from '../../../DocumentNameContext';
import { VideoItemProps } from './VideoItem';

export default function VideoWithProgress({
  id,
  videoId,
  onDelete,
  onDuplicate,
  readOnly = false,
  onResizeStop,
  onVideoDownload,
  url,
  videoPoster,
  dragging,
  onResizeStart,
  onUpdateVideoTitle,
  title,
  height,
  onUpdateVideoPoster,
  onDisableFastForward,
  disabledFastForward = false,
  onRequired,
  isVideoRequired = false,
  onUndoCrop,
  onVideoUpload,
  originalAspectRatio,
  captions,
  chapters,
}: VideoItemProps) {
  const params = useParams<{
    trainingId: string;
    moduleId: string;
    lessonId: string;
    sectionId: string;
  }>();
  const { data: videoProgressData } = useGetVideoProgressQuery(videoId || '', {
    skip: !(params.lessonId || disabledFastForward),
  });
  const { documentName, documentTitle } = useContext(DocumentNameContext);
  const [postVideoProgress] = usePostVideoProgressMutation();
  const [patchVideoRequired] = usePatchVideoRequiredMutation();

  const handleVideoProgress = useCallback(
    async (data: Partial<VideoProgress>) => {
      await postVideoProgress({ id: videoId || '', data, lessonId: params?.lessonId as string });
    },
    [postVideoProgress, videoId, params]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttleHandleVideoProgress = useCallback(
    throttle((data: Partial<VideoProgress>) => {
      handleVideoProgress(data);
    }, 2000),
    [handleVideoProgress]
  );

  const handleRequiredVideo = async (isRequired: boolean) => {
    onRequired?.(isRequired);
    await patchVideoRequired({
      id: videoId || '',
      isRequired,
      lessonId: params?.lessonId as string,
    });
  };

  return (
    <VideoItem
      id={id}
      title={title}
      url={url}
      videoPoster={videoPoster}
      readOnly={readOnly}
      height={height}
      onDelete={onDelete}
      onDuplicate={onDuplicate}
      onResizeStop={onResizeStop}
      onVideoDownload={onVideoDownload}
      onVideoUpload={onVideoUpload}
      onUpdateVideoTitle={onUpdateVideoTitle}
      onUpdateVideoPoster={onUpdateVideoPoster}
      dragging={dragging}
      onResizeStart={onResizeStart}
      disabledFastForward={disabledFastForward}
      isVideoRequired={isVideoRequired}
      onDisableFastForward={onDisableFastForward}
      onRequired={handleRequiredVideo}
      watchedDuration={videoProgressData?.watchedDuration}
      onVideoProgress={throttleHandleVideoProgress}
      documentName={documentName}
      documentTitle={documentTitle}
      onUndoCrop={onUndoCrop}
      originalAspectRatio={originalAspectRatio}
      videoId={videoId}
      chapters={chapters}
      captions={captions}
    />
  );
}
