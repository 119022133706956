import { Box, Button, Container, Stack, SvgIcon, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { WorkbaseArrowRightIcon, WorkbaseTrainingsIcon } from 'src/assets/icons/workbaseIcons';
import { Training } from 'src/store/api/trainings/trainingsApi';
import { useRef, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import { darkModePalette } from 'src/mui/palette';
import DefaultImage from 'src/assets/images/Default1.png';
import CircularProgressWithLabel from 'src/ui-components/custom/progress/CircularProgressWithLabel';
import useMaintainEditPath from 'src/lib/hooks/useMaintainEditPath';

export default function TrainingsHeader({
  name,
  description,
  id,
  lessonsCount,
  estimatedTimeToComplete,
  imageUrl,
  progress,
}: Training) {
  const { t } = useTranslation();
  const lessonsCountLabel = t('lesson', { count: lessonsCount || 0 });
  const estimatedTimeLabel = t('minute', { count: estimatedTimeToComplete || 0 });
  const path = useMaintainEditPath();
  const theme = useTheme();
  const containerRef = useRef(null);
  const [isMobileVersion, setIsMobileVersion] = useState(false);

  useResizeObserver(containerRef, (entry) => {
    setIsMobileVersion(entry.contentRect.width < theme.breakpoints.values.md);
  });

  return (
    <Box
      ref={containerRef}
      minHeight={isMobileVersion ? 'max-content' : 400}
      display="flex"
      flexDirection="column"
      position="relative"
      color="common.white"
      component="header"
      justifyContent="center"
      bgcolor={darkModePalette.background.paper}
    >
      <Container
        sx={{ display: 'flex', position: 'relative', zIndex: 1, mb: isMobileVersion ? 2 : 0 }}
      >
        <Stack
          gap={isMobileVersion ? 2 : 1}
          maxWidth={600}
          width="100%"
          py={2}
          justifyContent={isMobileVersion ? 'flex-start' : 'center'}
        >
          <Stack gap={1}>
            <Stack direction="row" alignItems="center" gap={1}>
              <SvgIcon component={WorkbaseTrainingsIcon} />
              <Typography variant="h6"> {t('trainingsPage.trainingForYou')}</Typography>
            </Stack>
            <Box overflow="hidden">
              <Typography
                variant="h2"
                fontWeight="bold"
                sx={{
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {name || t('unnamedTraining')}
              </Typography>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" gap={1}>
            <CircularProgressWithLabel
              value={progress || 0}
              circularProgressProps={{ color: 'inherit' }}
              backdropCircularProgressProps={{ sx: { color: 'grey.700' } }}
            />
            <Typography>
              {lessonsCountLabel} – {estimatedTimeLabel}
            </Typography>
          </Stack>
          {description && (
            <Box overflow="hidden">
              <Typography
                sx={{
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {description}
              </Typography>
            </Box>
          )}
          <Button
            sx={{ mt: 1, alignSelf: 'flex-start' }}
            component={Link}
            to={`/trainings/${id}${path}`}
            size="large"
            endIcon={<SvgIcon component={WorkbaseArrowRightIcon} />}
            data-cy="training-header-gp-to-training-button"
          >
            {t('trainingsPage.goToTraining')}
          </Button>
        </Stack>
      </Container>
      <Box
        position={isMobileVersion ? 'relative' : 'absolute'}
        left={isMobileVersion ? 0 : '50%'}
        right={0}
        top={isMobileVersion ? undefined : 0}
        bottom={0}
        sx={{
          aspectRatio: isMobileVersion ? 16 / 9 : undefined,
          backgroundImage: `linear-gradient(${isMobileVersion ? 0 : 270}deg, 
  rgba(0,0,0,0) 50%, 
  ${darkModePalette.background.paper} 100%, 
  ${darkModePalette.background.paper}), url(${imageUrl || DefaultImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      />
    </Box>
  );
}
