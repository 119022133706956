import axiosInstance from 'src/axios';
import { API_URL } from 'src/global-config';

const getVideoEndpoint = async ({
  documentName,
  originalAspectRatio,
  posterUrl,
  elementId,
}: {
  documentName?: string;
  originalAspectRatio?: number;
  posterUrl?: string;
  elementId?: string;
}) => {
  const resp = await axiosInstance.post(`${API_URL}/videos/upload`, {
    editorDocumentName: documentName,
    editorWidgetId: elementId,
    originalAspectRatio,
    posterUrl,
  });
  return resp.data;
};

const getVideoData = async (videoId: string) => {
  const resp = await axiosInstance.get(`${API_URL}/videos/${videoId}`);
  return resp.data;
};

export { getVideoData, getVideoEndpoint };
