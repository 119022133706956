import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCurrentUserQuery } from 'src/store/api/currentUserApi';
import BrandedHeader from 'src/ui-components/branded-header';
import { useDashboardQuery } from 'src/store/api/dashboardApi';
import { useLinksQuery } from 'src/store/api/linksApi';
import { useAppSelector } from 'src/store';
import Image from 'src/ui-components/image';
import useThemeLogos from 'src/hooks/useThemeLogos';
import SelectorWithLinks from '../SelectorWithLinks';
import NewsfeedSlider from './neewsfeed/Newsfeed';
import useIsCustomerPortal from 'src/hooks/useIsCustomerPortal';
import useFilteredCustomerGroups from '../useFilteredCustomerGroups';

interface Props {
  readOnly: boolean;
}

export default function DashboardHeader({ readOnly }: Props) {
  const { t } = useTranslation();
  const { data: currentUserData } = useCurrentUserQuery();
  const isCustomerPortal = useIsCustomerPortal();
  const { data: rolesData } = useDashboardQuery(undefined, { skip: isCustomerPortal });
  const { filteredCustomerGroups } = useFilteredCustomerGroups();
  const selectedSelectorItem = useAppSelector((state) =>
    !isCustomerPortal ? state.dashboard.selectedRole : state.dashboard.selectedCustomerGroup
  );

  const { data } = useLinksQuery(
    {
      resourceType: isCustomerPortal ? 'customerGroup' : 'role',
      resourceId: selectedSelectorItem || '',
    },
    { skip: !selectedSelectorItem }
  );
  const { favicon } = useThemeLogos();
  const roles = rolesData?.roles || [];

  function getGreetingText() {
    const greetingMorning = t('dashboardPage.header.greetingMorning');
    const greetingAfternoon = t('dashboardPage.header.greetingAfternoon');
    const greetingEvening = t('dashboardPage.header.greetingEvening');

    const hour = new Date().getHours();
    if (hour < 12 && hour >= 3) {
      return greetingMorning;
    }
    if (hour < 18 && hour >= 12) {
      return greetingAfternoon;
    }
    return greetingEvening;
  }

  const items = isCustomerPortal ? filteredCustomerGroups : roles;

  const hasSingleItem = items.length === 1;
  const hasNoData = !data || data.length === 0;

  const shouldRenderSubHeader = !(readOnly && hasSingleItem && hasNoData);

  return (
    <BrandedHeader
      subHeader={
        shouldRenderSubHeader && (
          <SelectorWithLinks
            links={data}
            roles={roles}
            customerGroups={filteredCustomerGroups}
            isEditing={!readOnly}
          />
        )
      }
      stickyBottomContent={readOnly}
    >
      <Stack gap={2} width="100%">
        <Stack direction="row" spacing={1} alignItems="center">
          <Box borderRadius={1} bgcolor="background.default" p={1}>
            <Image {...favicon} width={40} height={40} />
          </Box>
          <Typography variant="h4" fontWeight="bold" color="primary.contrastText">
            {getGreetingText()}, {currentUserData?.firstName}
          </Typography>
        </Stack>
        <Card>
          <CardContent>
            <NewsfeedSlider />
          </CardContent>
        </Card>
      </Stack>
    </BrandedHeader>
  );
}
