import { SvgIcon, Box, ToggleButton, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WorkbasePressContentIcon } from 'src/assets/icons/workbaseIcons';
import { DocumentResourceType } from 'src/store/api/driveApi';
import { usePatchEmbeddedAppMutation } from 'src/store/api/embeddedAppsApi';
import { usePatchPageMutation } from 'src/store/api/pagesApi';
import { usePatchWhiteboardMutation } from 'src/store/api/whiteboardsApi';

interface Props {
  checkedInit: boolean;
  disabled: boolean;
  id: string;
  resourceType: DocumentResourceType;
}

export default function DocumentsSwitch({ checkedInit, disabled, id, resourceType }: Props) {
  const [checked, setChecked] = useState<boolean>(checkedInit);
  const { t } = useTranslation();
  const [patchPage] = usePatchPageMutation();
  const [patchEmbeddedApp] = usePatchEmbeddedAppMutation();
  const [patchWhiteboard] = usePatchWhiteboardMutation();

  const patchMap: {
    [key in DocumentResourceType]: (args: { id: string; sidebar: boolean }) => void;
  } = {
    page: patchPage,
    embeddedApp: patchEmbeddedApp,
    whiteboard: patchWhiteboard,
  };

  function handleSwitchChange(val: boolean) {
    setChecked(val);
    patchMap[resourceType]({ id, sidebar: val });
  }

  return (
    <Tooltip title={t('sidebarTooltip')} disableInteractive>
      <Box bgcolor="primary.paper" borderRadius={1}>
        <ToggleButton
          data-cy="documents-table-show-in-sidebar-button"
          size="small"
          disabled={disabled}
          color={checked ? 'primary' : 'standard'}
          selected
          value={checkedInit}
          onChange={(_, state) => {
            handleSwitchChange(!state);
          }}
        >
          <SvgIcon component={WorkbasePressContentIcon} fontSize="small" />
        </ToggleButton>
      </Box>
    </Tooltip>
  );
}
