import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useOnEnterTrigger from 'src/hooks/useOnEnterTrigger';
import { useRolesQuery } from 'src/store/api/roles.api';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import ChipSelectionMenu from 'src/ui-components/custom/selection-menu/ChipSelectionMenu';
import validateNewEmployeesEmails from './controller/helper';
import { EmailChipsValueProps } from './controller/use-email-chips';
import EmailChips from './EmailChips';
import { useCreateCompanyUserMutation } from 'src/store/api/company-users/companyUsersApi';
import { useCustomerGroupsQuery } from 'src/store/api/customerGroupsApi';
import useIsCustomerPortal from 'src/hooks/useIsCustomerPortal';

interface Props {
  onClose: () => void;
}

export default function NewEmployeeDialogContent({ onClose }: Props) {
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [options, setOptions] = useState<{ id: string; label: string }[]>([]);
  const [createCompanyUser] = useCreateCompanyUserMutation({});
  const [allEmails, setAllEmails] = useState<EmailChipsValueProps[]>([]);
  const isCustomerPortal = useIsCustomerPortal();

  const { data: rolesData } = useRolesQuery(undefined, { skip: isCustomerPortal });
  const { data: customerGroups } = useCustomerGroupsQuery(undefined, { skip: !isCustomerPortal });

  const onOptionsChange = (options: string[]) => {
    setSelectedOptions(options);
  };

  useEffect(() => {
    if (!isCustomerPortal && rolesData) {
      setOptions(
        rolesData.map((role) => ({
          id: role.id,
          label: `${role.department.name} - ${role.name}`,
        }))
      );
    } else if (!!isCustomerPortal && customerGroups) {
      setOptions(customerGroups.map((group) => ({ id: group.id, label: group.name })));
    }
  }, [rolesData, customerGroups, isCustomerPortal]);

  const hasInvalidEmails = allEmails.some((obj) => !obj.isValid);
  const validatingEmails = allEmails.some((obj) => obj.isLoading);

  const disabledConfirmButton =
    allEmails.length === 0 || hasInvalidEmails || validatingEmails || selectedOptions.length === 0;

  const handleChipsCardOnChange = (values: EmailChipsValueProps[]) => {
    validateNewEmployeesEmails(values, allEmails, setAllEmails);
  };

  const handleEmployeeAdd = () => {
    if (disabledConfirmButton) return;

    createCompanyUser({
      emails: allEmails.map((email) => email.label),
      [!isCustomerPortal ? 'roleIds' : 'customerGroupIds']: selectedOptions,
    }).then((resp: any) => {
      if (resp?.error) {
        showNotification(
          t(!isCustomerPortal ? 'addEmployees.notificationFail' : 'addCustomers.notificationFail'),
          'error'
        );
      } else {
        showNotification(
          allEmails.length > 1
            ? t(
                !isCustomerPortal
                  ? 'addEmployees.notificationSuccess.multiplePeopleAdded'
                  : 'addCustomers.notificationSuccess.multiplePeopleAdded'
              )
            : t(
                !isCustomerPortal
                  ? 'addEmployees.notificationSuccess.onePersonAdded'
                  : 'addCustomers.notificationSuccess.onePersonAdded'
              )
        );
      }
    });
    onClose();
  };

  useOnEnterTrigger(handleEmployeeAdd);

  return (
    <>
      <DialogTitle>
        {t(!isCustomerPortal ? 'addEmployees.title' : 'addCustomers.title')}
      </DialogTitle>
      <DialogContent>
        <Stack gap={2} pt={0.5}>
          <EmailChips
            placeholder={t('addEmployees.emailPlaceholder')}
            values={allEmails}
            onChange={(e) => handleChipsCardOnChange(e)}
            error={hasInvalidEmails}
            autoFocus
          />
          <Stack gap={1}>
            <Typography variant="subtitle1">{t('addEmployees.inviteAs')}</Typography>
            <ChipSelectionMenu
              dataCy="new-employee"
              options={options}
              searchable
              selected={selectedOptions}
              onChange={onOptionsChange}
              placeholder={t(
                !isCustomerPortal
                  ? 'addEmployees.roleSelectorLabel'
                  : 'addCustomers.customerGroupsSelectorLabel'
              )}
              emptyErrorText={t(!isCustomerPortal ? 'emptyRolesError' : 'emptyCustomerGroupsError')}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button fullWidth color="inherit" onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button
          fullWidth
          color="primary"
          disabled={disabledConfirmButton}
          onClick={handleEmployeeAdd}
        >
          {t('addEmployees.confirmButton')}
        </Button>
      </DialogActions>
    </>
  );
}
