import {
  Avatar,
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material';
import {
  WorkbaseAIIcon,
  WorkbaseCaptionIcon,
  WorkbaseDepartmentsIcon,
  WorkbaseGlobalIcon,
} from 'src/assets/icons/workbaseIcons';
import { useTranslation } from 'react-i18next';
import { useCurrentUserQuery } from 'src/store/api/currentUserApi';
import useIsCustomerPortal from 'src/hooks/useIsCustomerPortal';

function BasicActionsList({ onSendMessage }: { onSendMessage: (message: string) => void }) {
  const { t } = useTranslation();
  const { data: userData } = useCurrentUserQuery();
  const isCustomerPortal = useIsCustomerPortal();
  const employeeQuestion = isCustomerPortal
    ? []
    : [
        {
          title: t('assistant.basicActions.list_department_employees'),
          icon: WorkbaseDepartmentsIcon,
        },
      ];
  const tasks = [
    { title: t('assistant.basicActions.summarize_page'), icon: WorkbaseCaptionIcon },
    ...employeeQuestion,
    { title: t('assistant.basicActions.translate_page_german'), icon: WorkbaseGlobalIcon },
  ];

  return (
    <Stack px={2} pb={2} pt={10} overflow="auto" flexGrow={1}>
      <Paper variant="outlined">
        <Stack direction="row" alignItems="center" gap={1} p={1}>
          <Avatar sx={{ backgroundColor: 'primary.main' }}>
            <SvgIcon component={WorkbaseAIIcon} fontSize="medium" htmlColor="inherit" />
          </Avatar>
          <Box>
            <Typography variant="body2" fontWeight="medium">
              {t('assistant.basicActions.greeting', {
                name: userData?.firstName,
                firstname: userData?.lastName,
              })}
            </Typography>
            <Typography variant="body2" fontWeight="medium">
              {t('assistant.basicActions.howCanIHelp')}
            </Typography>
          </Box>
        </Stack>
      </Paper>
      <List>
        {tasks.map((task) => (
          <ListItemButton
            key={task.title}
            onClick={() => onSendMessage(task.title)}
            data-cy={`ai-assistant-basic-action-button-${task.title}`}
          >
            <ListItemIcon>
              <SvgIcon component={task.icon} fontSize="medium" htmlColor="inherit" />
            </ListItemIcon>
            <ListItemText primary={task.title} />
          </ListItemButton>
        ))}
      </List>
    </Stack>
  );
}

export default BasicActionsList;
