import { Dispatch, SetStateAction, useCallback } from 'react';
import {
  UploadStatus,
  UploadingFile,
  UploadOptions,
  VideoUploadOptions,
  ImageUploadOptions,
} from '../types';
import { getFileUploadEndpoint, uploadFileToGoogleCloud } from './fileUploadHelpers';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import * as tus from 'tus-js-client';
import { getVideoData, getVideoEndpoint } from './videoUploadHelpers';
import { imageUpload } from './imageUploadHelpers';

const useUploadFunctions = ({
  setSignals,
  setUploadingFiles,
  updateProgress,
}: {
  setSignals: Dispatch<SetStateAction<{ [key: string]: AbortController }>>;
  setUploadingFiles: Dispatch<SetStateAction<{ [key: string]: UploadingFile }>>;
  updateProgress: (fileId: string, progressValue: number) => void;
}) => {
  const uploadFile = useCallback(
    // eslint-disable-next-line max-statements
    async ({
      file,
      errorMessage,
      onFileUploadSuccess,
      resource,
      onFileUploadError,
    }: UploadOptions): Promise<any> => {
      const uploadDataKey = `upload-data-${file.name}-${file.lastModified}`;
      let { fileId, uploadUrl } = JSON.parse(localStorage.getItem(uploadDataKey) || '{}');

      if (!fileId) {
        const { id, uploadUrl: url } = await getFileUploadEndpoint(file);
        fileId = id;
        uploadUrl = url;
        localStorage.setItem(uploadDataKey, JSON.stringify({ fileId, uploadUrl }));
      }

      const controller = new AbortController();
      setSignals((prev) => ({ ...prev, [fileId]: controller }));
      setUploadingFiles((prev) => ({
        ...prev,
        [fileId]: {
          id: fileId,
          name: file.name,
          resource,
          status: UploadStatus.UPLOADING,
        },
      }));

      try {
        const localUrl = URL.createObjectURL(file);
        await uploadFileToGoogleCloud(
          uploadUrl,
          file,
          (p: number) => updateProgress(fileId, p),
          controller
        );
        localStorage.removeItem(uploadDataKey);
        onFileUploadSuccess(fileId);
        setUploadingFiles((prev) => ({
          ...prev,
          [fileId]: { ...prev[fileId], status: UploadStatus.SUCCESS },
        }));
        return { localUrl, fileId };
      } catch (error: any) {
        if (error.message !== 'Upload aborted') {
          showNotification(errorMessage, 'error');
          onFileUploadError(fileId);
        }
        return {};
      }
    },
    [setSignals, setUploadingFiles, updateProgress]
  );

  const uploadVideo = useCallback(
    async ({
      file,
      errorMessage,
      onVideoUploadSuccess,
      resource,
      documentName,
      elementId,
      originalAspectRatio,
      posterUrl,
    }: VideoUploadOptions) => {
      const videoData = await getVideoEndpoint({
        documentName,
        originalAspectRatio,
        posterUrl,
        elementId,
      });
      const localUrl = URL.createObjectURL(file);
      setUploadingFiles((prev) => ({
        ...prev,
        [elementId ?? videoData.videoId]: {
          id: elementId ?? videoData.videoId,
          name: file.name,
          resource,
          status: UploadStatus.UPLOADING,
        },
      }));
      updateProgress(elementId ?? videoData.videoId, 0);
      const upload = new tus.Upload(file, {
        endpoint: videoData.endpoint,
        removeFingerprintOnSuccess: true,
        chunkSize: 1024 * 1024 * 2, // 2MB
        retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
        headers: {
          AuthorizationSignature: videoData.authorizationSignature,
          AuthorizationExpire: videoData.authorizationExpire,
          VideoId: videoData.videoExternalId,
          LibraryId: videoData.libraryId,
        },
        metadata: {
          filename: file.name,
          filetype: file.type,
        },
        onError() {
          showNotification(errorMessage, 'error');
        },
        onProgress(bytesUploaded, bytesTotal) {
          const percentage = Math.ceil((bytesUploaded / bytesTotal) * 100);
          updateProgress(elementId ?? videoData.videoId, percentage);
        },
        async onSuccess() {
          onVideoUploadSuccess(videoData.videoId);
          setUploadingFiles((prev) => ({
            ...prev,
            [elementId ?? videoData.videoId]: {
              ...prev[elementId ?? videoData.videoId],
              status: UploadStatus.SUCCESS,
            },
          }));
          try {
            await getVideoData(videoData.videoId);
          } catch {
            setUploadingFiles((prev) => ({
              ...prev,
              [elementId ?? videoData.videoId]: {
                ...prev[elementId ?? videoData.videoId],
                status: UploadStatus.ERROR,
              },
            }));
            showNotification(errorMessage, 'error');
          }
        },
      });
      const controller = new AbortController();

      controller.signal.addEventListener('abort', () => {
        upload.abort();
      });

      const previousUploads = await upload.findPreviousUploads();
      if (previousUploads.length > 0) {
        upload.resumeFromPreviousUpload(previousUploads[0]);
      }

      setSignals((prev) => ({
        ...prev,
        [elementId ?? videoData.videoId]: controller,
      }));

      upload.start();
      return { localUrl, videoId: videoData.videoId, abortController: controller };
    },
    [setSignals, setUploadingFiles, updateProgress]
  );

  const uploadImage = useCallback(
    async ({ file, documentName, imageId }: ImageUploadOptions) => {
      return await imageUpload(
        file,
        {
          onUploadProgress: (progressEvent) => {
            if (progressEvent.loaded && progressEvent.total) {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              updateProgress(imageId, percentCompleted);
            }
          },
        },
        documentName
      );
    },
    [updateProgress]
  );

  return { uploadFile, uploadVideo, uploadImage };
};

export default useUploadFunctions;
