import { Stack } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useHocuspocusProvider, {
  HocuspocusProviderProps,
} from 'src/lib/hooks/use-hocuspocus-provider';
import { useAppSelector } from 'src/store';
import TopBarLayout from 'src/layouts/TopBarLayout';
import Grid from './grid/Grid';
import EditorTopBar from './top-bar/EditorTopBar';
import { setEditorData } from '../controller/Editor.slice';
import { useEditor } from '../controller/EditorContext';
import { DocumentNameContext } from './DocumentNameContext';
import RecordingModal from './RecordingModal';

const parsedUrl = new URL(import.meta.env.VITE_API_URL);

export interface EditorProps {
  readOnly: boolean;
  renderHeader?: (readOnly: boolean) => React.ReactNode;
  topBarRightContent?: React.ReactNode;
  documentTitle?: string;
  onEditorDataChangeCallback?: () => void;
}

export default function Editor({
  readOnly,
  renderHeader,
  topBarRightContent,
  documentTitle,
  onEditorDataChangeCallback,
}: EditorProps) {
  const dispatch = useDispatch<any>();
  const editorData = useAppSelector((state) => state.editor.editorData);
  const { yjsToEditorData, ydoc, undoManager, docName } = useEditor();
  const socketProtocol = parsedUrl.protocol === 'https:' ? 'wss' : 'ws';
  const socketUrl = `${socketProtocol}://${parsedUrl.host}/editor`;

  useHocuspocusProvider({
    documentName: docName,
    socketUrl,
    readOnly,
    ydoc,
  } as HocuspocusProviderProps);

  const onEditorChange = useCallback(() => {
    const updatedEditorData = yjsToEditorData();
    dispatch(setEditorData(updatedEditorData));
    onEditorDataChangeCallback?.();
  }, [yjsToEditorData, dispatch, onEditorDataChangeCallback]);

  useEffect(() => {
    ydoc.getMap('editorData').observeDeep(onEditorChange);

    return () => {
      ydoc.getMap('editorData').unobserveDeep(onEditorChange);
    };
  }, [ydoc, onEditorChange]);

  // cleanup
  useEffect(
    () => () => {
      dispatch(setEditorData(undefined));
    },
    [dispatch]
  );

  useEffect(() => {
    // clear undo history when finished editing
    if (readOnly) {
      undoManager.clear();
    }
  }, [readOnly, undoManager]);

  return (
    <TopBarLayout
      topBarVisible={!readOnly}
      topBar={<EditorTopBar readOnly={readOnly} rightContent={topBarRightContent} />}
    >
      <Stack height="100%" overflow="auto">
        {renderHeader?.(readOnly)}
        <DocumentNameContext.Provider value={{ documentName: docName, documentTitle }}>
          {!readOnly && <RecordingModal />}
          <Grid readOnly={readOnly} grid={editorData?.grid ?? []} />
        </DocumentNameContext.Provider>
      </Stack>
    </TopBarLayout>
  );
}
