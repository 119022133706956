import { MouseEvent } from 'react';
import { getDescendants, RenderParams } from '@minoru/react-dnd-treeview';
import { ButtonBase, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import SvgIconOrEmoji from 'src/ui-components/icon/SvgIconOrEmoji';
import { TreeNodeModel } from './types';
import LottieChevron from '../lottie/LottieChevron';

const TREE_X_OFFSET = 12;

interface Props {
  node: TreeNodeModel;
  treeData: TreeNodeModel[];
  onToggleOpen: (id: TreeNodeModel['id']) => void;
  renderParams: RenderParams;
  isDragging: boolean;
}

function Node({ node, onToggleOpen, treeData, renderParams, isDragging }: Props) {
  const indent = renderParams.depth * TREE_X_OFFSET;

  const handleToggle = (e: MouseEvent) => {
    e.stopPropagation();
    onToggleOpen(node.id);
  };

  const hasDescendants = getDescendants(treeData, node.id).length > 0;

  return (
    <ListItemButton
      style={{ marginInlineStart: indent }}
      className={
        renderParams.isDropTarget && !renderParams.isDragging && node.droppable && isDragging
          ? 'dropTarget'
          : ''
      }
      dense
      data-cy={node.id}
      selected={node.data?.active}
      onClick={node.data?.onClick}
      disableRipple
    >
      <ListItemIcon>
        <SvgIconOrEmoji content={node.data?.icon as string} fontSize="small" />
      </ListItemIcon>
      <ListItemText
        primary={node.text}
        primaryTypographyProps={{ noWrap: true, fontWeight: 'medium' }}
      />
      {hasDescendants && (
        <ButtonBase
          onClick={handleToggle}
          disableRipple
          data-cy={`${node.id}.nodeExpandButton`}
          sx={{
            padding: 0.25,
            width: 24,
            height: 24,
            flexShrink: 0,
            borderRadius: 0.5,
            '&:hover': {
              backgroundColor: 'action.hover',
            },
          }}
        >
          <LottieChevron open={renderParams.isOpen} />
        </ButtonBase>
      )}
    </ListItemButton>
  );
}

export default Node;
