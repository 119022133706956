import { ResourceType } from 'src/store/api/shareResourceApi';
import TrainingAccessControlList, { TabValue } from './TrainingAccessControlList';
import AccessControlList from './AccessControlList';

interface Props {
  resourceId: string;
  resourceType: ResourceType;
  currentShareLevel: string;
  activeTabButton?: TabValue;
  setActiveTabButton?: (_: any, val: string) => void;
  dataCy?: string;
}

export default function AccessControlListGroup({
  resourceId,
  resourceType,
  currentShareLevel,
  activeTabButton,
  setActiveTabButton,
}: Props) {
  const commonProps = {
    resourceId,
    resourceType,
    currentShareLevel,
  };

  return (
    <>
      {resourceType === 'training' && (
        <TrainingAccessControlList
          {...commonProps}
          activeTabButton={activeTabButton}
          setActiveTabButton={setActiveTabButton}
        />
      )}
      {!(resourceType === 'training') && <AccessControlList {...commonProps} />}
    </>
  );
}
